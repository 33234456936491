/**
 * angular imports.
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
/**
 * model imports.
 */
import { DoctorData, DoctorToken, Resources } from 'projects/nga-PAP/src/app/models/responses.model';
/**
 * rxjs imports.
 */
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
/**
 * store imports.
 */
import { Store } from '@ngrx/store';
import * as DoctorAction from '../../../../store/actions/doctor/doctor.actions';
import * as DoctorSelector from '../../../../store/selectors/doctor/doctor.selectors';

import * as PharmaActions from '../../../../store/actions/pharma/pharma.actions';
import * as PharmaSelector from '../../../../store/selectors/pharma/pharma.selectors';
import * as FileAction from '../../../../store/actions/file/file.action';
import * as FileSelector from '../../../../store/selectors/file/file.selectors';

/**
 * service imports.
 */
import { HelperService } from 'projects/nga-PAP/src/app/helper.service';
import { PATTERN, IMAGE_TYPES, MIME_TYPES, APPROVER_CONSTANTS } from 'projects/nga-PAP/src/app/constant/app.constant';

@Component({
  selector: 'pap-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit, OnDestroy {

  /**
   * to store pharma detail
   */
  public decodedToken: DoctorToken;
  /**
   * userId
   */
  public userId: number;
  /**
   * query param sub
   */
  public paramSub: Subscription;
  /**
   * to store pharma code
   */
  public pharmaCode: string = null;

  /**
   * to store doctor detail
   */
  public element: DoctorData = null;
  public doctorProfile: string;
  /**
   * doctor data subscription
   */
  public doctorDataSubs: Subscription;

  /**
   * subscription for pharma data
   */
  public pharmaSubs: Subscription;
  public resourcesData: Resources[];

  public resources = {};
  private filePresignedUrlSub: Subscription;
  private selectedResource = { url: '', type: '' };
  public isPdfError: boolean;
  private filePresignedSingleUrlSub: Subscription;
  public isVerified: boolean;

  /**
   * create all necessary instances.
   * @param store used for to use ngrx store.
   * @param router used to create route.
   * @param route used to get activate route.
   */
  constructor(
    public store: Store,
    public router: Router,
    public route: ActivatedRoute,
    private decodeToken: HelperService
  ) { }


  ngOnInit(): void {
    if (sessionStorage.getItem('resource')) {
      sessionStorage.removeItem('resource');
    }
    this.paramSub = this.route.queryParams
      .pipe(take(1)).subscribe(params => {
        if (params.pharmaCode) {
          this.pharmaCode = params.pharmaCode;
        }
      });
    if (localStorage.getItem('doctorAuth')) {
      const idToken = localStorage.getItem('doctorAuth');
      const decodeToken = this.decodeToken.decodeTokenFunc(idToken);
      if (decodeToken) {
        this.decodedToken = decodeToken;
        this.userId = this.decodedToken.data.userId;
      }
    }
    else {
      const redirectionUrl = localStorage.getItem('pharmaUrl');
      const redirectUrlCode = localStorage.getItem('pharmaCode');
      if (redirectionUrl) {
        this.router.navigate([redirectionUrl], {
          queryParams: {
            code: redirectUrlCode
          }
        });
      }
    }

    this.store.dispatch(DoctorAction.getDoctorDetail(
      { id: this.userId, token: localStorage.getItem('doctorAuth'), pharmaCode: this.pharmaCode }));

    this.doctorDataSubs = this.store.select(DoctorSelector.getDoctorData).subscribe(data => {
      if (data !== null && this.element === null) {
        this.element = data;
        this.doctorProfile = data?.personalDetails?.firstName + ' ' + data?.personalDetails?.lastName;
        this.isVerified = !data?.isVerified;
        this.store.dispatch(PharmaActions.loadPharmas({ pharmaCode: localStorage.getItem('pharmaCode') }));
      }
    });

    this.pharmaSubs = this.store.select(PharmaSelector.getPharmaDetail)
      .subscribe(data => {
        if (data != null) {
          this.resourcesData = data.resources;
          this.getImages(this.resourcesData);
        }
      });

    this.filePresignedUrlSub = this.store.select(FileSelector.getFilePresignedUrlWithIndex)
      .subscribe(file => {
        if (file?.url && file?.index !== null && file?.index !== undefined) {
          this.resources[file?.index] = ({
            id: file.index,
            source: file?.url,
            alt: 'sd',
            type: this.resources[file?.index]?.type
          });
        }
      });

    this.filePresignedSingleUrlSub = this.store.select(FileSelector.getFilePresignedUrl)
      .subscribe(file => {
        if (file) {
          this.selectedResource = ({ url: file, type: this.selectedResource?.type });
        }
      });
  }

  getImages(resources: Resources[]): void {
    for (const resource of resources) {
      this.getPresignedUrl(resource.thumbnailName, resource.thumbnailName);
      this.getFileTypes(resource.fileName, resource.thumbnailName);
    }
  }

  getFileTypes(fileName: string, index): void {
    const fileExtension =
      fileName.split('.').pop().split(PATTERN.URL_SUFFIX)[0];
    let contentType = '';
    switch (fileExtension.toLowerCase()) {
      case IMAGE_TYPES.jpeg:
        contentType = 'image';
        break;
      case IMAGE_TYPES.jpg:
        contentType = 'image';
        break;
      case IMAGE_TYPES.png:
        contentType = 'image';
        break;
      case IMAGE_TYPES.pdf:
        contentType = 'pdf';
        break;
      case IMAGE_TYPES.mp4:
        contentType = 'video';
        break;
      case IMAGE_TYPES.mov:
        contentType = 'video';
        break;
    }
    this.resources[index] = ({ id: null, source: null, alt: 'sd', type: contentType });
  }

  getPresignedUrl(fileName: string, index): void {
    const fileExtension =
      fileName.split('.').pop().split(PATTERN.URL_SUFFIX)[0];
    const prefix = 'hcpResource/thumbnail/';
    let contentType = '';
    switch (fileExtension.toLowerCase()) {
      case IMAGE_TYPES.jpeg:
        contentType = MIME_TYPES.JPEG;
        break;
      case IMAGE_TYPES.jpg:
        contentType = MIME_TYPES.JPEG;
        break;
      case IMAGE_TYPES.png:
        contentType = MIME_TYPES.PNG;
        break;
      case IMAGE_TYPES.pdf:
        contentType = MIME_TYPES.PDF;
        break;
    }
    const time = new Date().getMilliseconds();
    if (fileName) {
      this.store.dispatch(FileAction.loadFilePresignedUrlWithIndex({
        endPoint: APPROVER_CONSTANTS.FILE_SERVICE_URL,
        fileType: 'null',
        fileName: 'hcpResource/thumbnail/' + fileName.split('.')[0],
        contentType,
        module: 'na',
        fileMethod: 'false',
        country: localStorage.getItem('country') === 'PH' ? 'philippines' : 'thailand',
        time,
        index
      }));
    }
  }

  /**
   * used to navigate ae reporting page.
   */
  public navigateToAEReporting(): void {
    const redirectUrlCode = localStorage.getItem('pharmaCode');
    this.router.navigate(['doctor/ae-reporting'], {
      queryParams: {
        pharmaCode: redirectUrlCode
      }
    });
  }

  /**
   * used to navigate to doctor details page.
   */
  navigateToDoctorHome(): void {
    const redirectUrlCode = localStorage.getItem('pharmaCode');
    this.router.navigate(['viewDoctor'], {
      queryParams: {
        pharmaCode: redirectUrlCode
      }
    });
  }

  onPdfError(): void {
    this.isPdfError = true;
  }

  onResourceSelect(resource, type): void {
    this.isPdfError = false;
    this.selectedResource = { url: '', type };
    this.getSelectedUrl(resource.fileName);
  }

  getSelectedUrl(fileName): void {
    const fileExtension =
      fileName.split('.').pop().split(PATTERN.URL_SUFFIX)[0];
    const prefix = 'hcpResource/';
    let contentType = '';
    switch (fileExtension.toLowerCase()) {
      case IMAGE_TYPES.jpeg:
        contentType = MIME_TYPES.JPEG;
        break;
      case IMAGE_TYPES.jpg:
        contentType = MIME_TYPES.JPEG;
        break;
      case IMAGE_TYPES.png:
        contentType = MIME_TYPES.PNG;
        break;
      case IMAGE_TYPES.pdf:
        contentType = MIME_TYPES.PDF;
        break;
      case IMAGE_TYPES.mp4:
        contentType = MIME_TYPES.MP4;
        break;
      case IMAGE_TYPES.mov:
        contentType = MIME_TYPES.MOV;
        break;
    }
    const time = new Date().getMilliseconds();

    if (fileName) {
      this.store.dispatch(FileAction.loadFilePresignedUrl({
        endPoint: APPROVER_CONSTANTS.FILE_SERVICE_URL,
        fileType: 'null',
        fileName: 'hcpResource/' + fileName.split('.')[0],
        contentType,
        module: 'na',
        fileMethod: 'false',
        country: localStorage.getItem('country') === 'PH' ? 'philippines' : 'thailand',
        time
      }));
    }
  }

  /**
   * Unsubscribe the subscriptions.
   */
  ngOnDestroy(): void {
    if (this.doctorDataSubs) {
      this.doctorDataSubs.unsubscribe();
    }
    if (this.paramSub) {
      this.paramSub.unsubscribe();
    }
  }
}
