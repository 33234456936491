<div *ngIf="chatBlocks" #scrollHybrid class="row no-gutters h-100 scrollMe bg-porcelain-white  scroll-effect">
    <div [ngClass]="{
    'col-12 col-md-12 col-lg-12 mx-auto h-100': chatView,
    'col-12 col-md-6 col-lg-5 mx-auto h-100': !chatView
  }">
        <div class="header text-center py-4 bg-white border-bottom" [ngClass]="{
      'd-none scroll-effect': chatView}">
            <!-- <div class="d-flex">
                <img src="{{chatBlocks.productLogo}}" alt="product logo">
                <div class="ml-auto d-md-flex align-items-center d-none float-right my-auto">
                    <img src="assets/images/global.png" class="global mr-1" alt="Logo">
                    <dngx-language-selector [languages]="languages"></dngx-language-selector>
                </div>
            </div> -->
            <div class="row mw-100">
                <div class="col-10 col-lg-11">
                    <img src="{{chatBlocks.productLogo}}" alt="product logo">
                </div>
                <div class="col-2 col-lg-1">
                    <div class="d-flex align-items-center my-4 float-right">
                        <img src="assets/images/global.png" class="global mr-1" alt="Logo">
                    <dngx-language-selector [languages]="languages"></dngx-language-selector>
                    </div>
                    
                </div>
            </div>
            <hr />
            <span class="text-abbey-grey d-flex justify-content-center fnt-size15px ml-2 fnt_Medium">
        {{chatBlocks.chatBotHeading}}
      </span>
        </div>
        <div *ngFor="let blockData of chatBlocks.block; index as i">
            <div *ngIf="blockData.type === this.blockType.INFO && currentTime(i, docquityMessage)">
                <div class="d-flex flex-row align-items-start mt-3 ml-2">
                    <img class="docquityLogo mr-2" src="{{chatBlocks.images.chatLogo}}" alt="docquity logo">
                    <div class="flex-col">
                        <div [innerHTML]="blockData.content" class="mt-1"></div>
                        <div class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
                    </div>
                </div>
            </div>
            <div *ngIf="blockData.type === this.blockType.PHONE_EMAIL_MISMATCH && currentTime(i, docquityMessage)">
                <div class="d-flex flex-row align-items-start mt-3 ml-2">
                    <img class="docquityLogo mr-2" src="{{chatBlocks.images.chatLogo}}" alt="docquity logo">
                    <div class="flex-col">
                        <div class="mt-1 bg-white p-2 text-abbey-grey fnt-size12px medium-font-weight w-75 mr-5">
                            <div [innerHTML]="blockData.content">
                            </div>
                            <button class="btn btn-block btn-outline-success mt-4 fnt-size12px fnt_Medium" (click)="refreshPage()">{{'CONTINUE' | translate}}</button>
                        </div>
                        <div class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
                    </div>
                </div>
            </div>
            <div *ngIf="blockData.type === this.blockType.CONSENT ">
                <div class="d-flex flex-row align-items-start mt-3 ml-2">
                    <img class="mr-2 docquityLogo" src="{{chatBlocks.images.chatLogo}}" alt="docquity logo">
                    <div class="w-100">
                        <div class="bg-white p-2 w-75">
                            <span class="text-abbey-grey fnt-size12px fnt_Medium">{{ checkForOazisProcess() ? this.translate.instant('FD_TITLE_GIVE_CONSENT') : blockData.title}}</span>
                            <div class="calendar-field" *ngIf="checkForThailandCountry() && !checkForOazisProcess()">
                              <p-calendar [showTime]="false" [showIcon]="true" [panelClass]="'bg-white border'" [timeOnly]="false"
                                [placeholder]="'Select Patient Consent Date'"
                                [showIcon]="true"
                                [monthNavigator]="true"
                                [yearNavigator]="true"
                                [touchUI]="false"
                                yearRange="1922:2030"
                                [inline]="false"
                                dateFormat="dd M yy"
                                [maxDate]="maxConsentDate"
                                [readonlyInput]="true"
                                [minDate]="undefined"
                                [inputStyleClass]="'form-control fnt-size12px fnt_Medium bg-porcelain-white'"
                                [styleClass]="(!selectedConsentDate) && consentDateValidation ? 'pcomponent-invalid' : 'border-0'"
                                [disabled]="isSubmissionOngoing || blockData.submitted"
                                [(ngModel)]="defaultConsentDate"
                                [selectOtherMonths]="true"
                                (onClickOutside)="consentDateValidation = true"
                                (onSelect)="selectedConsentDateValue($event)">
                              </p-calendar>
                            </div>
                            <div *ngIf="(!selectedConsentDate) && consentDateValidation" class="text-danger fnt-size10px">
                              {{'CONSENT_DATE' | translate}} {{'IS_REQUIRED' | translate}}
                            </div>
                            <div *ngFor="let item of blockData.attributes; let i=index">
                                <div class="mt-2" *ngIf="i <= 2;">
                                    <div class="d-flex flex-row align-items-start mt-2 custom-control custom-checkbox">
                                        <input class="mr-2 custom-control-input" type="checkbox" id="checkbox_{{i}}" [checked]="true" [disabled]="true">
                                        <label class="custom-control-label" for="checkbox_{{i}}">
                      <span class="fnt-size12px text-rolling-stone-grey text-break"
                        [innerHTML]="convertToUrl(item.title)"></span>
                      <span class="fnt-size12px text-rolling-stone-grey"
                        *ngIf="!readMore && i===2 && blockData.attributes.length>3">...</span>
                      <p class="text-success fnt-size12px cursor-pointer" [ngClass]="{'text-83134D': checkForOazisProcess()}" href="javascript:;" *ngIf="!readMore && i===2"
                        (click)="readMore=true">
                        {{"READ_MORE" | translate}}
                      </p>
                    </label>
                                    </div>
                                </div>
                                <div *ngIf="i > 2 && readMore">
                                    <div class="d-flex flex-row align-items-start mt-2 custom-control custom-checkbox">
                                        <input class="mr-2 custom-control-input" type="checkbox" id="checkbox_{{i}}" [checked]="true" [disabled]="true">
                                        <label for="checkbox_{{i}}" class="custom-control-label">
                      <span class="fnt-size12px text-rolling-stone-grey text-break"
                        [innerHTML]="convertToUrl(item.title)"></span>
                      <p class="text-success fnt-size12px cursor-pointer" [ngClass]="{'text-83134D': checkForOazisProcess()}" href="javascript:;"
                        *ngIf="readMore && i+1===blockData.attributes.length" (click)="readMore=false">
                        {{"READ_LESS" | translate}}
                      </p>
                    </label>
                                    </div>
                                </div>
                            </div>
                            <button class="fnt-size12px fnt_Medium btn btn-block mt-3" type="button" [ngClass]="{'btn-success border-0': !blockData.submitted, 'btn-83134D border-0': checkForOazisProcess() && !blockData.submitted}" [disabled]="(blockData.submitted || this.isSubmissionOngoing) || (checkForThailandCountry() && !checkForOazisProcess() ? consentCalendarValidation :  false)" (click)="givePatientConsent(blockData)">
                <span class="text-white">{{blockData.response}}</span>
              </button>
                        </div>
                        <div class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
                        <div *ngIf="blockData.submitted && currentTime(i, userMessage)">
                            <div class="d-flex justify-content-end">
                                <span class="bg-light-green px-2 py-1 mr-2 rounded fnt-size12px fnt_Medium userReplyMessages w-auto">{{blockData.response}}</span>
                            </div>
                            <div class="d-flex justify-content-end fnt-size10px text-santas-grey mt-2">
                                {{ userTime[i] | date:'shortTime' | lowercase}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="blockData.type === this.blockType.FORM && currentTime(i, docquityMessage)">
                <pap-patient-form [hospitalOptions]="hospitalList" [chatView]="true" [pharmaCode]="pharmaCode" [programName] = "programName" [responseData]="blockData" [logo]="chatBlocks.images.chatLogo" [currentChatBlock]="i" [countries]="countries" [reapplication]="false" [reapplicationCount]="0" [token]="patientToken" [application]="'HYBRID'"
                    (submitPatientData)="submitPatientData($event)" (editPatientData)="editPatientData($event)" (deleteCaregiverData)="deleteCaregiverData($event)" (goBackToEdit)="goBackToEdit($event)" (submitError)="submitError($event)">
                </pap-patient-form>
                <div *ngIf="blockData.submitted && currentTime(i, userMessage)">
                    <div class="d-flex justify-content-end">
                        <span class="bg-light-green px-2 py-1 mr-2 rounded fnt-size12px fnt_Medium userReplyMessages w-auto">{{blockData.response}}</span>
                    </div>
                    <div class="d-flex justify-content-end fnt-size10px text-santas-grey mt-2">
                        {{ userTime[i] | date:'shortTime' | lowercase}}
                    </div>
                </div>
            </div>
            <div *ngIf="!checkForThailandCountry() && blockData.type === this.blockType.OPTION && currentTime(i, docquityMessage)">
                <div>
                    <div class="d-flex flex-row align-items-start mt-3 ml-2">
                        <img class="mr-2 docquityLogo" [src]="chatBlocks.images.chatLogo" alt="docquity logo">
                        <div class="w-100">
                            <div class="bg-white p-2 w-75">
                                <div class="text-abbey-grey fnt-size12px fnt_Medium" [innerHtml]="blockData.title"></div>
                                <button class="btn btn-block btn-outline-success mt-4 fnt-size12px fnt_Medium" [disabled]="blockData.submitted" (click)="careGiverOption(true)">
                  {{ "YES_ADD_CAREGIVER" | translate }}
                </button>
                                <button class="btn btn-block btn-outline-success mt-2 fnt-size12px fnt_Medium" [disabled]="blockData.submitted" (click)="careGiverOption(false)">
                  {{ "NO_ADD_CAREGIVER" | translate }}
                </button>
                            </div>
                            <div *ngIf="currentTime()" class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="blockData.submitted && currentTime(i, userMessage)">
                    <div class="d-flex justify-content-end">
                        <span class="bg-light-green px-2 py-1 mr-2 rounded fnt-size12px fnt_Medium userReplyMessages w-auto">
              {{this.selectedOption || this.translate.instant('YES')}}
            </span>
                    </div>
                    <div class="d-flex justify-content-end fnt-size10px text-santas-grey mt-2">
                        {{ userTime[i] | date:'shortTime' | lowercase}}
                    </div>
                </div>
            </div>
            <div *ngIf="blockData.type === this.blockType.EDIT_DETAIL && currentTime(i, docquityMessage)">
                <div>
                    <div class="d-flex flex-row align-items-start mt-3 ml-2">
                        <img class="mr-2 docquityLogo" [src]="chatBlocks.images.chatLogo" alt="docquity logo">
                        <div class="w-100">
                            <div class="bg-white p-2 w-75">
                                <div class="text-abbey-grey fnt-size12px fnt_Medium">
                                    <div>
                                        <div class="font-weight-bold"> {{ "HEY" | translate }} <span *ngIf="!checkForThailandCountry()">{{ this.patientName }}</span>!</div>
                                        <div>{{ "WHAT_WOULD_YOU_LIKE_TO_DO" | translate}}</div>
                                    </div>
                                </div>
                                <button *ngIf="this.isApproved && !checkForThailandCountry()" class="btn btn-block btn-outline-success mt-3 fnt-size12px fnt_Medium" [disabled]="blockData.submitted" (click)="uploadPrescriptionEvent(i)">
                  {{ "UPLOAD_PRESCRIPTION" | translate }}
                </button>
                                <button *ngIf="this.isApproved && checkForThailandCountry() && !checkForOazisProcess()" class="btn btn-block btn-outline-success mt-3 fnt-size12px fnt_Medium" [disabled]="blockData.submitted" (click)="uploadOrderForm(i)">
                  {{ "UPLOAD_ORDER_FORM" | translate }}
                </button>
                                <button *ngIf="!checkForThailandCountry() && !checkForOazisProcess() && !this.isApproved" [disabled]="blockData.submitted" class="btn btn-block btn-outline-success mt-3 fnt-size12px fnt_Medium editButtons" (click)="uploadPrescriptionEvent(i)">
                  {{ "EDIT_PRESCRIPTION" | translate }}
                  <div class="editButtonsDiv fnt-size10px text-rolling-stone-grey">{{
                    "UPDATE_LATEST_PRESCRIPTION_AND_DOCTOR" | translate }}</div>
                </button>
                <button *ngIf="checkForOazisProcess() && !this.isApproved" [disabled]="blockData.submitted" class="btn btn-block btn-outline-83134D mt-3 fnt-size12px fnt_Medium editButtons" (click)="uploadPrescriptionEvent(i)">
                    {{ "EDIT_SUPPORT_DOCUMENTS" | translate }}
                    <div class="editButtonsDiv fnt-size10px text-rolling-stone-grey">{{
                      "UPDATE_SUPPORT_DOCUMENTS" | translate }}</div>
                  </button>
                                <button *ngIf="checkForThailandCountry() && !checkForOazisProcess() && !this.isApproved" [disabled]="blockData.submitted" class="btn btn-block btn-outline-success mt-2 fnt-size12px fnt_Medium editButtons" (click)="editOrderDetails(i)">
                  {{ "EDIT_ORDER_DETAIL" | translate }}
                  <div class="editButtonsDiv fnt-size10px text-rolling-stone-grey">{{
                    "UPDATE_ORDER_DETAIL" | translate }}</div>
                </button>
                                <button *ngIf="!this.isApproved" [disabled]="blockData.submitted" class="btn btn-block btn-outline-success mt-2 fnt-size12px fnt_Medium editButtons" [ngClass]="{'btn-outline-83134D': checkForOazisProcess()}" (click)="editPatientDetails(i)">
                  {{ "EDIT_PATIENT_DETAILS" | translate }}
                  <div class="editButtonsDiv fnt-size10px text-rolling-stone-grey">
                    <span *ngIf="!checkForThailandCountry()">
                      {{ "UPDATE_YOUR_PERSONAL_DETAILS" |translate }}
                    </span>
                    <span *ngIf="checkForThailandCountry()">
                      {{"UPDATE_YOUR_PATIENT_PERSONAL_DETAILS" | translate}}
                    </span>
                  </div>
                </button>
                                <button *ngIf="checkForThailandCountry() && !checkForOazisProcess() && !this.isApproved" [disabled]="blockData.submitted" class="btn btn-block btn-outline-success mt-3 fnt-size12px fnt_Medium editButtons" (click)="editTreatmentDetails(i)">
                  {{ "EDIT_TREATMENT_DETAIL" | translate }}
                  <div class="editButtonsDiv fnt-size10px text-rolling-stone-grey">{{
                    "UPDATE_HOSPITAL_DETAILS" | translate }}</div>
                </button>
                                <button *ngIf="!this.isApproved && !checkForOazisProcess()" [disabled]="blockData.submitted" class="btn btn-block btn-outline-success mt-2 fnt-size12px fnt_Medium editButtons"  (click)="editPatientIdCard(i)">
                  <span *ngIf="!checkForThailandCountry()">
                    {{ "EDIT_ID_CARD" | translate }}
                  </span>
                  <span *ngIf="checkForThailandCountry()">
                    {{'EDIT_PATIENT_ID_DETAILS' | translate}}
                  </span>
                  <div class="editButtonsDiv fnt-size10px text-rolling-stone-grey">
                    <span *ngIf="!checkForThailandCountry()">
                      {{ "UPLOAD_YOUR_GOVERNMENT_ID" | translate }}
                    </span>
                    <span *ngIf="checkForThailandCountry() && !checkForOazisProcess()">
                      {{"UPLOAD_PATIENT_ID_DETAILS" | translate}}
                    </span>
                  </div>
                </button>
                                <button *ngIf="!this.isApproved && this.showAdditionalInfo" [disabled]="blockData.submitted" class="btn btn-block btn-outline-success mt-2 fnt-size12px fnt_Medium editButtons" (click)="editAdditionalCard(i)">
                  {{ "ADDITIONAL_INFO" | translate }}
                  <div class="editButtonsDiv fnt-size10px text-rolling-stone-grey">{{ "ADDITIONAL_INFO_PLACEHOLDER" |
                    translate }}</div>
                </button>
                                <button *ngIf="!checkForThailandCountry() && !this.isApproved" [disabled]="blockData.submitted" class="btn btn-block btn-outline-success mt-2 fnt-size12px fnt_Medium editButtons" (click)="editCareGiver(i)">
                  {{ "UPDATE_CAREGIVER" | translate }}
                  <div class="editButtonsDiv fnt-size10px text-rolling-stone-grey">{{ "VIEW_CAREGIVER_DETAILS" |
                    translate }}</div>
                </button>
                                <button *ngIf="!this.isApproved && blockData.noFurtherUpdate" [disabled]="blockData.submitted" class="btn btn-block btn-outline-success mt-2 fnt-size12px fnt_Medium" [ngClass]="{'btn-outline-83134D': checkForOazisProcess()}" (click)="noFurtherUpdate(i)">
                  {{ "NO_FURTHER_UPDATES" | translate }}
                </button>
                            </div>
                            <div *ngIf="currentTime()" class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="blockData.submitted && currentTime(i, userMessage)">
                    <div class="d-flex justify-content-end">
                        <span class="bg-light-green px-2 py-1 mr-2 rounded fnt-size12px fnt_Medium userReplyMessages w-auto">
              {{this.blockData.response}}
            </span>
                    </div>
                    <div class="d-flex justify-content-end fnt-size10px text-santas-grey mt-2">
                        {{ userTime[i] | date:'shortTime' | lowercase}}
                    </div>
                </div>
            </div>
            <div *ngIf="blockData.type === this.blockType.THANK_YOU && currentTime(i, docquityMessage)">
                <div class="d-flex flex-row align-items-start mt-3 ml-2 mr-5 p-1">
                    <img class="docquityLogo mr-2" src="{{chatBlocks.images.chatLogo}}" alt="docquity logo">
                    <div class="flex-col">
                        <div class="bg-white p-1 text-abbey-grey fnt-size12px medium-font-weight">
                            <div class="d-flex my-1">
                                <img src="assets/images/thumbs-up.svg" alt="thumbs up" class="signup-thumbs-up d-flex">
                            </div>
                            <div [innerHTML]="blockData.content" class="mt-1"></div>
                        </div>
                        <div class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
                    </div>
                </div>
            </div>
            <div *ngIf="blockData.type === this.blockType.REJECTED && currentTime(i, docquityMessage)">
                <div class="d-flex flex-row align-items-start mt-3 ml-2 mr-5 p-1">
                    <img class="docquityLogo mr-2" src="{{chatBlocks.images.chatLogo}}" alt="docquity logo">
                    <div class="flex-col">
                        <div class="bg-white p-1 text-abbey-grey fnt-size12px medium-font-weight">
                            <div class="mt-1">
                                <p *ngIf="!checkForOazisProcess()" class="mt-2">
                                    <span class="font-weight-bold">{{ "Hi" | translate}} {{ this.patientName }}</span>, {{ "REJECT_REASON_TEXT" | translate}}: {{ this.rejectReasons.join() }}.{{ "UPDATE_DETAIL_TEXT" | translate }}.
                                </p>
                                <p *ngIf="checkForOazisProcess()" class="mt-2">
                                <span class="font-weight-bold">{{ "REJECT_PATIENT_OAZIS" | translate}} {{ this.rejectReasons.join() }}</span>&nbsp;
                                    <span class="font-weight-bold"  [innerHTML]="translate.instant('STILL_LEARN_AT_WEBSITE')"></span>
                                </p>
                            </div>
                        </div>
                        <div class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
                    </div>
                </div>
            </div>
            <div *ngIf="blockData.type === this.blockType.EDIT_SUCCESS && currentTime(i, docquityMessage)">
                <div class="d-flex flex-row align-items-start mt-3 ml-2 mr-5 p-1">
                    <img class="docquityLogo mr-2" src="{{chatBlocks.images.chatLogo}}" alt="docquity logo">
                    <div class="flex-col">
                        <div class="bg-white p-1 text-abbey-grey fnt-size12px medium-font-weight">
                            <div class="d-flex my-1">
                                <img src="assets/images/thumbs-up.svg" alt="thumbs up" class="signup-thumbs-up d-flex">
                            </div>
                            <p class="mt-2"><b>{{ "AWESOME" | translate }}!</b></p>
                            <p *ngIf="this.applicationStatus === 'REJECTED' && !checkForThailandCountry()" class="mt-2">{{ "LATEST_REGISTRATION_MESSAGE" | translate }}.
                            </p>
                            <p *ngIf="this.applicationStatus === 'REJECTED' && checkForThailandCountry() && !checkForOazisProcess()" class="mt-2">{{ "ORDER_FORM_SENT_APPROVAL" | translate }}
                            </p>
                            <p *ngIf="this.applicationStatus !== 'REJECTED' && !checkForThailandCountry()" class="mt-2">{{ "UPDATED_DETAILS_MESSAGE" | translate }}.
                            </p>
                            <p *ngIf="this.applicationStatus !== 'REJECTED' && checkForThailandCountry() && !checkForOazisProcess()" class="mt-2">{{ "UPDATED_DETAILS_MESSAGE_TH" | translate }}.
                            </p>
                            <p *ngIf="checkForThailandCountry() && checkForOazisProcess()" class="mt-2">{{ "SUCCESSFULL_SUBMISSION_V2" | translate }}
                            </p>
                            <p *ngIf="!checkForThailandCountry()" class="mt-2" [innerHTML]="process === unEmbeddedPharma ? translate.instant('PRESCRIPTION_QUERY_V1') : translate.instant('PRESCRIPTION_QUERY')"></p>
                        </div>
                        <div class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
                    </div>
                </div>
            </div>
            <div *ngIf="blockData.type === this.blockType.ALREADY_APPROVED && currentTime(i, docquityMessage)">
                <div class="d-flex flex-row align-items-start mt-3 ml-2 mr-5 p-1">
                    <img class="docquityLogo mr-2" src="{{chatBlocks.images.chatLogo}}" alt="docquity logo">
                    <div class="flex-col">
                        <div class="bg-white p-1 text-abbey-grey fnt-size12px medium-font-weight">
                            <p class="mt-2" *ngIf="checkForOazisProcess()">{{ "NUMBER_ALREADY_EXIST" | translate }}</p>

                            <p class="mt-2" *ngIf="!checkForOazisProcess()">{{ "HEY" | translate }} <span *ngIf="!checkForThailandCountry() && !checkForOazisProcess()">{{ this.patientName }}</span>!</p>
                            <p class="mt-2" *ngIf="!checkForThailandCountry() && !checkForOazisProcess()">{{ "YOUR_DETAILS_ALREADY_APPROVED" | translate }} {{this.programName}}.</p>
                            <p class="mt-2" *ngIf="checkForThailandCountry()  && !checkForOazisProcess()">{{ "THESE_DETAILS_ALREADY_APPROVED" | translate }} {{this.programName}}.</p>
                            <p class="mt-2" *ngIf="!checkForThailandCountry() && !checkForOazisProcess()">{{ "YOU_MAY_UPLOAD_A_FOLLOWUP_PRESCRIPTION" | translate }}</p>
                            <p class="mt-2" *ngIf="checkForThailandCountry() && !checkForOazisProcess()">{{ "YOU_MAY_UPLOAD_A_FOLLOWUP_ORDER" | translate }}</p>
                        </div>
                        <div class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
                    </div>
                </div>
            </div>
            <div *ngIf="blockData.type === this.blockType.EDIT_BUTTON && currentTime(i, docquityMessage)">
                <div>
                    <div class="d-flex flex-row align-items-start mt-3 ml-2 mr-5 p-1">
                        <img class="docquityLogo mr-2" src="{{chatBlocks.images.chatLogo}}" alt="docquity logo">
                        <div class="flex-col">
                            <div class="w-100">
                                <div class="bg-white p-2">
                                    <div class="text-abbey-grey fnt-size12px fnt_Medium">{{ "EDIT_BUTTON_TEXT" | translate }}</div>
                                    <button class="btn btn-block btn-success mt-4 fnt-size12px fnt_Medium" [ngClass]="{'btn-83134D': checkForOazisProcess()}" (click)="editDetails()" [disabled]="blockData.submitted">
                    {{ "YES_EDIT" | translate }}</button>
                                </div>
                                <div class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="blockData.submitted && currentTime(i, userMessage)">
                    <div class="d-flex justify-content-end">
                        <span class="bg-light-green px-2 py-1 mr-2 rounded fnt-size12px fnt_Medium userReplyMessages w-auto">
              {{this.blockData.response}}
            </span>
                    </div>
                    <div class="d-flex justify-content-end fnt-size10px text-santas-grey mt-2">
                        {{ userTime[i] | date:'shortTime' | lowercase}}
                    </div>
                </div>
            </div>
            <div *ngIf="blockData.type === this.blockType.DELETE_CAREGIVER_CONFIRMATION && currentTime(i, docquityMessage)">
                <div>
                    <div class="d-flex flex-row align-items-start mt-3 ml-2">
                        <img class="mr-2 docquityLogo" [src]="chatBlocks.images.chatLogo" alt="docquity logo">
                        <div class="w-100">
                            <div class="bg-white p-2 w-75">
                                <div class="text-abbey-grey fnt-size12px fnt_Medium mb-4">{{'ARE_YOU_SURE_YOU_WANT_TO_REMOVE' | translate}}{{this.careGiverName}} {{'FROM_YOUR_CAREGIVER' | translate}}</div>
                                <button class="btn btn-block btn-outline-success fnt-size12px fnt_Medium" (click)="deleteCareGiverConfirm(i, 'YES_DELETE_IT')" [disabled]="blockData.submitted || this.isSubmissionOngoing">{{'YES_DELETE_IT' | translate}}
                </button>
                                <button class="btn btn-block btn-outline-success fnt-size12px fnt_Medium" (click)="backToCareGiverForm(i, 'NO')" [disabled]="blockData.submitted || this.isSubmissionOngoing">{{'NO' | translate}}
                </button>
                            </div>
                            <div *ngIf="currentTime()" class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="blockData.submitted && currentTime(i, userMessage)">
                    <div class="d-flex justify-content-end">
                        <span class="bg-light-green px-2 py-1 mr-2 rounded fnt-size12px fnt_Medium userReplyMessages w-auto">
              {{blockData.response}}
            </span>
                    </div>
                    <div class="d-flex justify-content-end fnt-size10px text-santas-grey mt-2">
                        {{ userTime[i] | date:'shortTime' | lowercase}}
                    </div>
                </div>
            </div>
            <div *ngIf="blockData.type === this.blockType.ADD_NEW_CAREGIVER && currentTime(i, docquityMessage)">
                <div>
                    <div class="d-flex flex-row align-items-start mt-3 ml-2">
                        <img class="mr-2 docquityLogo" [src]="chatBlocks.images.chatLogo" alt="docquity logo">
                        <div class="w-100">
                            <div class="bg-white p-2 w-75">
                                <div class="d-flex my-1">
                                    <img src="assets/images/thumbs-up.svg" alt="thumbs up" class="signup-thumbs-up d-flex">
                                </div>
                                <div class="text-abbey-grey fnt-size12px fnt_Medium mb-4">{{'CAREGIVER_DELETED_SUCCESS' | translate}}</div>
                                <button class="btn btn-block btn-outline-success fnt-size12px fnt_Medium" (click)="addNewCareGiver(i, 'ADD_NEW_CAREGIVER')" [disabled]="blockData.submitted">{{'ADD_NEW_CAREGIVER' | translate}}
                </button>
                                <button type="button" class="btn btn-block btn-outline-success fnt-size12px fnt_Medium d-flex justify-content-center align-content-center hybridGoBack" (click)="goBackToDetail(i, 'GO_BACK')" [disabled]="blockData.submitted">
                <svg width="20" height="20" fill="none" viewBox="0 0 20 20">
                  <path stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" d="m8.843 15-4.8-4.8 4.8-4.8"/>
                  <path stroke-linecap="round" stroke-width="1.5" d="M4.043 10.2h11.7"/>
                </svg>
                {{'GO_BACK' | translate}}
                </button>
                            </div>
                            <div *ngIf="currentTime()" class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="blockData.submitted && currentTime(i, userMessage)">
                    <div class="d-flex justify-content-end">
                        <span class="bg-light-green px-2 py-1 mr-2 rounded fnt-size12px fnt_Medium userReplyMessages w-auto">
              {{blockData.response}}
            </span>
                    </div>
                    <div class="d-flex justify-content-end fnt-size10px text-santas-grey mt-2">
                        {{ userTime[i] | date:'shortTime' | lowercase}}
                    </div>
                </div>
            </div>
        </div>
    </div>
