/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

/**
 * Rxjs Operators.
 */
import { catchError, mergeMap, map, switchMap } from 'rxjs/operators';

/**
 * Ngrx store
 */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SignupAction from '../../actions/signup/signup.actions';
import * as ErrorAction from '../../actions/error/error.actions';
import * as HybridAction from '../../actions/hybrid/hybrid.actions';
import { Store } from '@ngrx/store';

/**
 * Constants imports.
 */
import { API } from '../../../constant/api.constant';
import { CHATBOT_CONSTANTS, ERROR_CODE } from '../../../constant/app.constant';

/**
 * Models imports.
 */
import {
  CountriesResponse, SignupChatbotResponse, UpdateConsentResponse,
  RegionsResponse, CitiesResponse, UpdateDoctorResponse, HospitalsResponse
} from '../../../models/responses.model';

/**
 * Service Imports.
 */
import { TranslateService } from '@ngx-translate/core';
import { resendOTP } from '../../actions/doctor/doctor.actions';

@Injectable()
export class SignupEffects {

  /**
   * Effect for signup consent data API.
   */
  public loadSignups$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(SignupAction.loadSignups),
        mergeMap((action) =>
          this.http.get<SignupChatbotResponse>(
            API.SIGNUP_CONSENT + action.token + `${action.drshift ? '?drshift=true' : ''}` + `${action.reapplication ? '/reapply' : ''}`
          ).pipe(
            map(res => {
              if (res.success === true && res.data.status === 200) {
                return  SignupAction.setSignupConsentData({ signupConsent: res.data.data });
                // TODO: sumit remove 404.
              } else if (res.success === true && (res.data.status === 404 || res.data.status === 403)) {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.INVALID_TOKEN,
                    message: 'Token is invalid.'
                  }});
              } else {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.DEFAULT_ERROR,
                    message: 'Something went wrong.'
                  }});
              }
            }),
            catchError(err => {
              return [SignupAction.setErrors(
                { error: err.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );

  /**
   * Effect for signup update consent API.
   */
  public updateSignup$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(SignupAction.updateConsent),
        mergeMap((action) =>
          this.http.patch<UpdateConsentResponse>(
            '/' + action.endPoint,
            {consent: action.consent, formId: action.formId}
          ).pipe(
            map(res => {
              if (res.success === true && res.data.status === 200) {
                return  SignupAction.setUpdateConsentData({ updateData: res.data.data });
              } else if (res.success === true && res.data.status === 400) {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.SOMETHING_WENT_WRONG,
                    message: 'Bad request.'
                  }});
              } else {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.DEFAULT_ERROR,
                    message: 'Something went wrong.'
                  }});
              }
            }),
            catchError(err => {
              return [SignupAction.setErrors(
                { error: err.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );

  /**
   * Effect for countries data API.
   */
  public loadCountries$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(SignupAction.loadCountries),
        mergeMap(() =>
          this.http.get<CountriesResponse>(
            API.COUNTRIES
          ).pipe(
            map(res => {
              if (res.success === true && res.data.status === 200) {
                return  SignupAction.setCountriesList({ countryList: res.data.data });
              } else if (res.success === true && res.data.status === 400) {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.SOMETHING_WENT_WRONG,
                    message: 'Bad request.'
                  }});
              } else {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.DEFAULT_ERROR,
                    message: 'Something went wrong.'
                  }});
              }
            }),
            catchError(err => {
              return [SignupAction.setErrors(
                {error: err.error}
              ),
              ErrorAction.catchError({ error: err })
            ];
            })
          )
        )
      )
  );

  /**
   * Effect for regions data API.
   */
   public loadRegions$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(SignupAction.loadRegions),
        mergeMap((action) =>
          this.http.get<RegionsResponse>(
            '/' + action.endPoint
          ).pipe(
            map(res => {
              if (res.success === true && res.data.status === 200) {
                return  SignupAction.setRegionsList({ regionsList: res.data.data });
              } else if (res.success === true && res.data.status === 400) {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.SOMETHING_WENT_WRONG,
                    message: 'Bad request.'
                  }});
              } else {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.DEFAULT_ERROR,
                    message: 'Something went wrong.'
                  }});
              }
            }),
            catchError(err => {
              return [SignupAction.setErrors(
                {error: err.error}
              ),
              ErrorAction.catchError({ error: err })
            ];
            })
          )
        )
      )
  );

  /**
   * Effect for cities data API.
   */
   public loadCities$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(SignupAction.loadCities),
        mergeMap((action) =>
          this.http.get<CitiesResponse>(
            '/' + action.endPoint
          ).pipe(
            map(res => {
              if (res.success === true && res.data.status === 200) {
                return  SignupAction.setCitiesList({ citiesList: res.data.data });
              } else if (res.success === true && res.data.status === 400) {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.SOMETHING_WENT_WRONG,
                    message: 'Bad request.'
                  }});
              } else {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.DEFAULT_ERROR,
                    message: 'Something went wrong.'
                  }});
              }
            }),
            catchError(err => {
              return [SignupAction.setErrors(
                {error: err.error}
              ),
              ErrorAction.catchError({ error: err })
            ];
            })
          )
        )
      )
  );

  /**
   * Effect for submit form data API.
   */
   public submitFormData$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(SignupAction.submitFormData),
        mergeMap((action) => (
          action.method === 'POST' ?
          this.http.post<UpdateConsentResponse>(
            '/' + action.endPoint,
            action.formData,
            {
              headers: action.applicationType === 'HYBRID' ? {
                'pap-hybrid': 'true',
                'pap-authorization': action.idToken,
              } : undefined
            })
            : ( action.method === 'GET' ? this.http.get<UpdateConsentResponse>(
              '/' + action.endPoint,
              {
                headers: action.applicationType === 'HYBRID' ? {
                  'pap-hybrid': 'true',
                  'pap-authorization': action.idToken,
                } : undefined
              }
            ) : this.http.patch<UpdateConsentResponse>(
              '/' + action.endPoint,
              action.formData,
              {
                headers: action.applicationType === 'HYBRID' ? {
                  'pap-hybrid': 'true',
                  'pap-authorization': action.idToken,
                } : undefined
              }))
        )
          .pipe(
            map(res => {

              if (res.success === true && res.data.status === 200 && action.endPoint.includes('verifyOtp')){
                this.store.dispatch(SignupAction.setRequestResendOtp({resendOtp: false}));
                return SignupAction.setFormSubmittedId({formSubmittedId: action.formData.formId});
              } else if (res.success === true && res.data.status === 200) {
                return SignupAction.setFormSubmittedId({formSubmittedId: action.formData.formId});
              } else if (res.success === true && res.data.status === 400) {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.SOMETHING_WENT_WRONG,
                    message: 'Bad request.'
                  }});
              } else if (res.success === true && res.data.status === 401 && action.endPoint.includes('verifyOtp')){

                this.store.dispatch(SignupAction.setRequestResendOtp({resendOtp: true}));
                return SignupAction.setFormSubmittedId({formSubmittedId: action.formData.formId});
              } else {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.DEFAULT_ERROR,
                    message: 'Something went wrong.'
                  }});
              }
            }),
            catchError(err => {
              if (err?.error?.data?.error?.code === ERROR_CODE.E4024) {
                return [HybridAction.resetCaregiver({}), HybridAction.setErrors({error: err?.error?.data?.error})];
              }
              return [SignupAction.setErrors(
                {error: err.error}
              ),
              ErrorAction.catchError({ error: err })
            ];
            })
          )
        )
      )
  );

  /**
   * Effect for getting file upload url.
   */
   public loadFileUploadUrl$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(SignupAction.loadFileUploadUrl),
        switchMap((action) =>
          // TODO: sumit add data typing.
          this.http.get<any>(
            '/' + action.endPoint, {
              params: {
                type: action.fileType,
                fileName: action.fileName,
                contentType: action.contentType,
                module: action.module ? action.module : 'pap',
                country: action.country ? action.country : 'india'
              }
            }
          ).pipe(
            map(res => {
              if (res.success === true) {
                return SignupAction.setFileUploadUrl({ fileUploadUrl: res.data.preSignedUrl});
              } else {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.DEFAULT_ERROR,
                    message: 'Something went wrong.'
                  }});
              }
            }),
            catchError(err => {
              return [SignupAction.setErrors(
                {error: err.error}
              ),
              ErrorAction.catchError({ error: err })
            ];
            })
          )
        )
      )
  );

  /**
   * Effect for uploading file.
   */
   public fileUpload$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(SignupAction.fileUpload),
        switchMap((action) => {
          return this.http.put<any>(
            action.endPoint,
            action.file
          ).pipe(
            map(res => {
              return SignupAction.setFileUploadStatus({ fileUploadStatus: 'done' });
            }),
            catchError(err => {
              return [SignupAction.setErrors(
                {error: err.error}
              ),
              SignupAction.setFileUploadStatus({fileUploadStatus: 'error'}),
              ErrorAction.catchError({ error: err })
            ];
            })
          );
        })
      )
  );



  /**
   * Effect for submit form data API.
   */
   public updateDoctor$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(SignupAction.updateDoctor),
        mergeMap((action) =>
          this.http.patch<UpdateDoctorResponse>(
            '/' + action.endPoint,
            {
              formId: action.formId, isShifted: action.isShifted, currDoc: action.currDoc,
              oldDoc: action.oldDoc, patient: action.patient
            }
          ).pipe(
            map(res => {
              if (res.success === true && res.data.status === 200) {
                return SignupAction.setFormSubmittedId({formSubmittedId: action.formId});
              } else if (res.success === true && res.data.status === 400) {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.SOMETHING_WENT_WRONG,
                    message: this.translate.instant('BAD_REQUEST')
                  }});
              } else {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.DEFAULT_ERROR,
                    message: this.translate.instant('SOMETHING_WENT_WRONG')
                  }});
              }
            }),
            catchError(err => {
              return [SignupAction.setErrors(
                {error: err.error}
              ),
              ErrorAction.catchError({ error: err })
            ];
            })
          )
        )
      )
  );

  /**
   * Effect for hospital data API.
   */
  public loadHospitals$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(SignupAction.loadHospitals),
        mergeMap((action) =>
          this.http.get<any>(
            API.HOSPITAL_LIST + action.token + '/hospital'
          ).pipe(
            map(res => {
              if (res.success === true && res.data.status === 200) {
                return  SignupAction.setHospitalList({ hospitalList: res.data.data });
              } else if (res.success === true && res.data.status === 400) {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.SOMETHING_WENT_WRONG,
                    message: 'Bad request.'
                  }});
              } else {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.DEFAULT_ERROR,
                    message: 'Something went wrong.'
                  }});
              }
            }),
            catchError(err => {
              return [SignupAction.setErrors(
                {error: err.error}
              ),
              ErrorAction.catchError({ error: err })
            ];
            })
          )
        )
      )
  );

  /**
   * Effect for load user based on hospital data.
   */
  public getUserForHospital$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(SignupAction.getUserForHospital),
        mergeMap((action) =>
        this.http.get<any>(
          API.HOSPITAL_USERS + action.hospitalId + '/users',
         ).pipe(
            map(res => {
              console.log('res:::::::::', res);
              if (res.success === true && res.status === 200) {
                return  SignupAction.setUsersList({ usersList: res.data });
              } else if (res.success === true && res.status === 400) {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.SOMETHING_WENT_WRONG,
                    message: 'Bad request.'
                  }});
              } else {
                  return SignupAction.setErrors({ error: {
                    name: CHATBOT_CONSTANTS.DEFAULT_ERROR,
                    message: 'Something went wrong.'
                  }});
              }
            }),
            catchError(err => {
              return [SignupAction.setErrors(
                {error: err.error}
              ),
              ErrorAction.catchError({ error: err })
            ];
            })
          )
        )
      )
    );

  /**
   * Declare actions and httpsClient for effects.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private store: Store,
    private translate: TranslateService
  ) { }
}
