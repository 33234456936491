import { LOCAL_STORAGE_COUNTRY, COUNTRIES_LIST, COUNTRIES_NAME, USER_TYPE, UN_EMBEDDED_PHARMA } from './../../../../constant/app.constant';
/**
 * angular imports.
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
/**
 * constant imports
 */
import { APPROVER_CONSTANTS, PATTERN, IMAGE_TYPES, MIME_TYPES, ALL_PAP_FETCH_LIMIT } from '../../../../constant/app.constant';
import { ProgramData } from '../../../../models/sign-up-chatbot.model';
import { PharmaDetail, DoctorToken } from '../../../../models/responses.model';
/**
 * store imports.
 */
import { Store } from '@ngrx/store';
import * as FileAction from '../../../../store/actions/file/file.action';
import * as FileSelector from '../../../../store/selectors/file/file.selectors';
import * as DoctorAction from '../../../../store/actions/doctor/doctor.actions';
import * as DoctorSelector from '../../../../store/selectors/doctor/doctor.selectors';
import * as PharmaSelector from '../../../../store/selectors/pharma/pharma.selectors';
import * as PharmaAction from '../../../../store/actions/pharma/pharma.actions';
import * as LoginSelector from '../../../../store/selectors/login/login.selectors';

/**
 * model imports
 */
import { DoctorData } from 'projects/nga-PAP/src/app/models/responses.model';
/**
 * Jwt import.
 */
import { JwtHelperService } from '@auth0/angular-jwt';
/**
 * router imports.
 */
import { Router, ActivatedRoute } from '@angular/router';
/**
 * Rxjs import.
 */
import { Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
/**
 * service imports.
 */
import { HelperService } from 'projects/nga-PAP/src/app/helper.service';

/**
 * component to show doctor profile page
 */
@Component({
  selector: 'pap-view-doctor',
  templateUrl: './view-doctor.component.html',
  styleUrls: ['./view-doctor.component.scss']
})

export class ViewDoctorComponent implements OnInit, OnDestroy {

  /**
   * constant to access user type
   */
  readonly userType = USER_TYPE;

  /**
   * to store doctor detail
   */
  public speciality: string;
  public element: DoctorData = null;
  public userProfile: string;
  /**
   * public doctor pap programs
   */
  public papPrograms: FormGroup;
  /**
   * used to subscribe pap program.
   */
  public addDoctorProgramSub: Subscription;
  /**
   * doctor data subscription
   */
  public doctorDataSubs: Subscription;
  /**
   * query param sub
   */
  private paramSub: Subscription;
  /**
   * This is used to hold the patient id card. by default default id card is initialized.
   */
  public DoctorIdCard = [{
    source: '',
    alt: '',
  }];
  /**
   * This is used to hold the patient signature card. by default default id card is initialized.
   */
  public DoctorSignature = [{
    source: '',
    alt: '',
  }];
  public DoctorPrcUrl: string;
  /**
   * variable to show pop up
   */
  public displayModal = false;
  /**
   * userId
   */
  public userId: number;
  /**
   * to store doctor signature url
   */
  public DoctorSignatureUrl: string;
  /**
   * to store pharma code
   */
  public pharmaCode: string = null;
  /**
   * to store pharma detail
   */
  public pharmaDetail: PharmaDetail;
  /**
   * to store pharma detail
   */
  public decodedToken: DoctorToken;
  /**
   * to store pharma detail
   */
  public pharmaId: string;
  /**
   * used to store speciality program
   */
  public doctorSpecialityPrograms: ProgramData[];
  /**
   * used to store mapped pap with speciality
   */
  public programData: ProgramData[];
  /**
   * flag variable for is  doctor associated with pharma
   */
  public doctorExistWithPharma: boolean;
  /**
   * File upload url subscription.
   */
  public fileUploadUrlSub: Subscription;
  /**
   * pharma detail subscription
   */
  public pharmaSubs: Subscription;
  /**
   * subscription for successful edit
   */
  public doctorEditSubs: Subscription;
  /**
   * timeout subscription
   */
  public timeOut: Subscription;
  /**
   * system time.
   */
  public sysTime: number;
  /**
   * Session timeout subscription.
   */
  public sessionTimeOutSub: Subscription;
  /**
   * get country from local storage
   */
  private country: string;
  public findDoctor: boolean;
  public userRole: any;
  private subscriptions: Subscription = new Subscription();

  /**
   * to submit new pap enrollments
   */
  submitForm(): void {
    this.store.dispatch(DoctorAction.editDoctorFormData({
      id: this.userId, token: localStorage.getItem('doctorAuth'),
      formData: this.papPrograms.value
    }));
    this.doctorEditSubs = this.store.select(DoctorSelector.doctorEditSuccess).subscribe(data => {
      if (data != null) {
        if (data === true) {
          this.store.dispatch(DoctorAction.resetDoctorData({}));
          this.displayModal = true;
          this.timeOut = timer(4000).subscribe(res => {
            window.location.reload();
          });
        }
      }
    });
  }
  /**
   * formInitlaize for pap programs
   */
  formInitialization(): void {
    this.papPrograms = this.formBuilder.group({
      papIds: new FormArray([], Validators.required),
      consent: new FormControl('', Validators.required)
    });
  }
  /**
   * function to change selection of pap array
   */
  onCheckChange(event): void {
    const formArray: FormArray = this.papPrograms.get('papIds') as FormArray;

    if (event.target.checked) {
      formArray.push(new FormControl(event.target.value));
    }
    else {
      let i = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value === event.target.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  /**
   * logout session
   */
  logout(): void {
    localStorage.removeItem('doctorAuth');
    window.location.reload();
  }
  /**
   * Fetching aws get presigned url.
   */
  fetchGetPresignedUrl(fileType: string, fileName: string, contentType: string, module?: string): Promise<string> {
    const countryCode = localStorage.getItem(LOCAL_STORAGE_COUNTRY.COUNTRY);
    let countryName = COUNTRIES_NAME[countryCode];
    if (!countryName) {
      countryName = COUNTRIES_LIST.PHILLIPPINS.name;
    }
    const time = new Date().getMilliseconds();
    return new Promise((resolve, reject) => {
      this.store.dispatch(FileAction.loadFilePresignedUrl({
        endPoint: APPROVER_CONSTANTS.FILE_SERVICE_URL,
        fileType,
        fileName,
        contentType,
        fileMethod: 'false',
        module,
        country: countryName,
        time
      }));
      this.fileUploadUrlSub = this.store.select(FileSelector.getFilePresignedUrl).subscribe(url => {
        if (url != null) {
          resolve(url);
          this.store.dispatch(FileAction.resetFilePresignedUrl({
            presignedUrl: null
          }));
        }
      });
    });
  }
  /**
   * function to get content type of file
   */
  getContentType(extension: string): string {
    let contentType = '';
    switch (extension.toLowerCase()) {
      case IMAGE_TYPES.jpeg:
        contentType = MIME_TYPES.JPEG;
        break;
      case IMAGE_TYPES.jpg:
        contentType = MIME_TYPES.JPG;
        break;
      case IMAGE_TYPES.png:
        contentType = MIME_TYPES.PNG;
        break;
    }
    return contentType;
  }
  /**
   * function to get doctor prc url and signature
   */
  async getDoctorPRC(element: any): Promise<void> {
    if (element === '') {
      this.DoctorIdCard[0].source = 'assets/images/philippines-dummy.png';
      this.DoctorIdCard[0].alt = 'PRC Document';
      return;
    }
    const fileExtension = element.split('.').pop().split(PATTERN.URL_SUFFIX)[0];
    const contentType = this.getContentType(fileExtension);
    const path = 'doctor/document' + '/' + element.split('.')[0];
    this.DoctorPrcUrl = await this.fetchGetPresignedUrl('null', path, contentType, 'na');
    this.DoctorIdCard[0].source = this.DoctorPrcUrl;
  }
  async getDoctorSignature(element: any): Promise<void> {
    if (element === '') {
      this.DoctorSignature[0].source = 'assets/images/philippines-dummy.png';
      this.DoctorSignature[0].alt = 'Doctor Signature Document';
      return;
    }
    const fileExtension = element.split('.').pop().split(PATTERN.URL_SUFFIX)[0];
    const contentType = this.getContentType(fileExtension);
    const path = 'signature_image' + '/' + element.split('.')[0];
    this.DoctorSignatureUrl = await this.fetchGetPresignedUrl('null', path, contentType, 'na');
    this.DoctorSignature[0].source = this.DoctorSignatureUrl;
  }
  /**
   * function to navigate to edit page
   */
  doctorEdit(): void {
    console.log('doctorEdit', this.findDoctor);
    if (!this.findDoctor) {
      this.store.dispatch(DoctorAction.resetDoctorData({}));
      if (this.pharmaCode) {
        this.router.navigate(['/addDoctor'], {
          queryParams: {
            pharmaCode: this.pharmaCode
          },
        });
      }
      else {
        this.router.navigate(['/addDoctor']);
      }
    } else {
      this.store.dispatch(DoctorAction.resetDoctorData({}));
      if (this.pharmaCode) {

        if (this.userRole === USER_TYPE.APPROVER) {
          this.router.navigate(['approval/findDoctor/editDoctor'], {
            queryParams: {
              pharmaCode: this.pharmaCode,
              role: this.userRole
            },
          });
        } else {
          this.router.navigate(['supervisor/findDoctor/editDoctor'], {
            queryParams: {
              pharmaCode: this.pharmaCode,
              role: this.userRole
            },
          });
        }
      }
    }
  }
  constructor(
    public store: Store,
    public router: Router,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private decodeToken: HelperService
  ) {
    /**
     * block browser back button
     */
    history.pushState(null, '', location.href);
    window.onpopstate = (): void => {
      history.go(1);
    };
  }
  ngOnInit(): void {
    this.getUserRole();
    console.log('this.router.url', this.router.url.split('?')[0]);
    this.findDoctor = ((this.router.url.split('?') && ((this.router.url.split('?')[0]) === '/approval/findDoctor/ViewDoctorDetail') ? true : false || (this.router.url.split('?') && this.router.url.split('?')[0]) === '/supervisor/findDoctor/ViewDoctorDetail') ? true : false);
    if (this.findDoctor) {
      this.store.dispatch(DoctorAction.resetDoctorData({}));
    }
    console.log('this.findDoctor', this.findDoctor);
    this.paramSub = this.route.queryParams
      .pipe(take(1)).subscribe(params => {
        if (params.pharmaCode) {
          this.pharmaCode = params.pharmaCode;
        }
      });
    this.country = localStorage.getItem(LOCAL_STORAGE_COUNTRY.COUNTRY);
    if (!this.country) {
      this.country = COUNTRIES_LIST.PHILLIPPINS.value;
      localStorage.setItem(LOCAL_STORAGE_COUNTRY.COUNTRY, COUNTRIES_LIST.PHILLIPPINS.value);
    }
    if (localStorage.getItem('doctorAuth')) {
      this.sysTime = Math.floor(Date.now() / 1000);
      const idToken = localStorage.getItem('doctorAuth');
      const decodeToken = this.decodeToken.decodeTokenFunc(idToken);
      this.sessionTimeOut(this.sysTime, decodeToken.exp);
      if (decodeToken) {
        this.decodedToken = decodeToken;
        this.userId = this.decodedToken.data.userId;
      }
      if (this.pharmaCode) {
        this.pharmaId = localStorage.getItem('pharmaId')?.toString();
      }
    }
    else {
      if (!this.findDoctor) {
        if (this.pharmaCode) {
          this.redirectionToPharmaPage();
        }
        else {
          this.router.navigate(['/doctor/login/' + this.country]);
        }
      } else {
        if (localStorage.getItem('findDoctorAuth')) {
          this.sysTime = Math.floor(Date.now() / 1000);
          const idToken = localStorage.getItem('findDoctorAuth');
          console.log('idToken', idToken);
          const decodeToken = this.decodeToken.decodeFindDoctorTokenFunc(idToken);
          console.log('decodeToken', decodeToken);
          // this.sessionTimeOut(this.sysTime, decodeToken.exp);
          if (decodeToken) {
            this.decodedToken = decodeToken;
            this.userId = this.decodedToken.data.userId;
          }
          if (this.pharmaCode) {
            this.pharmaId = localStorage.getItem('pharmaId')?.toString();
          }
        } else {
          this.router.navigate(['/findDoctor']);
        }
      }
    }
    this.formInitialization();
    if (this.pharmaCode) {
      this.store.dispatch(PharmaAction.loadPharmas({ pharmaCode: this.pharmaCode }));
      this.pharmaSubs = this.store.select(PharmaSelector.getPharmaDetail).subscribe(data => {
        if (data != null) {
          this.pharmaDetail = data;
        }
      });
    }
    this.store.dispatch(DoctorAction.getDoctorDetail(
      { id: this.userId, token: this.findDoctor ? localStorage.getItem('findDoctorAuth') : localStorage.getItem('doctorAuth'), pharmaCode: this.pharmaCode }));
    this.doctorDataSubs = this.store.select(DoctorSelector.getDoctorData).subscribe(async data => {
      if (data !== null && this.element === null) {
        this.element = data;
        this.speciality = this.element?.specialities[0]?.name;
        const specialityCode = this.element?.specialities[0]?.id;
        this.doctorExistWithPharma = this.element.mappedWithPharma;
        this.store.dispatch((DoctorAction.loadPapPrograms({ pharma: this.pharmaId, limit: ALL_PAP_FETCH_LIMIT })));
        this.addDoctorProgramSub = this.store.select(DoctorSelector.getPapProgram).subscribe(value => {
          if (value != null) {
            this.doctorSpecialityPrograms = value;
            this.programData = this.doctorSpecialityPrograms.filter((element) => {
              const papMappings = JSON.parse(element.Program.Mappings);
              for (const ele1 of papMappings) {
                if (ele1?.Specaility === specialityCode) {
                  return true;
                }
              }
              return false;
            });
          }
        });
        await this.getDoctorPRC(data.registrationDetails.document);
        await this.getDoctorSignature(data.clinicDetails.digitalSignature);
        this.userProfile = data?.personalDetails?.firstName + ' ' + data?.personalDetails?.lastName;
        if (sessionStorage.getItem('resource')) {
          this.navigateToResources();
        }
      }
    });
    this.DoctorIdCard[0].alt = 'default idcard';
  }

  getUserRole(): void {
    this.subscriptions.add(this.store.select(LoginSelector.getUserCredential).subscribe(data => {
      if (data) {
        const idToken = data.accessToken;
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(idToken);
        this.userRole = decodedToken.role;
      }
    }));
  }

  redirectionToPharmaPage(): void {
    const redirectionUrl = localStorage.getItem('pharmaUrl');
    const redirectUrlCode = localStorage.getItem('pharmaCode');
    this.router.navigate([redirectionUrl], {
      queryParams: {
        code: redirectUrlCode
      }
    });
  }
  /**
   * used to navigate ae reporting page.
   */
  public navigateToAEReporting(): void {
    const redirectUrlCode = localStorage.getItem('pharmaCode');
    this.router.navigate(['doctor/ae-reporting'], {
      queryParams: {
        pharmaCode: redirectUrlCode
      }
    });
  }
  /**
   * used to navigate ae reporting page.
   */
  public navigateToResources(): void {
    const redirectUrlCode = localStorage.getItem('pharmaCode');
    this.router.navigate(['doctor/resources'], {
      queryParams: {
        pharmaCode: redirectUrlCode
      }
    });
  }
  /**
   * unsubscribe the variables.
   */
  ngOnDestroy(): void {
    if (this.doctorDataSubs) {
      this.doctorDataSubs.unsubscribe();
    }
    if (this.fileUploadUrlSub) {
      this.fileUploadUrlSub.unsubscribe();
    }
    if (this.paramSub) {
      this.paramSub.unsubscribe();
    }
    if (this.pharmaSubs) {
      this.pharmaSubs.unsubscribe();
    }
    if (this.addDoctorProgramSub) {
      this.addDoctorProgramSub.unsubscribe();
    }
    if (this.doctorEditSubs) {
      this.doctorDataSubs.unsubscribe();
    }
    if (this.timeOut) {
      this.timeOut.unsubscribe();
    }
    if (this.sessionTimeOutSub) {
      this.sessionTimeOutSub.unsubscribe();
    }
  }
  /**
   * reload component when session expired.
   */
  sessionTimeOut(sysTime: number, expTime: number): void {
    const time = expTime - sysTime > 0 ? expTime - sysTime : 0;
    this.sessionTimeOutSub = timer(time * 1000).subscribe(res => {
      localStorage.removeItem('doctorAuth');
      this.redirectionToPharmaPage();
    });
  }

  /**
   * Check for the thailand country.
   */
  checkForThailandCountry(): boolean {
    return localStorage.getItem('country') === COUNTRIES_LIST.THAILAND.value;
  }

  /**
   * Check if country is thailand.
   */
  checkForIsRegistrationInPh(): boolean {
    return sessionStorage.getItem('process') === UN_EMBEDDED_PHARMA;
  }

  /**
   * Check if country is thailand.
   */
  checkForIsPhilippineCountry(): boolean {
    return localStorage.getItem('country') === COUNTRIES_LIST.PHILLIPPINS.value;
  }
}
