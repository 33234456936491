import { createSelector } from '@ngrx/store';
import * as fromRoot from '../reducers/index';
import * as fromLogin from '../reducers/home.reducer';
import { getHome } from '.';


export const home = (state: fromRoot.State) => state.homeData;


/**
 * Selector for user credential.
 */
export const getHomeList = createSelector(
  getHome,
  (state: fromLogin.State) => state.HomeList
);

/**
 * Selector for login error data.
 */
export const getHommeError = createSelector(
  getHome,
  (state: fromLogin.State) => state.error
);
/**
 * Selector for get prescription detail.
 */
export const getPrescription = createSelector(
  getHome,
  (state: fromLogin.State) => state.priscriptionDetail
);

export const getSmsDashboard = createSelector(
  getHome,
  (state: fromLogin.State) => state.otpData
);

/**
 * Selector for search drug list
 */
export const getSearchDrugList = createSelector(
  getHome,
  ((state: fromLogin.State) => state.drugSearchList)
);

/**
 * Selector for search drug list
 */

export const getMrnSearchList = createSelector(
  getHome,
  ((state: fromLogin.State) => state.mrnSearchData)
);
/**
 * selector for mrn error data
 */
export const getMrnError =  createSelector(
  getHome,
  (state: fromLogin.State) => state.MrnError
);
/**
 * Selector for patient Migration List
 */
export const getPatientMigrationList = createSelector(
  getHome,
  ((state: fromLogin.State) => state.PatientMigrationList)
);
/**
 * Selector for patient Migration enable success
 */
export const getSuccesEnable = createSelector(
  getHome,
  ((state: fromLogin.State) => state.PatientEnabled)
);
/**
 * Selector for prescription remark data.
 */
export const getRemarksData = createSelector(
  getHome,
  ((state: fromLogin.State) => state.RemarksData)
);
/**
 * Selector for consulted doctors.
 */
export const getConsultedDoctors = createSelector(
  getHome,
  ((state: fromLogin.State) => state.consultedDoctors)
);
/**
 * Selector for get patient list.
 */
export const getPatientList = createSelector(
  getHome,
  ((state: fromLogin.State) => state.PatientList)
);
/**
 * Selector for get patient details.
 */
export const getPatientDetails = createSelector(
  getHome,
  ((state: fromLogin.State) => state.patientDetails)
);
/**
 * Selector for get patient status.
 */
export const getPatientStatus = createSelector(
  getHome,
  ((state: fromLogin.State) => state.patientStatus)
);
/**
 * Selector for get patient prescription details.
 */
export const getPatientPrescriptionDetail = createSelector(
  getHome,
  ((state: fromLogin.State) => state.patientPrescription)
);

/**
 * Selector for get patient prescription details.
 */
export const getOrderProgramId = createSelector(
  getHome,
  ((state: fromLogin.State) => state.programId)
);

/**
 * Selector for get patient notify status.
 */
export const getNotifyStatus = createSelector(
  getHome,
  ((state: fromLogin.State) => state.notifyStatus)
);
/**
 * Selector for get patient notify status.
 */
export const getNotifyStatusCta = createSelector(
  getHome,
  ((state: fromLogin.State) => state.notifyStatusCta)
);
/**
 * Selector for get order number.
 */
export const getOrderNumber = createSelector(
  getHome,
  ((state: fromLogin.State) => state.orderNumber)
);
/**
 * Selector for get delivery date.
 */
export const getDeliveryDate = createSelector(
  getHome,
  ((state: fromLogin.State) => state.deliveryDate)
);
/*
 * Selector to get ae form list.
 */
export const getAeFormList = createSelector(
  getHome,
  (state: fromLogin.State) => state.aeFormList
);

/**
 * Selector to get ae form list.
 */
export const getPatientDetail = createSelector(
  getHome,
  (state: fromLogin.State) => state.patientDetail
);

/**
 * Selector to get ae form list.
 */
export const getAeSubmitRes = createSelector(
  getHome,
  (state: fromLogin.State) => state.aeSubmitRes
);

/**
 * Selector to get ae acknowledgement form .
 */
export const getAcknowledgmentSubmitRes = createSelector(
  getHome,
  (state: fromLogin.State) => state.ackSubmitRes
);

/**
 * Selector to get ae form list.
 */
export const getPharmaProcess = createSelector(
  getHome,
  (state: fromLogin.State) => state.process
);

/**
 * Selector for get ae report list.
 */
export const getAeReportList = createSelector(
  getHome,
  ((state: fromLogin.State) => state.AeReportList)
);

/**
 * selector for get pharma detail list
 */
export const getPharmDetails =  createSelector(
  getHome,
  ((state: fromLogin.State) => state.pharmaDetailList)
);

/**
 * Selector for get ae report.
 */
export const getAeReport = createSelector(
  getHome,
  ((state: fromLogin.State) => state.aeReport)
);

/**
 * Selector for get users list
 */
export const getUsersList = createSelector(
  getHome,
  ((state: fromLogin.State) => state.usersList)
);

/**
 * Selector to get pharma program list
 */
export const getPharmaProgramList = createSelector(
  getHome,
  ((state: fromLogin.State) => state.pharmaProgramList)
);
