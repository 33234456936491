<!-- TODO: Rahul: handle text from language file. --->
<div class="row no-gutters h-100 doctorRegistration">
  <div *ngIf="!DCPMSupervisor && !findDoctor" class="col-md-2 d-none d-md-block">
    <div class="bg-white h-100 doctorRegistration__leftBar border-right">
      <div class="sticky-top h-100">
        <div class="text-center p-3">
          <img [src]="logoSrc" alt="Logo">
        </div>
        <!-- Vertical navbar -->
        <nav class="vertical-nav bg-white h-100 pl-3 mt-3 pr-1" id="sidebar">
          <ul class="nav flex-column bg-white mb-0 leftNav h-100">
            <li class="nav-item mb-1" [ngClass]="{'active': !this.isExistingUser}">
              <div *ngIf="!this.isExistingUser">
                <a
                  class="cursor-pointer nav-link text-dark bg-light fnt-size15px d-flex align-items-center w-100 rounded">
                  <img src="assets/images/profile-logo.svg" alt="profile">
                  <span>{{ "REGISTRATION" | translate }}
                  </span>
                </a>
              </div>
              <div *ngIf="this.isExistingUser">
                <a class="cursor-pointer nav-link text-dark bg-light fnt-size15px d-flex align-items-center w-100 rounded"
                  (click)="navigateToDoctorHome()">
                  <img src="assets/images/profile-logo.svg" alt="profile">
                  <span>{{ "PROFILE" | translate }}</span>
                </a>
              </div>
            </li>

            <li class="nav-item" *ngIf="this.isExistingUser">
              <a class="cursor-pointer nav-link text-dark bg-light fnt-size15px d-flex align-items-center w-100 rounded"
                (click)="navigateToAEReporting()">
                <img src="assets/images/profile-logo.svg" alt="profile">
                <span>{{ "REPORT_ADVERSE_EVENT" | translate }}</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="this.isExistingUser">
              <a class="cursor-pointer nav-link text-dark bg-light fnt-size15px d-flex align-items-center w-100 rounded"
                (click)="navigateToResources()">
                <img src="assets/images/resource-active.svg" alt="profile">
                <span>{{ "RESOURCES" | translate }}</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div [ngClass]="{'col-md-10' : !DCPMSupervisor, 'col-md-12' : DCPMSupervisor, 'col-md-12 px-4' : findDoctor}">
    <!-- header -->
    <div *ngIf="!DCPMSupervisor && !findDoctor" class="bg-white p-3 border-bottom doctorRegistration__header sticky-top header-view">

      <div *ngIf="!this.UserProfile" class="float-left d-block d-md-none">
        <img [src]="logoSrc" alt="Logo" />
      </div>
      <pap-doctor-header *ngIf="this.UserProfile" [editDoctor]="true" [doctorDetails]="existingData"
        [userProfile]="UserProfile"></pap-doctor-header>

    </div>
    <!-- form -->
    <div class="p-3">
      <div class="mb-3" *ngIf="this.renderForm">
        <form [formGroup]="doctorForm" autocomplete="off" action="">
          <div *ngIf="!this.isExistingUser">
            <p class="fnt-size20px font-weight-bold mb-3">{{ "REGISTRATION" | translate }}</p>
          </div>
          <div *ngIf="this.isExistingUser">
            <p class="fnt-size20px font-weight-bold mb-3">{{ "EDIT_PROFILE" | translate }}</p>
          </div>
          <div class="border rounded bg-white p-3">
            <h4 *ngIf="checkForThailandCountry()">{{'DOCTOR_DETAILS' | translate}}</h4>
            <div formGroupName="personalDetails">
              <p class="mb-2 font-weight-bold" *ngIf="!checkForThailandCountry()">{{ "DOCTOR_FIRST_NAME" | translate }}<span class="text-danger">*</span>
              </p>
              <div class="row">
                <div [ngClass]="{'col-md-6': checkForThailandCountry(), 'col-md-4' : checkForIsPhilippineCountry()}">
                  <div class="form-group">
                    <p class="mb-2 font-weight-bold" *ngIf="checkForThailandCountry()">{{ "FIRST_NAME" | translate }}<span class="text-danger">*</span></p>
                    <input formControlName="firstName" type="text" class="form-control fnt-size14px"
                      placeholder="{{ 'ENTER_FIRST_NAME' | translate }}" />
                    <div
                      *ngIf="doctorForm.get('personalDetails.firstName').touched && doctorForm.get('personalDetails.firstName').errors?.required"
                      class="text-danger fnt-size12px"> {{ "DOCTOR_REG_FIRST_NAME_REQ" | translate }}</div>
                    <div
                      *ngIf="doctorForm.get('personalDetails.firstName').touched && doctorForm.get('personalDetails.firstName').errors?.pattern"
                      class="text-danger fnt-size12px"> {{ "DOCTOR_REG_FIRST_NAME_ERR" | translate }}</div>
                    <div
                      *ngIf="doctorForm.get('personalDetails.firstName').touched && doctorForm.get('personalDetails.firstName').errors?.maxlength"
                      class="text-danger fnt-size12px"> {{ "DOCTOR_REG_FIRST_NAME_MAXLENGTH" | translate }}</div>
                  </div>
                </div>
                <div class="col-md-4" *ngIf="checkForIsPhilippineCountry()">
                  <div class="form-group">
                    <input formControlName="middleName" type="text" class="form-control fnt-size14px"
                      placeholder="{{ 'ENTER_MIDDLE_NAME' | translate }}" />
                    <div
                      *ngIf="doctorForm.get('personalDetails.middleName').touched && doctorForm.get('personalDetails.middleName').errors?.pattern"
                      class="text-danger fnt-size12px"> {{ "DOCTOR_REG_MIDDLE_NAME_ERR" | translate }}</div>
                    <div
                      *ngIf="doctorForm.get('personalDetails.middleName').touched && doctorForm.get('personalDetails.middleName').errors?.maxlength"
                      class="text-danger fnt-size12px"> {{ "DOCTOR_REG_MIDDLE_NAME_MAXLENGTH" | translate }}</div>
                  </div>
                </div>
                <div [ngClass]="{'col-md-6': checkForThailandCountry(), 'col-md-4' : checkForIsPhilippineCountry()}">
                  <div class="form-group">
                    <p class="mb-2 font-weight-bold" *ngIf="checkForThailandCountry()">{{ "LAST_NAME" | translate }}<span class="text-danger">*</span></p>
                    <input formControlName="lastName" type="text"
                      class="form-control fnt-size14px" placeholder="{{'ENTER_LAST_NAME' | translate }}" />
                    <div
                      *ngIf="doctorForm.get('personalDetails.lastName').touched && doctorForm.get('personalDetails.lastName').errors?.required"
                      class="text-danger fnt-size12px"> {{ "DOCTOR_REG_LAST_NAME_REQ" | translate }}</div>
                    <div
                      *ngIf="doctorForm.get('personalDetails.lastName').touched && doctorForm.get('personalDetails.lastName').errors?.pattern"
                      class="text-danger fnt-size12px"> {{ "DOCTOR_REG_LAST_NAME_ERR" | translate }}</div>
                    <div
                      *ngIf="doctorForm.get('personalDetails.lastName').touched && doctorForm.get('personalDetails.lastName').errors?.maxlength"
                      class="text-danger fnt-size12px"> {{ "DOCTOR_REG_LAST_NAME_MAXLENGTH" | translate }}</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <p class="font-weight-bold">{{"MOBILE_NUMBER" | translate}}<span class="text-danger">*</span></p>
                    <div class="d-flex">
                      <div class="w-100 position-relative coutryCodeDesign" *ngIf="this.defaultCountry">
                        <p-dropdown
                          class="d-flex align-items-center position-absolute h-100  pr-2 form-control disable-country"
                          [options]="[this.defaultCountry]" formControlName="countryCode" [optionValue]="'countryCode'"
                          [disabled]="'true'">
                          <ng-template pTemplate="selectedItem">
                            <div class="country-item-value" *ngIf="this.doctorForm.get('personalDetails.countryCode')">
                              <div class="fnt-size12px fnt_Medium text-rolling-stone-grey mr-1">
                                <img [src]="this.defaultCountry.logo" class="flag mr-1"
                                  onerror="this.src='assets/images/logo.svg'" alt="country flag" />
                                +{{this.doctorForm.get('personalDetails.countryCode').value}}
                              </div>
                            </div>
                          </ng-template>
                          <ng-template let-item pTemplate="item">
                            <div class="d-flex justify-content-between align-items-center p-2">
                              <div class="country-item-value d-flex">
                                <img [src]="item.logo" class="flag mr-2" onerror="this.src='assets/images/logo.svg'"
                                  alt="country flag" />
                                <div class="fnt-size12px text-abbey-grey mr-2">
                                  {{item.name}}
                                </div>
                                <div class="fnt-size12px text-abbey-grey">
                                  +{{item.countryCode}}
                                </div>
                              </div>
                              <img *ngIf="this.loginForm.get('countryCode').value === item.countryCode"
                                src="assets/images/checkbox.svg" alt="green checked checkbox">
                            </div>
                          </ng-template>
                        </p-dropdown>
                      </div>
                      <div class="w-100 position-relative">
                        <span *ngIf="this.isExistingUser"
                          (click)="displayEditCred = true; showEditFields(fieldType.PHONE)"
                          class="cursor-pointer position-absolute doctor_changeCredential text-success">Change</span>
                        <input formControlName="phoneNumber" type="text" (input)="phoneValidator($event,'personalDetails.phoneNumber')"
                          (paste)="onPaste($event,'phoneNumber')" class="form-control fnt-size14px"
                          placeholder="{{'ENTER_MOBILE_NUMBER' | translate }} " dngxDigitOnly />
                      </div>
                    </div>
                    <div
                      *ngIf="doctorForm.get('personalDetails.phoneNumber').touched && doctorForm.get('personalDetails.phoneNumber').invalid"
                      class="text-danger fnt-size12px"> {{ "DOCTOR_REG_PHONE_NUMBER_LIMIT" | translate }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <p class="font-weight-bold">{{"EMAIL_ID" | translate}}<span class="text-danger">*</span></p>
                    <div class="position-relative">
                      <span *ngIf="this.isExistingUser"
                        (click)="displayEditCred = true; showEditFields(fieldType.EMAIL)"
                        class="cursor-pointer position-absolute doctor_changeCredential text-success">Change</span>
                      <input formControlName="email" type="email" class="form-control fnt-size14px"
                        placeholder="{{'ENTER_EMAIL_ID' | translate}}" />
                    </div>

                    <div
                      *ngIf="doctorForm.get('personalDetails.email').touched && doctorForm.get('personalDetails.email').invalid"
                      class="text-danger fnt-size12px"> {{ "DOCTOR_REG_EMAIL_ERR" | translate }}</div>
                  </div>
                </div>
              </div>
              <div class="form-group" *ngIf="!checkForThailandCountry() && !checkForIsRegistrationInPh()">
                <p class="mb-1 font-weight-bold">Select Gender<span class="text-danger d-none" *ngIf="!checkForIsPhilippineCountry()">*</span></p>
                <div class="d-flex custom-checkbox">
                  <div class="pr-5">
                    <input value="male" id="genderMale" class="radio-green p-2 mr-2" formControlName="gender"
                      name="gender" type="radio" checked />
                    <label for="genderMale" class="radio-button-text cursor-pointer">Male</label>
                  </div>
                  <div class="pr-5">
                    <input value="female" id="genderFemale" class="radio-green p-2 mr-2" formControlName="gender"
                      name="gender" type="radio" />
                    <label for="genderFemale" class="radio-button-text cursor-pointer">Female</label>
                  </div>
                  <div>
                    <input value="other" id="genderOther" class="radio-green p-2 mr-2" formControlName="gender"
                      name="gender" type="radio" />
                    <label for="genderOther" class="radio-button-text cursor-pointer">Other</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">

              <div class="col-md-6 form-group" *ngIf="checkForThailandCountry()">
                <p class="font-weight-bold mb-1">{{"HOME_OFFICE_NUMBER" | translate}} ({{'OPTIONAL' | translate}})</p>
                <div class="d-flex">
                  <div class="w-100 position-relative coutryCodeDesign" *ngIf="this.defaultCountry">
                    <p-dropdown class="d-flex align-items-center position-absolute h-100  pr-2 form-control disable-country" [disabled]="true"
                      [options]="[this.defaultCountry]" [optionValue]="'countryCode'" formControlName="officeCountryCode" >
                      <ng-template pTemplate="selectedItem">
                        <div class="country-item-value">
                          <div class="fnt-size12px fnt_Medium text-rolling-stone-grey mr-1">
                            <img [src]="this.defaultCountry.logo" class="flag mr-1" onerror="this.src='assets/images/logo.svg'"
                              alt="country flag" />
                            +{{this.defaultCountry.countryCode}}
                          </div>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div class="d-flex justify-content-between align-items-center p-2">
                          <div class="country-item-value d-flex">
                            <img [src]="item.logo" class="flag mr-2" onerror="this.src='assets/images/logo.svg'"
                              alt="country flag" />
                            <div class="fnt-size12px text-abbey-grey mr-2">
                              {{item.name}}
                            </div>
                            <div class="fnt-size12px text-abbey-grey">
                              +{{item.countryCode}}
                            </div>
                          </div>
                          <img src="assets/images/checkbox.svg" alt="green checked checkbox">
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <div class="w-100">
                    <input type="text" formControlName="officePhoneNumber" (input)="phoneValidator($event,'officePhoneNumber')" (focus)="resetError()" (paste)="onPaste($event,'officePhoneNumber')"
                      maxlength="14" class="form-control fnt-size14px" placeholder="{{'ENTER_MOBILE_NUMBER' | translate }} "
                      dngxDigitOnly />
                    </div>
                  </div>
                  <div
                  *ngIf="doctorForm.get('officePhoneNumber').touched && doctorForm.get('officePhoneNumber').invalid"
                  class="text-danger fnt-size12px"> {{ "DOCTOR_REG_PHONE_NUMBER_LIMIT" | translate }}</div>
              </div>
              <div class="col-md-6" *ngIf="checkForThailandCountry()">
                <div class="form-group">
                  <p class="font-weight-bold">{{"REFERENCE_ID" | translate}} ({{'OPTIONAL' | translate}})</p>
                  <div class="position-relative">
                    <input formControlName="referenceId" type="email" class="form-control fnt-size14px"
                      placeholder="{{'ENTER_REFERENCE_ID' | translate}}" [maxlength]="20"/>
                  </div>
                  <div
                    *ngIf="doctorForm.get('referenceId').touched && doctorForm.get('referenceId').invalid"
                    class="text-danger fnt-size12px">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div *ngIf="!checkForThailandCountry() && !checkForIsRegistrationInPh()" class="col-md-6" formGroupName="educationDetails">
                <div class="form-group addDoctor__Dropdown">
                  <p class="font-weight-bold">{{"EDUCATIONAL_DEGREE" | translate}} <span class="text-danger d-none" *ngIf="!checkForIsPhilippineCountry()">*</span>
                  </p>
                  <input formControlName="id" hidden>
                  <p-dropdown (onChange)="onEducationChange($event)" [options]="doctorEducation" formControlName="name"
                    optionLabel="name" optionValue="name" panelStyleClass="bg-white border p-2 fnt-size14px"
                    styleClass="form-control fnt-size14px"
                    placeholder="{{'ENTER_EDUCATIONAL_DEGREE' | translate}}"></p-dropdown>
                  <div
                    *ngIf="doctorForm.get('educationDetails.name').touched && doctorForm.get('educationDetails.name').invalid && !checkForIsPhilippineCountry()"
                    class="text-danger fnt-size12px d-none">{{ "DOCTOR_REG_EDUCATION_ERR" | translate }}</div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="checkForThailandCountry()">
                <div class="form-group hospital-search">
                  <p class="font-weight-bold">{{"HOSPITAL_AFFILIATION" | translate}} <span class="text-danger">*</span></p>
                  <p-autoComplete class="w-100" (keyup)="userInput('hospitalIds', $event)" [dropdown]="true"
                    [inputStyleClass]="'form-control fnt-size14px w-100'" [suggestions]="filterHospitalAffiliationList" [multiple]="true"
                    [styleClass]="doctorForm.get('hospitalIds').touched && doctorForm.get('hospitalIds').invalid ? 'pcomponent-invalid w-100' : 'w-100'"
                    placeholder="{{'SELECT_HOSPITAL' | translate}}" [minLength]="1" (completeMethod)="filterCountry($event)" [autoHighlight]="true"
                    formControlName="hospitalIds" field="name" [completeOnFocus]="true">
                    <ng-template let-hospital pTemplate="item">
                      <div [innerHTML]="hospital.name | highlight: toHighlight"></div>
                    </ng-template>
                  </p-autoComplete>
                  <div *ngIf="doctorForm.get('hospitalIds').touched && doctorForm.get('hospitalIds').invalid"
                        class="text-danger fnt-size12px">
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="checkForIsRegistrationInPh()">
                <div class="form-group float-left w-100 mwebPrcView" formGroupName="registrationDetails">
                  <p class="font-weight-bold">{{"PRC_NUMBER" | translate}}</p>
                  <input formControlName="number" type="text" class="form-control fnt-size14px"
                    placeholder="{{'ENTER_PRC_NUMBER' | translate}}">
                  <div
                    *ngIf="doctorForm.get('registrationDetails.number').invalid && doctorForm.get('registrationDetails.number').touched"
                    class="text-danger fnt-size12px">{{ "DOCTOR_REG_PRC_ERR" | translate }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group speciality__Dropdown">
                  <p class="font-weight-bold">{{"SPECIALITY" | translate}}<span class="text-danger">*</span></p>
                  <div *ngIf="this.isExistingUser && this.doctorSpecialityPrograms?.length === 0; else programExist ">
                    <input type="text" class="form-control fnt-size14px"
                      value={{this.existingData?.specialities[0]?.name}} disabled>
                  </div>
                  <ng-template #programExist>
                    <p-dropdown (onChange)="onSpecialityChange($event)" [options]="doctorSpecialtyData"
                      formControlName="speciality" optionLabel="name" optionValue="speciality_unique_id"
                      panelStyleClass="bg-white border p-2 fnt-size14px" styleClass="form-control fnt-size14px"
                      require="true" placeholder="{{'SELECT_SPECIALITY' | translate}}">
                      <ng-template let-item pTemplate="item">
                        <span class="speciality-image mr-2">
                          <img src="{{item.image_url}}" alt="speciality-image">
                        </span>
                        <span class="ml-2">{{item.name}}</span>
                        <span class="float-right mt-2">
                          <img width="25" *ngIf="this.doctorForm.get('speciality').value === item.speciality_unique_id"
                            src="assets/images/checkbox.svg" alt="green checked checkbox">
                        </span>
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                </div>
              </div>
            </div>
            <div *ngIf="!checkForThailandCountry() && !checkForIsRegistrationInPh()">
              <div class="clearfix">
                <div class="form-group float-left w-50 mwebPrcView pr-0 pr-md-3" formGroupName="clinicDetails">
                  <p class="font-weight-bold">{{"HOSPITAL_AFFILIATION" | translate}}</p>
                  <input formControlName="name" type="text" class="form-control fnt-size14px"
                    placeholder="{{'ENTER_HOSPITAL_NAME' | translate}}" maxlength="50">
                  <div
                    *ngIf="doctorForm.get('clinicDetails.name').invalid && doctorForm.get('clinicDetails.name').touched"
                    class="text-danger fnt-size12px">{{ "DOCTOR_REG_AFFLIATION" | translate }}</div>
                </div>
                <div class="form-group float-right w-50 mwebPrcView pl-0 pl-md-3" formGroupName="clinicDetails">
                  <p class="font-weight-bold">{{"HOSPITAL_ADDRESS" | translate}}</p>
                  <textarea formControlName="address" type="text"
                    class="form-control fnt-size14px doctorRegistration__address"
                    placeholder="{{'ENTER_HOSPITAL_ADDRESS' | translate}}" maxlength="100"></textarea>
                  <div
                    *ngIf="doctorForm.get('clinicDetails.address').invalid && doctorForm.get('clinicDetails.address').touched"
                    class="text-danger fnt-size12px">{{ "DOCTOR_REG_ADDRESS" | translate }}</div>
                </div>
                <div class="form-group float-left w-50 mwebPrcView pr-0 pr-md-3" formGroupName="registrationDetails">
                  <p class="font-weight-bold">{{"PRC_NUMBER" | translate}}<span class="text-danger">*</span></p>
                  <input formControlName="number" type="text" class="form-control fnt-size14px"
                    placeholder="{{'ENTER_PRC_NUMBER' | translate}}">
                  <div
                    *ngIf="doctorForm.get('registrationDetails.number').invalid && doctorForm.get('registrationDetails.number').touched"
                    class="text-danger fnt-size12px">{{ "DOCTOR_REG_PRC_ERR" | translate }}</div>
                </div>
              </div>
            </div>
            <div class="row" >
              <div class="col-md-6" *ngIf="!checkForThailandCountry() && !checkForIsRegistrationInPh()">
                <div class="form-group" formGroupName="registrationDetails">
                  <p class="font-weight-bold">{{"PRC_ID" | translate}}<span class="text-danger d-none" *ngIf="!checkForIsPhilippineCountry()">*</span></p>
                  <div
                    class="border border-dashed rounded p-2 text-center imagePreview d-flex align-items-center justify-content-center">
                    <div>
                      <img *ngIf="!imageDocSrc" src="assets/images/file-upload.svg" alt="file-upload" class="mb-2">
                      <p *ngIf="!imageDocSrc" class="mb-1">{{"CLICK_UPLOAD" | translate }}</p>
                      <div *ngIf="imageDocSrc" class="position-relative mb-2">
                        <img src="assets/images/cross-icon.svg" class="position-absolute rt-0" alt="cross"
                          (click)="closeDocImage()">
                        <img [src]="imageDocSrc" alt="doc image" />
                      </div>
                      <label
                        class="border rounded border-success py-2 px-3 fnt-size14px text-success mb-0 cursor-pointer"
                        for="uploadPrc">Upload <span *ngIf="imageDocSrc">again</span></label>
                    </div>
                  </div>
                  <input #prcInput id="uploadPrc" (change)="onSelectDoc($event)" type="file"
                    class="form-control fnt-size14px d-none" placeholder="Please select prcId"
                    accept=".png, .jpeg, .jpg" >
                  <div *ngIf="docSizeInvalid" class="text-danger fnt-size12px">{{ "DOCUMENT_SIZE_ERR" | translate }}
                  </div>
                  <div *ngIf="docFormatInvalid" class="text-danger fnt-size12px">{{ "INVALID_FILE_TYPE" | translate }}
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="!checkForIsRegistrationInPh()">
                <div class="form-group" formGroupName="clinicDetails">
                  <p class="font-weight-bold">{{"DIGITAL_SIGN" | translate}}({{'OPTIONAL' | translate}}) <span class="text-danger  d-none" *ngIf="!checkForIsPhilippineCountry()">*</span></p>
                  <div
                    class="border border-dashed rounded p-2 text-center imagePreview d-flex align-items-center justify-content-center position-relative "
                    [ngStyle]="{ 'background-color': !imageSignatureSrc ? '#FAFAFA' : '#D1D1D1' }" >
                    <div>
                      <img *ngIf="!imageSignatureSrc" src="assets/images/file-upload.svg" alt="file-upload"
                        class="mb-2">
                      <p *ngIf="!imageSignatureSrc" class="mb-1">{{"CLICK_UPLOAD" | translate }}</p>
                      <div *ngIf="imageSignatureSrc" class="position-relative mb-2">
                        <img class="position-absolute rt-0" src="assets/images/cross-icon.svg" alt="cross"
                          (click)="closeSignatureImage()">
                        <img class="p-3" [src]="imageSignatureSrc" alt="doc image"/>
                      </div>
                      <label *ngIf="!imageSignatureSrc"
                        class="border rounded border-success py-2 px-3 fnt-size14px text-success mb-0 cursor-pointer"
                        for="signatureInput">Upload <span *ngIf="imageSignatureSrc">again</span></label>
                    </div>
                    <i *ngIf="imageSignatureSrc" class="pi pi-search-plus signature-img" (click)="this.toggleViewMode=!this.toggleViewMode"></i>
                    <dngx-model [displayModal]="toggleViewMode"  [dismissableMask]="false" [closable]="true" (hideModal)="this.toggleViewMode = !this.toggleViewMode ">
                        <img class="rounded m-4" [src]="imageSignatureSrc" alt="preview">
                         </dngx-model>
                  </div>
                  
                    <input #signatureInput id="signatureInput" (change)="onSelectSignature($event)" type="file"
                    class="form-control fnt-size14px d-none" placeholder="Please select signature"
                    accept=".png, .jpeg, .jpg">
                  <div *ngIf="signSizeInvalid" class="text-danger fnt-size12px">{{ "DOCUMENT_SIZE_ERR" | translate }}
                  </div>
                  <div *ngIf="signFormatInvalid" class="text-danger fnt-size12px">{{ "INVALID_FILE_TYPE" | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="checkForThailandCountry()" class="row" formGroupName="personalAssistant">
              <div class="col-12">
                <h4 class="text-brownish-grey">
                  {{'PERSONAL_ASSISTANT_DETAILS' | translate}}
                </h4>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <p class="font-weight-bold">{{"FIRST_NAME" | translate}} ({{'OPTIONAL' | translate}})</p>
                  <input formControlName="firstName" type="text" class="form-control fnt-size14px"
                    placeholder="{{ 'ENTER_FIRST_NAME' | translate }}" />
                  <div
                    *ngIf="doctorForm.get('personalAssistant.firstName').touched && doctorForm.get('personalAssistant.firstName').errors?.required"
                    class="text-danger fnt-size12px"> {{ "DOCTOR_REG_FIRST_NAME_REQ" | translate }}</div>
                  <div
                    *ngIf="doctorForm.get('personalAssistant.firstName').touched && doctorForm.get('personalAssistant.firstName').errors?.pattern"
                    class="text-danger fnt-size12px"> {{ "DOCTOR_REG_FIRST_NAME_ERR" | translate }}</div>
                  <div
                    *ngIf="doctorForm.get('personalAssistant.firstName').touched && doctorForm.get('personalAssistant.firstName').errors?.maxlength"
                    class="text-danger fnt-size12px"> {{ "DOCTOR_REG_FIRST_NAME_MAXLENGTH" | translate }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <p class="font-weight-bold">{{"LAST_NAME" | translate}} ({{'OPTIONAL' | translate}})</p>
                  <input formControlName="lastName" type="text"
                    class="form-control fnt-size14px" placeholder="{{'ENTER_LAST_NAME' | translate }}" />
                  <div
                    *ngIf="doctorForm.get('personalAssistant.lastName').touched && doctorForm.get('personalAssistant.lastName').errors?.required"
                    class="text-danger fnt-size12px"> {{ "DOCTOR_REG_LAST_NAME_REQ" | translate }}</div>
                  <div
                    *ngIf="doctorForm.get('personalAssistant.lastName').touched && doctorForm.get('personalAssistant.lastName').errors?.pattern"
                    class="text-danger fnt-size12px"> {{ "DOCTOR_REG_LAST_NAME_ERR" | translate }}</div>
                  <div
                    *ngIf="doctorForm.get('personalAssistant.lastName').touched && doctorForm.get('personalAssistant.lastName').errors?.maxlength"
                    class="text-danger fnt-size12px"> {{ "DOCTOR_REG_LAST_NAME_MAXLENGTH" | translate }}</div>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <p class="font-weight-bold mb-1">{{"MOBILE_NUMBER" | translate}} ({{'OPTIONAL' | translate}})</p>
                <div class="d-flex">
                  <div class="w-100 position-relative coutryCodeDesign" *ngIf="this.defaultCountry">
                    <p-dropdown class="d-flex align-items-center position-absolute h-100  pr-2 form-control disable-country"
                      [options]="[this.defaultCountry]" [optionValue]="'countryCode'" [disabled]="true">
                      <ng-template pTemplate="selectedItem">
                        <div class="country-item-value">
                          <div class="fnt-size12px fnt_Medium text-rolling-stone-grey mr-1">
                            <img [src]="this.defaultCountry.logo" class="flag mr-1" onerror="this.src='assets/images/logo.svg'"
                              alt="country flag" />
                            +{{this.defaultCountry.countryCode}}
                          </div>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div class="d-flex justify-content-between align-items-center p-2">
                          <div class="country-item-value d-flex">
                            <img [src]="item.logo" class="flag mr-2" onerror="this.src='assets/images/logo.svg'"
                              alt="country flag" />
                            <div class="fnt-size12px text-abbey-grey mr-2">
                              {{item.name}}
                            </div>
                            <div class="fnt-size12px text-abbey-grey">
                              +{{item.countryCode}}
                            </div>
                          </div>
                          <img src="assets/images/checkbox.svg" alt="green checked checkbox">
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <div class="w-100">
                    <input type="text" formControlName="phoneNumber" (input)="phoneValidator($event,'personalAssistant.phoneNumber')" (focus)="resetError()" (paste)="onPaste($event,'phoneNumber')"
                      maxlength="14" class="form-control fnt-size14px" placeholder="{{'ENTER_MOBILE_NUMBER' | translate }} "
                      dngxDigitOnly />
                  </div>
                </div>
                <div
                  *ngIf="doctorForm.get('personalAssistant.phoneNumber').touched && doctorForm.get('personalAssistant.phoneNumber').invalid"
                  class="text-danger fnt-size12px"> {{ "DOCTOR_REG_PHONE_NUMBER_LIMIT" | translate }}</div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <p class="font-weight-bold">{{"EMAIL_ID" | translate}} ({{'OPTIONAL' | translate}})</p>
                  <div class="position-relative">
                    <input formControlName="email" type="email" class="form-control fnt-size14px"
                      placeholder="{{'ENTER_EMAIL_ID' | translate}}" />
                  </div>

                  <div
                    *ngIf="doctorForm.get('personalAssistant.email').touched && doctorForm.get('personalAssistant.email').invalid"
                    class="text-danger fnt-size12px"> {{ "DOCTOR_REG_EMAIL_ERR" | translate }}</div>
                </div>
              </div>
            </div>

            <div class="form-group addDoctor__Dropdown">
              <h4 *ngIf="checkForThailandCountry()">{{'PROGRAM_DETAILS' | translate}}</h4>
              <p class="mb-1 font-weight-bold">{{"SELECT_PAP" | translate}} <span class="text-danger">*</span>
              </p>
              <div class="px-2 py-1" style="background: #fbfbfb;" *ngIf="this.papView">
                <div class="pl-4">
                  <div class="row" *ngIf="this.papView">
                    <div class="col-md-4 pb-2 custom-checkbox"
                      *ngFor="let papPrograms of doctorSpecialityPrograms; let i = index">
                      <input id="selectPap{{papPrograms.Program.Id}}" value="{{papPrograms.Program.Id}}" type="checkbox"
                        (change)="onCheckChange($event)" formArrayName="papIds" name="papIds"
                        [checked]="this.userId && disbalePap(papPrograms.Program.Id)"
                        [disabled]="this.userId && disbalePap(papPrograms.Program.Id)"
                        class="custom-control-input doctor-consent" required />
                      <label for="selectPap{{papPrograms.Program.Id}}"
                        class="fnt-size14px custom-control-label cursor-pointer pt-1">
                        {{papPrograms.Program.Name}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" class="empty-pap text-center pb-2"
                *ngIf="!this.papView || this.doctorSpecialityPrograms?.length === 0">
                <img src="assets/images/no-pap.png" class="my-3" alt="empty">
                <p>{{"EMPTY_PAP" | translate}}</p>
              </div>
            </div>

            <div class="form-group" *ngIf="!this.isExistingUser && !DCPMSupervisor">
              <div class="termsList text-break">
                <p class="fnt-size14px mb-3 font-weight-bold">{{"DOCTOR_REG_CONFIM" | translate}}
                </p>
                <div *ngIf="(doctorConsent && pharmaCode) else nonPharma">
                  <div class="terms p-2 text-break">
                    <div [innerHTML]="doctorConsent" class="pharma-terms">
                    </div>
                  </div>
                </div>
                <ng-template #nonPharma>
                  <ul class="fnt-size12px pl-3">
                    <li>
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_ONE" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_TWO" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_THREE" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_FOUR" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_FIVE" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_SIX" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">I have read and understood the <i><u><a target="_blank"
                              href="{{novartisPrivacy}}">{{"NOVARTIS_PRIVACY" |
                              translate}}</a></u></i>{{"NOVARTIS_PRIVACY_NOTICE" | translate}}</span>
                    </li>
                  </ul>
                </ng-template>
              </div>
            </div>
            <div class=" form-group text-center">
              <button class="btn btn-success fnt-size14px px-5 py-2" (click)="this.submitForm(); this.resetError()"
                [disabled]="!doctorForm.valid || (this.doctorForm.get('papIds').value?.length === 0 && this.existingData?.enrolledPapDetails?.length === 0)">
                <span class="px-5" *ngIf="!this.isExistingUser">{{"ACCEPT_SUBMIT" | translate}}</span>
                <span class="px-5" *ngIf="this.isExistingUser">{{"UPDATE_PROFILE" | translate}}</span>
              </button>
            </div>

          </div>
          <div class="border rounded bg-white p-3 mt-3" *ngIf="!this.isExistingUser && !findDoctor">
            <div class="form-group mt-2">
              <div class="termsList">
                <p class="fnt-size14px mb-3 font-weight-bold">{{"DISCLAIMER" | translate}}
                </p>
                <ul class="fnt-size12px pl-3">
                  <li>
                    <span class="elementor-icon-list-icon">
                      <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                    <span class="elementor-icon-list-text">{{"DISCLAIMER_TERM_ONE" | translate}}</span>
                  </li>
                  <li class="">
                    <span class="elementor-icon-list-icon">
                      <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                    <span class="elementor-icon-list-text">{{"DISCLAIMER_TERM_TWO" | translate}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="border rounded bg-white p-3 mt-3" *ngIf="this.isExistingUser && !findDoctor">
            <div class="form-group">
              <div class="termsList">
                <p class="fnt-size14px mb-3 font-weight-bold">{{"DOCTOR_REG_CONFIM" | translate}}
                </p>
                <div *ngIf="pharmaDetail; else nonPharmaCond">
                  <div class="terms text-break">
                    <div [innerHTML]="doctorConsent" class="pharma-terms">
                    </div>
                  </div>
                </div>
                <ng-template #nonPharmaCond>
                  <ul class="fnt-size12px pl-3">
                    <li>
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_ONE" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_TWO" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_THREE" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_FOUR" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_FIVE" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_SIX" | translate}}</span>
                    </li>
                  </ul>
                </ng-template>
              </div>
            </div>
            <div class="form-group mt-2">
              <div class="termsList">
                <p class="fnt-size14px mb-3 font-weight-bold">{{"DISCLAIMER" | translate}}
                </p>
                <ul class="fnt-size12px pl-3">
                  <li>
                    <span class="elementor-icon-list-icon">
                      <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                    <span class="elementor-icon-list-text">{{"DISCLAIMER_TERM_ONE" | translate}}</span>
                  </li>
                  <li class="">
                    <span class="elementor-icon-list-icon">
                      <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                    <span class="elementor-icon-list-text">{{"DISCLAIMER_TERM_TWO" | translate}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf=" this.isDoctorCreated !== undefined && !this.isDoctorCreated"
        class="d-flex justify-content-center align-items-center alert alert-danger">
        {{"DOCTOR_CREATION_FAIL" | translate}}
      </div>
      <div *ngIf="this.isDoctorCreated" class="d-flex justify-content-center align-items-center alert alert-success">
        {{"DOCTOR_CREATION_SUCCESS" | translate}}
      </div>
    </div>
  </div>
</div>
<div class="modalDisplay">
  <p-dialog [(visible)]="displayCreate" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [closeOnEscape]="false" [closable]="false">
    <ng-template pTemplate="header">
      <img src="assets/images/success.svg" class="mx-auto" alt="success">
    </ng-template>
    <div class="px-5">
      <p class="my-2 text-center font-weight-bold">{{"PROFILE_SUCCESSFULL_CREATED" | translate}}</p>
      <p *ngIf="!DCPMSupervisor" class="text-center p-2 text-abbey-grey">{{"PROFILE_CREAT_MSG" | translate}}</p>
      <div class="text-center mb-3 mt-3">
        <button class="btn btn-outline-success fnt-size14px px-5 py-2" (click)="toView()">
          Okay
        </button>
      </div>
    </div>
  </p-dialog>
  <div class="successful-edit">
    <p-dialog [(visible)]="displayEdit" [modal]="true" [baseZIndex]="10000"
      [draggable]="false" [resizable]="false" [closeOnEscape]="false" styleClass="successful-edit">
      <ng-template pTemplate="header">
        <img src="assets/images/success.svg" class="mx-auto mt-5" alt="success">
      </ng-template>
      <p class="mb-5 px-5  font-weight-bold text-center">{{"PROFILE_UPDATE" | translate}}</p>
      <div class="text-center mb-3 mt-3">
        <button *ngIf="findDoctor" class="btn btn-outline-success fnt-size14px px-5 py-2" (click)="redirectToFindDoctor()">
          Okay
        </button>
      </div>
    </p-dialog>
  </div>
</div>
<!-- edit credential -->
<p-dialog *ngIf="displayEditCred" [(visible)]="displayEditCred" [showHeader]="false" [modal]="true" [appendTo]="'body'"
  [baseZIndex]="'1021'" [blockScroll]="true" [closeOnEscape]="false" styleClass="successful-edit mx-3"
  contentStyleClass="rounded">
  <div class="border-bottom px-4 py-3">
    <p class="fnt-size20px medium-font-weight">
      <span *ngIf="fieldTypeValue === fieldType.PHONE else emailId">
        {{'UPDATE_DOCTOR_CRED1' | translate}}
      </span>
      <ng-template #emailId>{{'UPDATE_DOCTOR_CRED2' | translate}}</ng-template>
    </p>
  </div>
  <div class="p-4" [formGroup]="loginForm">
    <div class="form-group" *ngIf="fieldTypeValue === fieldType.PHONE">
      <p class="font-weight-bold mb-1">{{"MOBILE_NUMBER" | translate}}<span class="text-danger">*</span></p>
      <div class="d-flex">
        <div class="w-100 position-relative coutryCodeDesign" *ngIf="this.defaultCountry">
          <p-dropdown class="d-flex align-items-center position-absolute h-100  pr-2 form-control disable-country"
            [options]="[this.defaultCountry]" [optionValue]="'countryCode'" [disabled]="'true'">
            <ng-template pTemplate="selectedItem">
              <div class="country-item-value">
                <div class="fnt-size12px fnt_Medium text-rolling-stone-grey mr-1">
                  <img [src]="this.defaultCountry.logo" class="flag mr-1" onerror="this.src='assets/images/logo.svg'"
                    alt="country flag" />
                  +{{this.defaultCountry.countryCode}}
                </div>
              </div>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div class="d-flex justify-content-between align-items-center p-2">
                <div class="country-item-value d-flex">
                  <img [src]="item.logo" class="flag mr-2" onerror="this.src='assets/images/logo.svg'"
                    alt="country flag" />
                  <div class="fnt-size12px text-abbey-grey mr-2">
                    {{item.name}}
                  </div>
                  <div class="fnt-size12px text-abbey-grey">
                    +{{item.countryCode}}
                  </div>
                </div>
                <img src="assets/images/checkbox.svg" alt="green checked checkbox">
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="w-100">
          <input type="text" formControlName="phone" (focus)="resetError()" (paste)="onPaste($event,'phoneNumber')"
            maxlength="14" class="form-control fnt-size14px" placeholder="{{'ENTER_MOBILE_NUMBER' | translate }} "
            dngxDigitOnly />
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="fieldTypeValue === fieldType.EMAIL">
      <p class="font-weight-bold mb-1">{{"EMAIL_ID" | translate}}<span class="text-danger">*</span></p>
      <input type="email" (focus)="resetError()" formControlName="email" class="form-control fnt-size14px"
        placeholder="{{'ENTER_EMAIL_ID' | translate}}" />
    </div>
    <div class="form-group text-danger fnt-size12px">
      <!-- email error -->
      <div *ngIf="((this.loginForm.get('email').touched || this.loginForm.get('email').errors?.pattern)
            &&
            this.loginForm.get('email').invalid) && !error">
        <div *ngIf="this.loginForm.get('email').invalid">{{ "DOCTOR_REG_EMAIL_ERR" | translate }}</div>
      </div>
      <!-- phone error -->
      <div *ngIf="this.loginForm.get('phone').invalid && !error">

        <span
          *ngIf="(this.loginForm.get('phone').errors?.maxlength || this.loginForm.get('phone').errors?.minlength) && !this.loginForm.get('phone').errors?.pattern">
          {{ "MOBILE_NUMBER_MUST_BE_BETWEEN" | translate }}
        </span>

        <span
          *ngIf="this.loginForm.get('phone').errors?.pattern || !(this.loginForm.get('phone').errors?.maxlength || this.loginForm.get('phone').errors?.minlength)">
          {{'PLEASE_ENTER_VALID_MOBILE_NUMBER' | translate}}
        </span>

      </div>

      <div *ngIf="error">
        {{error.messages[0]}}
      </div>
    </div>
    <div class="text-right">
      <button class="btn btn-default fnt-size14px px-4 border py-2 mr-3 border-737171 text-737171"
        (click)="resetEditCredData()">{{'CANCEL' | translate}}</button>
      <button class="btn btn-success fnt-size14px px-4 py-2 border-0" (click)="requestEditCredPopup(); resetError()"
        [disabled]="checkInvalid() || this.loginForm.invalid || this.disableSendOtp">
        {{'SENT_OTP' | translate}}
      </button>
    </div>
  </div>
</p-dialog>
<!-- show popup verify edit cred otp -->
<p-dialog *ngIf="displayEditCredOtp" [(visible)]="displayEditCredOtp" [showHeader]="false" [modal]="true"
  [appendTo]="'body'" [blockScroll]="true" [baseZIndex]="'1021'" [closeOnEscape]="false"
  styleClass="successful-edit mx-3" contentStyleClass="rounded">
  <div class="border-bottom px-4 py-3">
    <p class="fnt-size20px medium-font-weight d-flex">
      <span
        (click)="displayEditCredOtp = false; displayEditCred = true; this.loginForm.reset(); this.otpEnteredValue = ''; this.resetError()"
        class="cursor-pointer d-flex mr-1">
        <img src="assets/images/back-icon.svg" alt="back icon">
      </span>
      {{'OTP' | translate}}
    </p>
  </div>
  <div class="p-4">
    <div class="d-flex mb-3">
      <p class="font-weight-bold">{{'DOCTOR_SENT_OTP_TEXT' | translate}} {{editCredValue}}</p>
      <div class="ml-auto">
        <span class="text-success cursor-pointer d-flex"
          (click)="displayEditCredOtp = false; displayEditCred = true; this.otpEnteredValue = ''; this.resetError()">
          <img src="assets/images/green-pencil.svg" alt="edit" class="mr-1" /> {{'EDIT' | translate}}
        </span>
      </div>
    </div>
    <pap-otp-submit (otpResendData)="otpResendData($event)" (otpData)="receiveOtpData($event)"></pap-otp-submit>
    <div *ngIf="error" class="text-danger fnt-size12px form-group">
      {{error.messages[0]}}
    </div>
    <div class="text-right mt-4">
      <button class="btn btn-default fnt-size14px px-4 py-2 mr-3 border-737171 text-737171"
        (click)="resetEditCredData()">{{'CANCEL' | translate}}</button>
      <button [disabled]="disabledVerifyOtp || !this.otpEnteredValue"
        class="btn btn-success fnt-size14px px-4 py-2 border-0"
        (click)="verifyEnteredOtp(); this.resetError()">{{'VERIFY' | translate}}</button>
    </div>
  </div>
</p-dialog>
<!-- used to show the success popup after verify -->
<p-dialog *ngIf="displayEditCredSuccess" [(visible)]="displayEditCredSuccess" [showHeader]="false" [modal]="true"
  [baseZIndex]="'1021'" [appendTo]="'body'" [blockScroll]="true" [closeOnEscape]="false"
  styleClass="successful-edit mx-3" contentStyleClass="rounded">
  <div class="p-4 text-center">
    <img src="assets/images/success.svg" alt="success" class="mb-4" />

    <p class="font-weight-bold mb-4">
      <span *ngIf="fieldTypeValue === fieldType.EMAIL else successPopupPhone">
        {{'DOCTOR_EMAIL_UPDATED' | translate}}
      </span>
      <ng-template #successPopupPhone>
        {{'DOCTOR_PHONE_UPDATED' | translate}}
      </ng-template>
    </p>

    <button class="btn btn-default px-4 border-737171 text-737171" (click)="reloadPage()">{{'OKAY' |
      translate}}</button>
  </div>
</p-dialog>
