
/**
 * angular imports
 */
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
/**
 * pirime ng imports
 */
import { PrimeNGConfig } from 'primeng/api';

/**
 * component imports
 */
import { HybridComponent } from '../hybrid/hybrid.component';
/**
 * rxjs imports
 */
import { Subject, Subscription } from 'rxjs';

/**
 * Ngrx Imports.
 */
import { Store } from '@ngrx/store';
import * as PharmaActions from '../../../store/actions/pharma/pharma.actions';
import * as PharmaSelector from '../../../store/selectors/pharma/pharma.selectors';
import * as FileAction from '../../../store/actions/file/file.action';
import * as FileSelector from '../../../store/selectors/file/file.selectors';

/**
 * constant imports
 */
import {
  CONTACT_US,
  LOCAL_STORAGE_COUNTRY,
  COUNTRIES_LIST, LANGUAGES,
  PATTERN, IMAGE_TYPES,
  MIME_TYPES,
  APPROVER_CONSTANTS,
  UN_EMBEDDED_PHARMA
} from '../../../constant/app.constant';
/**
 * model imports
 */
import { PharmaDetail, PapDetail, Resources } from '../../../models/responses.model';
/**
 * device detector import.
 */
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * component to pharma detail
 */
@Component({
  selector: 'pap-pharma-view',
  templateUrl: './pharma-view.component.html',
  styleUrls: ['./pharma-view.component.scss']
})

export class PharmaViewComponent implements OnInit, OnDestroy {
  /**
   * html element for selected program div.
   */
  @ViewChild(HybridComponent) HybridPage: HybridComponent;

  /**
   * html element for terms and condtion and programs
   */
  @ViewChild('program') program: ElementRef;
  /**
   * html element for terms and condtion and programs
   */
  @ViewChild('resourcesDiv') resourcesDiv: ElementRef;

  @ViewChild('terms') terms: ElementRef;

  /**
   * variable to store pharma code
   */
  public pharmaCode: string;
  /**
   * variable to store drug names
   */
  public display = false;
  /**
   * variable to show popup
   */
  public showPopUp = false;
  /**
   * variable to show chatbot
   */
  public showChatBot = false;
  /**
   * to store window width
   */
  private windowWidth: number;
  /**
   * to display chatbot
   */
  public displayChatbot: boolean;
  /**
   * variable to store pharma detail
   */
  public pharma: PharmaDetail;
  /**
   * variable to store current route
   */
  private currentRoute: string;
  /**
   * variable to store pharma pap program
   */
  public papProgram: PapDetail[] = null;
  /**
   * subscription for pharma data
   */
  public pharmaSubs: Subscription;
  /**
   * subscription for invalid failure
   */
  public invalidFailure: Subscription;
  /**
   * public pharma code subscription from query params
   */
  public pharmaQueryParam = new Subscription();
  public isViewStores: boolean;
  /**
   * used to hold the mobile detection.
   */
  public isMobile: boolean;
  /**
   * var for read more limit.
   */
  public readMoreLimit = 600;
  /**
   * used to hold the contact us constants.
   */
  public contactUs = CONTACT_US;

  /**
   * Get support language.
   */
  public languages = LANGUAGES;

  private countryRouteParams: string;
  private PharmaNameRouteParams: string;

  public responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  resources = {};
  filePresignedUrlSub: Subscription;

  /**
   * function to show navbar
   */
  public displaynav(): void {
    this.display = !this.display;
  }

  /**
   * used to reset the chatbot session on reload on back from browser.
   */
  @HostListener('window:beforeunload') resetSession(): void {
    this.resetSessionOnReload();
  }
  /**
   * function to scroll at a div and close side bar in mweb
   */
  scroll(el: HTMLElement, close: boolean): void {
    if (close === true) {
      this.display = false;
    }
    el.scrollIntoView({ behavior: 'smooth' });
  }
  /**
   * function to show chatbot panel
   */
  showChatBotPanel(): void {
    this.showChatBot = true;
  }
  /**
   * function to show chatbot panel
   */
  resetChatbotSession(): void {
    localStorage.removeItem('patientAuth');
    this.showPopUp = false;
    this.displayChatbot = false;
    this.showChatBot = false;
    this.router.navigate([this.currentRoute], {
      queryParams: {
        code: this.pharmaCode
      },
    });
  }
  /**
   * function to close chatbot
   */
  showpopmessage(): void {
    if (this.showChatBot === false) {
      this.displayChatbot = false;
      this.router.navigate([this.currentRoute], {
        queryParams: {
          code: this.pharmaCode
        },
      });
      return;
    }
    this.showPopUp = true;
  }
  /**
   * start chatbot session
   */
  public startSession(): void {
    this.displayChatbot = true;
    if (this.windowWidth <= 767.98 || this.isMobile) {
      this.router.navigate([this.currentRoute], {
        queryParams: {
          code: this.pharmaCode,
          view: 'mWeb'
        },
      });
    }
    this.displayChatbot = true;
  }
  /**
   * redirect to doctor login
   */
  redirectDoctor(path): void {
    if (path === 'resource') {
      sessionStorage.setItem('resource', 'true');
    } else {
      if (sessionStorage.getItem('resource')) {
        sessionStorage.removeItem('resource');
      }
    }
    let redirectUrl = '';
    if (this.countryRouteParams) {
      redirectUrl = '/doctor/login/' + this.countryRouteParams;
    } else {
      redirectUrl = '/doctor/login';
    }
    this.router.navigate([redirectUrl], {
      queryParams: {
        pharmaCode: this.pharmaCode
      },
    });
  }

  navigateToPharmaStore(papCode: string): void {
    this.router.navigate([], {
      queryParams: {
        code: this.pharmaCode
      },
    }).then(result => {
      if (this.countryRouteParams) {
        window.open('/pharma/' + this.pharmaCode + '/pharma-store/' + this.countryRouteParams + '?papCode=' + papCode, '_blank');
      } else {
        window.open('/pharma/' + this.pharmaCode + '/pharma-store?papCode=' + papCode, '_blank');
      }
    });
  }
  signUpPatient(): void {
    this.display = false;
    this.startSession();
  }
  /**
   * function for handling read more.
   */
  readMore(index: number, isReadMore: boolean): void {
    this.papProgram[index].isReadMore = isReadMore;
  }
  constructor(
    private primengConfig: PrimeNGConfig,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService
  ) {
    this.deviceDetection();
    window.addEventListener('beforeunload', this.resetSessionOnReload, false);
  }
  /**
   * used to detect the device info.
   */
  private deviceDetection(): void {
    this.isMobile = this.deviceService.isMobile();
  }

  private resetSessionOnReload(): void {
    localStorage.removeItem('patientAuth');
    this.showPopUp = false;
    this.displayChatbot = false;
    this.showChatBot = false;
  }
  ngOnInit(): void {
    this.store.dispatch(FileAction.resetFilePresignedUrlWithIndex());
    localStorage.removeItem('pharmaUrl');
    if (window.innerWidth < 767) {
      this.readMoreLimit = 400;
    }
    this.windowWidth = innerWidth;
    this.currentRoute = this.router.url.split('?')[0];
    localStorage.setItem('pharmaUrl', this.currentRoute);
    this.primengConfig.ripple = true;
    /**
     * subscribe route params.
     */
    this.pharmaQueryParam.add(this.route.params.subscribe(data => {
      if (data.pharmaCode) {
        this.PharmaNameRouteParams = data.pharmaCode;
      }
      if (data.country) {
        if (data.country === COUNTRIES_LIST.PHILLIPPINS.value || data.country === COUNTRIES_LIST.THAILAND.value
        ) {
          this.countryRouteParams = data.country;
          localStorage.setItem('country', data.country);
        } else {
          localStorage.removeItem('country');
          this.router.navigate(['404']);
        }
      } else {
        localStorage.setItem('country', 'PH');
      }
    }));

    this.pharmaQueryParam.add(this.route.queryParams
      .subscribe(params => {
        this.pharmaCode = params.code;
        localStorage.setItem('pharmaCode', this.pharmaCode);
        if (params.view === undefined) {
          this.displayChatbot = false;
        }
        else {
          this.displayChatbot = true;
        }
      }
      ));
    this.store.dispatch(PharmaActions.loadPharmas({ pharmaCode: this.pharmaCode }));
    this.pharmaSubs = this.store.select(PharmaSelector.getPharmaDetail).subscribe(data => {
      if (data != null) {
        this.pharma = data;
        this.papProgram = JSON.parse(JSON.stringify(data.pap));
        if (this.pharma?.process === UN_EMBEDDED_PHARMA) {
          sessionStorage.setItem('process', this.pharma?.process);
        } else {
          if (sessionStorage.getItem('process')) {
            sessionStorage.removeItem('process');
          }
        }
        this.getImages(this.pharma.resources);
        if (this.pharma.termsAndConditions === '') {
          this.terms.nativeElement.classList.add('d-none');
        }
        if (this.pharma.pap.length === 0) {
          this.program.nativeElement.classList.add('d-none');
        }
      }
    });
    this.invalidFailure = this.store.select(PharmaSelector.pharmaError).subscribe(data => {
      if (data != null) {
        this.router.navigate(['404']);
      }
    });

    this.filePresignedUrlSub = this.store.select(FileSelector.getFilePresignedUrlWithIndex)
      .subscribe(file => {
        if (file?.url && file?.index !== null && file?.index !== undefined) {
          this.resources[file?.index] = ({
            id: file.index,
            source: file?.url, alt: 'sd',
            type: this.resources[file?.index]?.type
          });
        }
      });
  }

  getImages(resources: Resources[]): void {
    for (const resource of resources) {
      this.getFileTypes(resource.fileName, resource.thumbnailName);
      this.getPresignedUrl(resource.thumbnailName, resource.thumbnailName);
    }
  }

  getFileTypes(fileName: string, index): void {
    const fileExtension =
      fileName.split('.').pop().split(PATTERN.URL_SUFFIX)[0];
    let contentType = '';
    switch (fileExtension.toLowerCase()) {
      case IMAGE_TYPES.jpeg:
        contentType = 'image';
        break;
      case IMAGE_TYPES.jpg:
        contentType = 'image';
        break;
      case IMAGE_TYPES.png:
        contentType = 'image';
        break;
      case IMAGE_TYPES.pdf:
        contentType = 'pdf';
        break;
      case IMAGE_TYPES.mp4:
        contentType = 'video';
        break;
      case IMAGE_TYPES.mov:
        contentType = 'video';
        break;
    }
    this.resources[index] = ({ id: null, source: null, alt: 'sd', type: contentType });
  }

  getPresignedUrl(fileName: string, index): void {
    const fileExtension =
      fileName.split('.').pop().split(PATTERN.URL_SUFFIX)[0];
    const prefix = 'patient/document/';
    let contentType = '';
    switch (fileExtension.toLowerCase()) {
      case IMAGE_TYPES.jpeg:
        contentType = MIME_TYPES.JPEG;
        break;
      case IMAGE_TYPES.jpg:
        contentType = MIME_TYPES.JPEG;
        break;
      case IMAGE_TYPES.png:
        contentType = MIME_TYPES.PNG;
        break;
      case IMAGE_TYPES.pdf:
        contentType = MIME_TYPES.PDF;
        break;
    }
    const time = new Date().getMilliseconds();
    if (fileName) {
      this.store.dispatch(FileAction.loadFilePresignedUrlWithIndex({
        endPoint: APPROVER_CONSTANTS.FILE_SERVICE_URL,
        fileType: 'null',
        fileName: 'hcpResource/thumbnail/' + fileName.split('.')[0],
        contentType,
        module: 'na',
        fileMethod: 'false',
        country: localStorage.getItem('country') === 'PH' ? 'philippines' : 'thailand',
        time,
        index
      }));
    }
  }

  /**
   * used to redirect privacy page.
   */
  redirectToPharmaPrivacy(): void {

    let redirectUrl: string;
    if (this.countryRouteParams) {
      redirectUrl = 'pharma/' +
        this.PharmaNameRouteParams + '/policy/' +
        this.countryRouteParams + '?code=' +
        this.pharmaCode;
    } else {
      redirectUrl = localStorage.getItem('pharmaUrl') + '/policy' + '?code=' + this.pharmaCode;
    }
    this.router.navigate([], {
      queryParams: {
        code: this.pharmaCode
      },
    }).then(result => { window.open(redirectUrl, '_blank'); });
  }
  /**
   * used to unsubscribe the variables.
   */
  ngOnDestroy(): void {
    if (this.pharmaSubs) {
      this.pharmaSubs.unsubscribe();
    }
    if (this.invalidFailure) {
      this.invalidFailure.unsubscribe();
    }
    if (this.pharmaQueryParam) {
      this.pharmaQueryParam.unsubscribe();
    }
  }
}
