/**
 * Angular imports.
 */
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

/**
 * Rxjs import.
 */
import { Subscription } from 'rxjs';

/**
 * Ngrx store imports.
 */
import { Store } from '@ngrx/store';
import * as SignupAction from '../../../../store/actions/signup/signup.actions';
import * as FileAction from '../../../../store/actions/file/file.action';
import * as SignupSelector from '../../../../store/selectors/signup/signup.selectors';
import * as FileSelector from '../../../../store/selectors/file/file.selectors';
import * as HybridAction from '../../../../store/actions/hybrid/hybrid.actions';
import * as HybridSelector from '../../../../store/selectors/hybrid/hybrid.selectors';
import * as HomeAction from '../../../../featureModules/dashboard/featureModules/home/store/actions/home.actions';
import * as HomeSelector from '../../../../featureModules/dashboard/featureModules/home/store/selectors/home.selectors';

/**
 * Model imports.
 */
import { Message, Country, Region, City, ProgramData, Image, Program, CareGiverDeleteRequest, Hospital } from '../../../../models/sign-up-chatbot.model';
import { Signin } from './../../../../models/signin.model';

/**
 * Jwt import.
 */
import { JwtHelperService } from '@auth0/angular-jwt';

/**
 * Validator imports.
 */
import { ImageValidator } from '../../../../../../../ngp-docquity-ds/src/lib/validators/image.validators';

/**
 * Constants import.
 */
import {
  CHATBOT_CONSTANTS, FIELD_TYPE, MIME_TYPES, IMAGE_TYPES, FIELD_NAME,
  PATTERN, LOCAL_STORAGE_COUNTRY, HYBRID_STATES, APPLICATION_TYPE,
  MAX_FILE_SIZE, COUNTRIES_LIST, FILE_MODULE, ERROR_CODE,
  CHATBOT_PROGRAM_FETCH_LIMIT, FOLDER_CONSTANTS, UN_EMBEDDED_PHARMA, OAZIS_FLOW_PROCESS,
  TH_SERVIER_PROCESS, DEFAULT_TIMER
} from '../../../../constant/app.constant';
import { FileService } from 'projects/nga-PAP/src/app/file.service';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from 'projects/nga-PAP/src/app/helper.service';
import { PharmaNameService } from 'projects/nga-PAP/src/app/services/pharma/pharma-name.service';

/**
 * This component is used to dispaly form.
 */
@Component({
  selector: 'pap-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss']
})
export class PatientFormComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * chat bot view on pharma flag
   */
  @Input() chatView = false;
  /**
   * pharma code input
   */
  @Input() pharmaCode: string;
  /**
   * Storing patient country code.
   */
  @Input() patientCountryCode: number;
  /**
   * Storing patient mobile number;
   */
  @Input() patientPhoneNo: number;
  /**
   * Storing patient email.
   */
  @Input() patientEmail: string;
  /**
   * This is used to hold countries data.
   */
  @Input() countries: Country[];
  @Input() hospitalOptions: Hospital[];
  /**
   * Application type.
   */
  @Input() application: string;

  /**
   * Variable for storing response.
   */
  @Input() responseData: Message;

  /**
   * Docquity logo.
   */
  @Input() logo: string;

  /**
   * Current Chat block.
   */
  @Input() currentChatBlock: number;

  /**
   * Reapplication flag.
   */
  @Input() reapplication: boolean;

  /**
   * Reapplication count.
   */
  @Input() reapplicationCount: string;

  /**
   * Token of patient.
   */
  @Input() token: string;

  /**
   * program name for pre fetched program.
   */
  @Input() programName: string;

  /**
   * Submit patient information.
   */
  @Output() submitPatientData = new EventEmitter<{
    formData: any, nextIndex: number, chatBlockIndex: number, formType: string,
    consentToken: string, accessToken: string, jwtTokenExpire?: number
  }>();
  /**
   * Submit patient information-incase of conflicted entries.
   */
  @Output() submitError = new EventEmitter<{ formData: any }>();

  /**
   * Edit patient information.
   */
  @Output() editPatientData = new EventEmitter<{ formData: any, nextIndex: number, chatBlockIndex: number }>();
  @Output() deleteCaregiverData = new EventEmitter<{
    formData: CareGiverDeleteRequest,
    nextIndex: number, chatBlockIndex: number, careGiverName: string
  }>();
  @Output() goBackToEdit = new EventEmitter<{ nextIndex: number, chatBlockIndex: number }>();
  @Output() deleteEpapCaregiverData = new EventEmitter<{
    formData: CareGiverDeleteRequest,
    nextIndex: number, chatBlockIndex: number, careGiverName: string
  }>();
  /**
   * hybrid Form type
   */
  public hybridForm = APPLICATION_TYPE.HYBRID;

  /**
   * Storing consent token.
   */
  public consentToken: string;

  /**
   * Storing Jwt token.
   */
  public idToken: Signin;

  /**
   *  Patient information form.
   */
  public form: FormGroup;

  /**
   * Image preview source.
   */
  public imageSrc = '';

  /**
   * Time for user messages.
   */
  public docquityTime: Date;

  /**
   * Variable for storing regions.
   */
  public regions: Region[];

  /**
   * Variable for storing accepted file types.
   */
  public fileTypesDoc: string[];

  /**
   * Variable for storing cities.
   */
  public cities: City[];

  /**
   * Variable for holding pap programs.
   */
  public programs: ProgramData[];
  public userHospitalList: any[];
  /**
   * Used for storing prescription urls.
   */
  public prescriptionFiles: string[] = [];
  /**
   * Used for showing prescription image preview.
   */
  public prescriptionImages: Image[][] = [];

  /**
   * Variable to store search string.
   */
  public toHighlight: string;

  /**
   * Storing field types and get it from constants.
   */
  public fieldType: object;

  /**
   * File upload url.
   */
  public fileUploadUrl: string;

  /**
   * Flag for file.
   */
  public selectedFileFlag = false;

  /**
   * Generated image id URL.
   */
  public imageIdUrl: string;

  /**
   * File upload url subscription.
   */
  public fileUploadUrlSub: Subscription;

  /**
   * Storing file.
   */
  public fileStorage: File;

  /**
   * Flag for file upload status.
   */
  public fileUploadStatus = false;
  /**
   * flag variable for max size of prescription
   */
  public isprescriptionFileSizeValid = true;
  /**
   * flag varibale for prescription max lenght
   */
  public isprescriptionFileLengthValid = true;
  /**
   * flag variable for file type valid
   */
  public isFileTypeValid = true;
  /**
   * flag variable for max size of idcard
   */
  public idCardSizeValid = true;

  /**
   * Subscription for cities.
   */
  public formSubmittedIdSub: Subscription;

  /**
   * Country
   */
  public country: string;

  /**
   * Default gender.
   */
  public defaultGender: string;

  /**
   * form isEditable flag.
   */
  public isEditable: boolean;

  /**
   * Subscription for credentials.
   */
  public credSub: Subscription;

  /**
   * login subscription for error handling.
   */
  public loginError: Subscription;

  /**
   * File Upload Status Sub.
   */
  public fileUploadStatusSub: Subscription;

  /**
   * Subscription for programs.
   */
  public programSub: Subscription;

  /**
   * Subscription for region list.
   */
  public regionSub: Subscription;

  /**
   * Subscription for cities list.
   */
  public citySub: Subscription;

  /**
   * Subscription for cities list.
   */
  private pharmaNameChangeSub: Subscription;

  /**
   * Subscription for cities list.
   */
  private pharmaSub: Subscription;

  /**
   * var for disabling country code input.
   */
  public isDisableCountryCode = false;

  /**
   * var for disabling phone input box.
   */
  public isDisablePhone = false;

  /**
   * var for disabling email box.
   */
  public isDisableEmail = false;
  /**
   * varibale to store programs and corresponding drugs
   */
  public programselected: string;
  /**
   * variable to store drug names
   */
  public druglist: string;
  /**
   * variable to store  current date
   */
  public currentdate: Date;
  /**
   * Chatbot field doctor name.
   */
  public doctorName: string;
  /**
   * var for default country.
   */
  public defaultCountry: Country;
  /**
   * Chatbot form name
   */
  public PatientInfoForm: string;
  /**
   * var for selecting the context of form i.e patient or caregiver.
   */
  public isPatientForm: boolean;
  /**
   * var for holding selected program.
   */
  public selectedProgram: Program;
  /**
   * html element for selected program div.
   */
  @ViewChild('selectedProgramDiv') selectedProgramDiv: ElementRef;
  /**
   * html element for selected program div.
   */
  @ViewChild('programSearch') programSearchEle: ElementRef;
  /**
   * Submitting form already.
   */
  public isSubmissionOngoing = false;
  /**
   * Caregiver Subscription.
   */
  public careGiverSub: Subscription;
  /*
   * used to hold the pharma id.
   */
  private pharmaId: string;
  private hybridErrSub: Subscription;
  public process: string;
  public unEmbeddedPharma = UN_EMBEDDED_PHARMA;
  /**
   * to toggle resend otp cta functionality
   */
  public isResendOtp = false;
  public disableResendOtp = true;
  public resendTimer: any;
  /**
   * Emiting an event of submit application.
   */
  submitApplicationForm(id: number, index: number): void {
    console.log('submitApplicationForm', id, index);
    if (this.isControlsValid() && !this.fileUploadStatus && !this.isSubmissionOngoing) {
      this.form.disable();
      this.isSubmissionOngoing = true;
      const submitData = {
        ...this.form.value,
        formId: id,
      };

      if (submitData.program) {
        submitData.pap = submitData.program.Program.Id;
        submitData.patientConsents = submitData.program.Program.PatientConsents;
        console.log('submitData.program', id, index);
      }

      if (submitData.countryCode) {
        submitData.countryCode = +submitData.countryCode;
      }
      if (submitData.phone) {
        submitData.phone = +submitData.phone;
      }
      if (this.checkForThailandCountry()
        && (submitData?.hospitalUser?.countryCode || submitData?.hospitalUser?.mobile)) {
        submitData.hospitalUser = {
          countryCode: +submitData.hospitalUser?.countryCode,
          email: submitData?.hospitalUser?.email,
          id: submitData?.hospitalUser?.id,
          mobile: +submitData?.hospitalUser?.mobile,
          name: submitData?.hospitalUser?.name
        };
      }
      if (this.checkForThailandCountry() && (submitData.caregiverMobile === '' || submitData.caregiverMobile)) {
        submitData.caregiverMobile = submitData?.caregiverMobile ? +submitData.caregiverMobile : 0;
        this.form.get('caregiverCountryCode').setValue(66);
        submitData.caregiverCountryCode = 66;
      }
      if (submitData.addressRegion && submitData.addressRegion.id === undefined) {
        submitData.addressRegion = {
          id: null,
          name: submitData.addressRegion
        };
      }
      if (submitData.addressCity && submitData.addressCity.id === undefined) {
        submitData.addressCity = {
          id: null,
          name: submitData.addressCity
        };
      }
      if (submitData.dateOfAdministration) {
        submitData.dateOfAdministration = new Date(Date.UTC(submitData.dateOfAdministration.getFullYear(),
          submitData.dateOfAdministration.getMonth(), submitData.dateOfAdministration.getDate()));
        submitData.dateOfAdministration = new Date(submitData.dateOfAdministration).getTime() / 1000;
        submitData.dateOfAdministration = Math.floor(submitData.dateOfAdministration);
      }
      if (submitData.dob) {
        submitData.dob =
          new Date(Date.UTC(submitData.dob.getFullYear(), submitData.dob.getMonth(), submitData.dob.getDate()));
        submitData.dob = new Date(submitData.dob).getTime() / 1000;
        submitData.dob = Math.floor(submitData.dob);
      }
      if (this.checkForThailandCountry() && submitData.doctorConsentDate) {
        submitData.doctorConsentDate = new Date(submitData.doctorConsentDate).getTime();
        submitData.doctorConsentDate = submitData.doctorConsentDate / 1000;
      }
      if (this.checkForThailandCountry() && submitData.receivedApplicationDate) {
        submitData.receivedApplicationDate = new Date(submitData.receivedApplicationDate).getTime();
        submitData.receivedApplicationDate = submitData.receivedApplicationDate / 1000;
      }
      if (submitData.idUrl && this.imageIdUrl) {
        submitData.idUrl = this.imageIdUrl;
      }
      if (this.process) {
        submitData.process = this.process;
      }
      let submitEndPoint = '';
      if (this.reapplication) {
        if (this.responseData.submitted) {
          console.log('responseData.submitted', id, index);
          submitEndPoint = this.responseData.apiEndPoints.edit + '?reapply=true&count=' + this.reapplicationCount;
        } else {
          console.log('responseData.submitted else', id, index);
          submitEndPoint = this.responseData.apiEndPoints.submit + '?reapply=true&count=' + this.reapplicationCount;
        }
      } else {
        if (this.responseData.submitted) {
          submitEndPoint = this.responseData.apiEndPoints.edit;
          console.log('reapplication if responseData.submitted else', id, index);
        } else {
          submitEndPoint = this.responseData.apiEndPoints.submit;
          console.log('reapplication else responseData.submitted else', id, index);
        }
      }
      // TODO handle this in interceptor
      if (this.responseData.formType === HYBRID_STATES.LOGIN) {
        if (this.checkForOazisProcess()) {
          submitData.email = submitData.phone + '@docquityclinic.com';
        }
        if (this.chatView === true && this.pharmaCode) {
          console.log('submitLoginData', id, index);
          this.store.dispatch(HybridAction.submitLoginData({
            endPoint: submitEndPoint,
            formData: submitData,
            pharmaCode: this.pharmaCode,
            process: this.process,
          }));
        }
        else {
          console.log('submitLoginData else', id, index);
          this.store.dispatch(HybridAction.submitLoginData({
            endPoint: submitEndPoint,
            formData: submitData,
            process: this.process,
          }));
        }
        this.credSub = this.store.select(HybridSelector.getCreds).subscribe(data => {
          if (data != null) {
            const helper = new JwtHelperService();
            const decodedToken = helper.decodeToken(data.accessToken);
            const accessTokenValue = decodedToken.data.consentToken;
            this.submitPatientData.emit({
              formData: submitData,
              nextIndex: index,
              chatBlockIndex: this.currentChatBlock,
              formType: this.responseData.formType,
              consentToken: accessTokenValue,
              accessToken: data.accessToken,
              jwtTokenExpire: decodedToken.exp,
            });
          }
        });
        this.loginError = this.store.select(HybridSelector.getHybridError).subscribe(data => {
          this.submitError.emit({ formData: submitData });
        });
      } else if (this.responseData.formType === HYBRID_STATES.PROGRAM_INFO) {
        console.log('PROGRAM_INFO else', id, index);

        this.submitPatientData.emit({
          formData: submitData,
          nextIndex: index,
          chatBlockIndex: this.currentChatBlock,
          formType: this.responseData.formType,
          consentToken: '',
          accessToken: '',
        });
      } else {
        console.log('submitFormData else', id, index, submitData);
        this.store.dispatch(SignupAction.submitFormData({
          endPoint: submitEndPoint,
          method: this.responseData.submitted ? this.responseData.apiEndPoints.editMethod :
            this.responseData.apiEndPoints.submitMethod,
          formData: submitData,
          applicationType: this.application,
          idToken: this.idToken ? this.idToken.accessToken : '',
        }));
        this.formSubmittedIdSub = this.store.select(SignupSelector.getSubmittedFormID).subscribe(data => {
          if (data != null && data === id) {
            this.submitPatientData.emit({
              formData: submitData,
              nextIndex: index,
              chatBlockIndex: this.currentChatBlock,
              formType: this.responseData.formType,
              consentToken: '',
              accessToken: ''
            });
            this.store.dispatch(SignupAction.resetformSubmittedId({
              formSubmittedId: null
            }));
            this.unsubscribeFormSubmittedSubscriptions();
          }
        });
      }
      this.isSubmissionOngoing = true;
      this.disableResendOtp = true;
      clearTimeout(this.resendTimer);
    }
  }

  /**
   * Emiting an event of edit form.
   */
  editApplicationForm(index: number): void {
    this.editPatientData.emit({ formData: this.form.value, nextIndex: index, chatBlockIndex: this.currentChatBlock });
  }

  /**
   * Time update for particular message.
   */
  currentTime(): boolean {
    if (this.docquityTime === undefined) {
      this.docquityTime = new Date();
    }
    return true;
  }

  /**
   * function to validate filesize
   */
  isFileSizeValid(event: any, maxSize: number): boolean {
    if (event.target.files) {
      for (const item of event.target.files) {
        if (item.size > maxSize) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Generate file endpoint using query parameters.
   */
  getFileName(chatbotToken: string, time: number): string {
    if (this.responseData.formType === HYBRID_STATES.PRESCRIPTION_INFO) {
      return `${chatbotToken}-${time}-prescription`;
    } else if (this.responseData.formType === HYBRID_STATES.ADDITIONAL_INFO) {
      return `${chatbotToken}-${time}-discount`;
    } else if (this.responseData.context === CHATBOT_CONSTANTS.PATIENT) {
      return `${chatbotToken}-1-idcard`;
    } else {
      return `${chatbotToken}-2-idcard`;
    }
  }

  /**
   * Event Handler for uploading prescription files.
   */
  async onSelectMultipleFile(event, controlName: string): Promise<void> {
    const typeofImage = event.target.files[0].type;
    const ifAllowed = this.fileTypesDoc.includes(typeofImage);
    if (ifAllowed === false) {
      this.isFileTypeValid = false;
      return;
    }
    this.isFileTypeValid = true;
    this.isprescriptionFileSizeValid = this.isFileSizeValid(event, MAX_FILE_SIZE);
    if (this.isprescriptionFileSizeValid === false) {
      this.isprescriptionFileLengthValid = true;
      return;
    }
    if (event.target.files && event.target.files.length) {
      if (controlName === 'discountUrls') {
        if ((this.form.get(controlName).value.length + event.target.files.length) > 5) {
          this.isprescriptionFileLengthValid = false;
          this.isprescriptionFileSizeValid = true;
          return;
        }
      } else {
        if ((this.form.get(controlName).value.length + event.target.files.length) > 10) {
          this.isprescriptionFileLengthValid = false;
          this.isprescriptionFileSizeValid = true;
          return;
        }
      }
      this.isprescriptionFileLengthValid = true;
      this.form.get(controlName).setValue('');
      for (const file of event.target.files) {
        const fileName = this.getFileName(this.token, Date.now());
        let getPresignedUrl: string;
        if (controlName === 'urls') {
          getPresignedUrl = await this.uploadFile(file, FOLDER_CONSTANTS.PRESCRIPTION, fileName);
        } else if (controlName === 'discountUrls') {
          getPresignedUrl = await this.fileService.uploadFile(
            file, null,
            FOLDER_CONSTANTS.DISCOUNT_PROOFS + '/' + fileName,
            FILE_MODULE.PAP,
            COUNTRIES_LIST.PHILLIPPINS.name
          );
        }
        this.prescriptionFiles.push(getPresignedUrl);
        this.prescriptionImages.push([{
          source: getPresignedUrl,
          alt: 'image'
        }]);
      }
      this.form.get(controlName).setValue(this.prescriptionFiles);
      return;
    }
  }

  /**
   * Helper function for uploading file.
   */
  async uploadFile(file: File, cloudFolderPath: string, fileName: string): Promise<string> {
    console.log('putPresignedUrl', file);
    const putPresignedUrl = await this.fetchPutPresignedUrl(cloudFolderPath, fileName, file.type);
    await this.uploadFileToCloud(file, putPresignedUrl);
    const getPresignedUrl = await this.fetchGetPresignedUrl(cloudFolderPath, fileName, file.type);
    return getPresignedUrl;
  }

  /**
   * Fetching aws get presigned url.
   */
  fetchGetPresignedUrl(fileType: string, fileName: string, contentType: string): Promise<string> {
    const time = new Date().getMilliseconds();
    return new Promise((resolve, reject) => {
      this.store.dispatch(FileAction.loadFilePresignedUrl({
        endPoint: this.responseData.apiEndPoints.preSignedUrl,
        fileType,
        fileName,
        contentType,
        fileMethod: 'false',
        time
      }));
      this.fileUploadUrlSub = this.store.select(FileSelector.getFilePresignedUrl).subscribe(url => {
        if (url != null) {
          resolve(url);
          this.store.dispatch(FileAction.resetFilePresignedUrl({
            presignedUrl: null
          }));
        }
      });
    });
  }

  /**
   * Uploading file to cloud from presigned url.
   */
  uploadFileToCloud(file: File, presignedUrl: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(SignupAction.fileUpload({
        endPoint: presignedUrl,
        file
      }));
      this.fileUploadStatusSub = this.store.select(SignupSelector.getFileUploadStatus).subscribe(status => {
        if (status != null && status === 'done') {
          resolve(status);
          this.store.dispatch(SignupAction.resetFileUploadStatus({
            fileUploadStatus: null
          }));
        }
      });
    });
  }

  /**
   * Fetching aws put presigned url for file.
   */
  fetchPutPresignedUrl(fileType: string, fileName: string, contentType: string): Promise<string> {
    return new Promise((resolve, reject) => {
      console.log('file-upload', fileType, fileName, contentType);
      console.log('file-loadFileUploadUrl', this.responseData.apiEndPoints);
      this.store.dispatch(SignupAction.loadFileUploadUrl({
        endPoint: this.responseData.apiEndPoints.preSignedUrl,
        method: this.responseData.apiEndPoints.preSignedUrlMethod,
        fileType,
        fileName,
        contentType
      }));
      this.fileUploadStatusSub = this.store.select(SignupSelector.getFileUploadUrl).subscribe(data => {
        if (data != null) {
          resolve(data);
          this.store.dispatch(SignupAction.resetFileUploadUrl({
            fileUploadUrl: null
          }));
        }
      });
    });
  }

  /**
   * Image upload.
   */
  async onSelectFile(event, controlName: string): Promise<void> {
    const typeofImage = event.target.files[0].type;
    const ifAllowed = this.fileTypesDoc.includes(typeofImage);
    if (ifAllowed === false) {
      return;
    }
    this.idCardSizeValid = this.isFileSizeValid(event, MAX_FILE_SIZE);
    if (this.idCardSizeValid === false) {
      return;
    }
    if (event.target.files && event.target.files.length) {
      this.form.get(controlName).setValue('');
      const file: File = event.target.files[0];
      const getPresignedUrl = await this.uploadFile(file, 'idcard', this.getFileName(this.token, Date.now()));
      this.form.get(controlName).setValue(getPresignedUrl);
    }
  }

  /**
   * Checking form group validations.
   */
  public isControlsValid(): boolean {
    const controls = this.form.controls;
    for (const name in controls) {
      if ((name === 'soldToPatientHn' || name === 'soldToHospital') && !this.checkForServierProcess())
        {
          continue;
        }
      if (controls[name].invalid) {
        return false;
      }
    }
    return true;
  }

  /**
   * Remove image from form.
   */
  removeFile(event, controlName: string, index: number): void {
    if (index === -1) {
      this.form.get(controlName).setValue('');
    } else {
      this.prescriptionFiles.splice(index, 1);
      this.prescriptionImages.splice(index, 1);
      this.form.get(controlName).setValue(this.prescriptionFiles);
      if (this.prescriptionFiles.length < 1) {
        this.form.get(controlName).setValue('');
      }
    }
  }
  /**
   * funntion to restrict only blankspaces entry.
   */
  userInput(fieldName: string, event: any): void {
    if (event.target.value) {
      const value = event.target.value;
      if (value[0] === ' ') {
        this.form.get(fieldName).setValue(value.trimStart());
      }
    }
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0];
  }
  /**
   * Search Functionality for region and cities.
   */
  search(event, type: string): void {

    if (this.checkForOazisProcess && this.programName) {
      event.query = this.programName;
    }
    this.toHighlight = event.query;
    if (type === CHATBOT_CONSTANTS.PROGRAM) {
      this.store.dispatch(HybridAction.resetProgramList());
      this.druglist = '';
      this.programselected = '';
      this.store.dispatch(HybridAction.loadPrograms({
        keyword: event?.query,
        endPoint: this.responseData.apiEndPoints.programSearch,
        method: this.responseData.apiEndPoints.programSearchMethod,
        pharma: this.pharmaId,
        limit: CHATBOT_PROGRAM_FETCH_LIMIT
      }));
    }
    if (type === CHATBOT_CONSTANTS.REGION) {
      this.store.dispatch(SignupAction.loadRegions({
        keywords: event.query,
        endPoint: this.responseData.apiEndPoints.regionSearch,
        method: this.responseData.apiEndPoints.regionSearchMethod
      }));
      this.regionSub = this.store.select(SignupSelector.getRegionsList).subscribe(data => {
        if (data !== null) {
          this.regions = data.list;
        }
      });
    } else {
      this.regions = [];
    }
    if (type === CHATBOT_CONSTANTS.CITY && this.form.get('addressRegion').value.id) {
      this.responseData.apiEndPoints.citySearch =
        this.responseData.apiEndPoints.citySearch.replace('REGION', this.form.get('addressRegion').value.id);
      this.store.dispatch(SignupAction.loadCities({
        keywords: event.query,
        endPoint: this.responseData.apiEndPoints.citySearch,
        method: this.responseData.apiEndPoints.citySearchMethod
      }));
      this.citySub = this.store.select(SignupSelector.getCitiesList).subscribe(data => {
        if (data !== null) {
          this.cities = data.list;
        }
      });
    } else {
      this.cities = [];
    }
  }
  /**
   * Unsubscribe form submitted id subscription.
   */
  unsubscribeFormSubmittedSubscriptions(): void {
    if (this.formSubmittedIdSub) {
      this.formSubmittedIdSub.unsubscribe();
    }
    if (this.hybridErrSub) {
      this.hybridErrSub.unsubscribe();
    }
  }

  /**
   * function to show back program search field
   */
  showFaceDetails(): void {
    this.selectedProgramDiv.nativeElement.classList.add('d-none');
    this.programSearchEle.nativeElement.classList.remove('d-none');
    document.getElementById('searchdrug').focus();
  }
  /**
   * function to be called on selecting pap program
   */
  onSelectProgram(event: any): void {
    this.selectedProgram = event.Program;
    this.selectedProgramDiv.nativeElement.innerHTML = this.generateSelectedProgramHTML();
    this.selectedProgramDiv.nativeElement.classList.remove('d-none');
    this.programSearchEle.nativeElement.classList.add('d-none');
  }
  /**
   * This function is used for generating html for
   * selected program.
   */
  generateSelectedProgramHTML(): string {
    let html = '';
    html += `<div class="mb-1 px-2 fnt-size12px mb-2 text-break">${this.selectedProgram.Name}</div>`;
    if (this.checkForOazisProcess()) {
      return html;
    }
    html += `<div>`;
    for (const drug of this.selectedProgram.Drugs) {
      html +=
        `<div class="px-2">
        <span>${drug.GenericName}</span>
        <span>(</span>
        <span>${drug.BrandName}</span>
        <span>)</span>
      </div>`;
    }
    html += '</div>';
    return html;
  }
  /**
   * function to validate only number input
   */
  validateNo(e: any): boolean {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /**
   * Required instances
   */
  constructor(private store: Store, private fileService: FileService,
              private helpers: HelperService, private translate: TranslateService,
              private pharmaNameChange: PharmaNameService,
            ) { }
  /**
   * Initialise the data.
   */
  ngOnInit(): void {
    this.process = sessionStorage.getItem('process');
    this.pharmaNameChange.setProgramPage(true);
    this.responseData.attributes.sort((a, b) => {
      return a.arrangementOrder > b.arrangementOrder ? 1 : -1;
    });
    this.hybridErrSub = this.store.select(HybridSelector.getHybridError).subscribe(data => {
      if (data != null) {
        if (data.code === ERROR_CODE.E4024) {
          if (this.responseData.formType === HYBRID_STATES.CARETAKER_INFO_FORM && !this.responseData.submitted) {
            this.responseData.patientAlreadyExistsErr = this.translate.instant('USER_REGISTERED_AS_PATIENT');
          }
        }
      }
    });
    this.store.dispatch(HybridAction.resetProgramList());
    this.isPatientForm = this.responseData.formType === HYBRID_STATES.PATIENT_INFO_FORM
      || this.responseData.formType === HYBRID_STATES.LOGIN ? true : false;

    this.fileTypesDoc = ['image/jpg', 'image/jpeg', 'image/png'];
    this.currentdate = new Date();
    this.defaultGender = 'MALE';
    this.doctorName = FIELD_NAME.DOCTOR_NAME;
    this.PatientInfoForm = HYBRID_STATES.PATIENT_INFO_FORM;
    if (this.token !== '') {
      if (this.responseData.apiEndPoints.submit) {
        this.responseData.apiEndPoints.submit = this.responseData.apiEndPoints.submit.replace('TOKEN', this.token);
      }
      if (this.responseData.apiEndPoints.edit) {
        this.responseData.apiEndPoints.edit = this.responseData.apiEndPoints.edit.replace('TOKEN', this.token);
      }
      /**
       * enable the resend otp cta
       * after 30 secs
       */
      this.resendTimer = setTimeout(() => {
        this.disableResendOtp = false;
      }, DEFAULT_TIMER.OTP_RESEND_TIME);
    }
    if (JSON.parse(localStorage.getItem('patientAuth'))) {
      const idToken = JSON.parse(localStorage.getItem('patientAuth'));
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(idToken.accessToken);
      this.patientEmail = decodedToken.data.email;
      this.patientPhoneNo = decodedToken.data.phoneNumber;
      this.patientCountryCode = decodedToken.data.countryCode;
      this.pharmaId = decodedToken?.data?.pharma?.id;
    }
    this.idToken = JSON.parse(localStorage.getItem('patientAuth'));
    if (this.responseData && this.responseData.onSubmit) {
      this.isEditable = this.responseData.onSubmit.isEditable;
    } else {
      this.isEditable = true;
    }

    this.fieldType = FIELD_TYPE;

    this.country = localStorage.getItem(LOCAL_STORAGE_COUNTRY.COUNTRY);
    for (const country of this.countries) {
      if (country.code === this.country) {
        this.defaultCountry = country;
        break;
      }
      const result = this.countries.filter((obj) => {
        return obj.code === COUNTRIES_LIST.PHILLIPPINS.value;
      });
      this.defaultCountry = result[0];
    }

    /**
     * Form initialisation.
     */
    const group = {};
    this.responseData.attributes.forEach(async element => {
      if (element.mandatory) {
        if (element.type === FIELD_TYPE.FILE) {
          if (element.value) {
            const fileExtension = element.value.split('.').pop().split(PATTERN.URL_SUFFIX)[0];
            let contentType = '';
            switch (fileExtension.toLowerCase()) {
              case IMAGE_TYPES.jpeg:
                contentType = MIME_TYPES.JPEG;
                break;
              case IMAGE_TYPES.jpg:
                contentType = MIME_TYPES.JPEG;
                break;
              case IMAGE_TYPES.png:
                contentType = MIME_TYPES.PNG;
                break;
            }
            const fileName = this.getFileName(this.token, Date.now());
            const getPresignedUrl = await this.fetchGetPresignedUrl('idcard', fileName, contentType);
            group[element.fieldName] = new FormControl(
              getPresignedUrl,
              [Validators.required]
            );
          }
          else if (!element.value) {
            group[element.fieldName] = new FormControl(
              '',
              [Validators.required]
            );
          }
        }
        else if (element.type === FIELD_TYPE.MULTIPLE_FILE) {
          if (element.value) {
            const imageUrls = JSON.parse(element.value);
            // tslint:disable-next-line: prefer-for-of
            for (const image of imageUrls) {
              const ele: string = image;
              const fileExtension = ele.split('.').pop().split(PATTERN.URL_SUFFIX)[0];
              let fileTime: string;
              if (element.fieldName === 'discountUrls') {
                fileTime = ele.split('-discount').shift().split(`${this.token}-`).pop();
              } else {
                fileTime = ele.split('-prescription').shift().split(`${this.token}-`).pop();
              }
              let contentType = '';
              switch (fileExtension.toLowerCase()) {
                case IMAGE_TYPES.jpeg:
                  contentType = MIME_TYPES.JPEG;
                  break;
                case IMAGE_TYPES.jpg:
                  contentType = MIME_TYPES.JPEG;
                  break;
                case IMAGE_TYPES.png:
                  contentType = MIME_TYPES.PNG;
                  break;
              }
              const fileName = this.getFileName(this.token, parseInt(fileTime, 10));
              let getPresignedUrl: string;
              if (element.fieldName === 'discountUrls') {
                getPresignedUrl = await this.fileService.fetchPresignedUrl('null', FOLDER_CONSTANTS.DISCOUNT_PROOFS + '/' + fileName, FILE_MODULE.PAP, COUNTRIES_LIST.PHILLIPPINS.name, false, contentType);
              } else {
                getPresignedUrl = await this.fetchGetPresignedUrl('prescription', fileName, contentType);
              }
              this.prescriptionFiles.push(getPresignedUrl);
              this.prescriptionImages.push([{
                source: getPresignedUrl,
                alt: 'image'
              }]);
            }
            group[element.fieldName] = new FormControl(
              this.prescriptionFiles,
              [Validators.required]
            );
          }
          else if (!element.value) {
            group[element.fieldName] = new FormControl(
              '',
              [Validators.required]
            );
          }
        }
        else if (element.type === FIELD_TYPE.PHONE) {
          if (this.application === APPLICATION_TYPE.HYBRID
            && this.responseData.formType === HYBRID_STATES.PATIENT_INFO_FORM) {
            const phoneControl = new FormControl(this.patientPhoneNo);
            phoneControl.disable();
            group[element.fieldName] = phoneControl;
          }
          else if (element.value && element.value !== '0') {
            group[element.fieldName] = new FormControl(
              element.value,
              [Validators.required, Validators.minLength(8), Validators.maxLength(15)]
            );
          }
          else if (!element.value || element.value === '0') {
            group[element.fieldName] = new FormControl(
              '',
              [Validators.required, Validators.minLength(8), Validators.maxLength(15)]
            );
          }
        }
        else if (element.type === FIELD_TYPE.SELECT) {
          if ((this.application === APPLICATION_TYPE.HYBRID
            && this.responseData.formType === HYBRID_STATES.PATIENT_INFO_FORM)) {
            const countryCodeControl = new FormControl(this.patientCountryCode);
            if (element.fieldName !== 'countryCode') {
              countryCodeControl.disable();
            }
            group[element.fieldName] = countryCodeControl;
          } else if (this.checkForThailandCountry() && element.fieldName === 'hospitalUser') {
            console.log('hospitalUser', element.value);
            let users = null;
            if (element.value) {
              users = this.checkForEmptyJson(JSON.parse(element.value), 'id');
            }
            group[element.fieldName] = new FormControl(
              users ? users : '',
              [Validators.required]
            );
          }
          else if (this.checkForThailandCountry() && element.fieldName === 'dispensingHospital') {
            if (element.value) {
              const hospital = JSON.parse(element.value);
              this.store.dispatch(SignupAction.getUserForHospital({
                hospitalId: hospital.id
              }));
            }
            group[element.fieldName] = new FormControl(
              element?.value ? this.checkForEmptyJson(JSON.parse(element.value), 'id') : '',
              [Validators.required]
            );
          }
          else if (this.checkForThailandCountry() && (element.fieldName === 'treatmentHospital' || element.fieldName === 'soldToHospital')) {
            group[element.fieldName] = new FormControl(
              element?.value ? this.checkForEmptyJson(JSON.parse(element.value), 'id') : '',
              [Validators.required]
            );
          } else if (this.checkForThailandCountry() && element.fieldName === 'securityProfile' || element.fieldName === 'idCardType') {
            group[element.fieldName] = new FormControl(
              element?.value ? element.value : '',
              [Validators.required]
            );
          } else if (this.checkForThailandCountry() &&
              this.checkForServierProcess() && element.fieldName === 'gender') {
            group[element.fieldName] = new FormControl(
              element?.value ? element.value : '',
              [Validators.required]
            );
          } else if (element.value && element.value !== '0') {
            console.log('element.type 2', element.value);
            group[element.fieldName] = new FormControl(
              element.value,
              [Validators.required]
            );
          }
          else if (!element.value || element.value === '0') {
            console.log('element.type fieldName', element.fieldName);
            group[element.fieldName] = new FormControl(
              this.defaultCountry.countryCode,
              [Validators.required]
            );
            console.log('element.type3', element);
          }
        }
        else if (element.type === FIELD_TYPE.EMAIL) {
          if (this.application === APPLICATION_TYPE.HYBRID
            && this.responseData.formType === HYBRID_STATES.PATIENT_INFO_FORM) {
            const emailControl = new FormControl(this.patientEmail);
            emailControl.disable();
            group[element.fieldName] = emailControl;
          }
          else if (element.value) {
            group[element.fieldName] = new FormControl(
              element.value,
              [Validators.required, Validators.email]
            );
          }
          else if (!element.value) {
            group[element.fieldName] = new FormControl(
              '',
              [Validators.required, Validators.email]
            );
          }
        }
        else if (element.fieldName === this.doctorName) {
          if (!this.checkForThailandCountry() && !element.value) {
            group[element.fieldName] = new FormControl(
              '',
              [Validators.required, Validators.pattern(PATTERN.DOCTOR_NAME_REG)]
            );
          }
          if (this.checkForThailandCountry() && !element.value) {
            group[element.fieldName] = new FormControl(
              '',
              [Validators.required]
            );
          }
          else if (!this.checkForThailandCountry() && element.value) {
            group[element.fieldName] = new FormControl(
              element.value,
              [Validators.required, Validators.pattern(PATTERN.DOCTOR_NAME_REG)]
            );
          }
          else if (this.checkForThailandCountry() && element.value) {
            group[element.fieldName] = new FormControl(
              element.value,
              [Validators.required]
            );
          }
        }
        else if (element.type === FIELD_TYPE.DATE) {
          if (element.value) {
            const date = new Date(+element.value * 1000);
            group[element.fieldName] = new FormControl(
              element?.fieldName === 'dob' ? date : element?.value !== '0' ? date : '',
              [(this.process !== this.unEmbeddedPharma && element.fieldName === 'dateOfAdministration') ?
                null : Validators.required]
            );
          }
          else if (!element.value) {
            group[element.fieldName] = new FormControl(
              '',
              [(this.process !== this.unEmbeddedPharma && element.fieldName === 'dateOfAdministration') ?
                null : Validators.required]
            );
          }
        }
        else if (element.type === FIELD_TYPE.SEARCH) {
          if (element.value) {
            // TODO: sumit remove any.
            const obj: any = JSON.parse(element.value);
            if (obj.id !== 0 || obj.name !== '') {
              group[element.fieldName] = new FormControl(
                obj,
                [element.fieldName.startsWith('address') && this.process === this.unEmbeddedPharma ?
                  null : Validators.required]
              );
            } else {
              group[element.fieldName] = new FormControl(
                '',
                [element.fieldName.startsWith('address') && this.process === this.unEmbeddedPharma ?
                  null : Validators.required]
              );
            }
          }
          else if (!element.value) {
            group[element.fieldName] = new FormControl(
              '',
              [element.fieldName.startsWith('address') && this.process === this.unEmbeddedPharma ?
                null : Validators.required]
            );
          }
        }
        else if (element.type === FIELD_TYPE.NUMBER) {
          if (this.application === APPLICATION_TYPE.HYBRID
            && this.responseData.formType === HYBRID_STATES.ADDITIONAL_INFO) {
            group[element.fieldName] = new FormControl(element.value ? element.value : null,
              [Validators.required, Validators.pattern(PATTERN.ALLOW_0_RESTRICT_AFTER_0)]);
          } else if (this.application === APPLICATION_TYPE.HYBRID
            && this.responseData.formType === HYBRID_STATES.PATIENT_VERIFY_OTP) {
            group[element.fieldName] = new FormControl(element.value &&
              this.responseData.submitted ? element.value : null,
              [Validators.required, Validators.pattern(PATTERN.OTP_FOUR)]);
          }
        }
        else if (element.value) {
          group[element.fieldName] = new FormControl(element.value, (element.fieldName.startsWith('address')
            && this.process === this.unEmbeddedPharma ? null : Validators.required));
        }
        else if (!element.value) {
          group[element.fieldName] = new FormControl('', (element.fieldName.startsWith('address')
            && this.checkForAddressFieldsValidation(element.fieldName) ? null : Validators.required));
        }
      } else {
        if (element.value) {
          group[element.fieldName] = new FormControl((element?.value && element?.value !== '0') ? element.value : '');
        }
        else {
          group[element.fieldName] = new FormControl('');
        }
      }
    });
    this.form = new FormGroup(group);

    /**
     * Disable form when submitted.
     */
    if (this.responseData.submitted || this.responseData.isCareGiverExist === true) {
      this.form.disable();
    }
    this.careGiverSub = this.store.select(HybridSelector.getCareGiverData).subscribe(data => {
      if (data !== null) {
        if (this.responseData.formType === HYBRID_STATES.CARETAKER_INFO_FORM) {
          this.responseData.attributes.forEach(async element => {
            switch (element?.fieldName) {
              case 'firstName': {
                this.form.get('firstName').setValue(data.firstName);
                break;
              }
              case 'lastName': {
                this.form.get('lastName').setValue(data.lastName);
                break;
              }
              case 'phone': {
                this.form.get('phone').setValue(data.phone);
                break;
              }
              case 'countryCode': {
                this.form.get('countryCode').setValue(data.countryCode);
                break;
              }
              case 'email': {
                this.form.get('email').setValue(data.email);
                break;
              }
              case 'relationship': {
                this.form.get('relationship').setValue(data.relationship);
                break;
              }
              default: {
                break;
              }
            }
          });
        }
      }
    });
    this.programSub = this.store.select(HybridSelector.getPrograms).subscribe(data => {
      if (data) {
        this.programs = JSON.parse(JSON.stringify(data));
      }
    });

    if (this.checkForThailandCountry()) {
      this.programSub = this.store.select(SignupSelector.getUsersList).subscribe(data => {
        if (data) {
          console.log('data', data);
          this.userHospitalList = data;
        }
      });
    }
    this.pharmaSub = this.store.select(HomeSelector.getPharmDetails)
    .subscribe(res => {
      if (res) {
        sessionStorage.removeItem('process');
        sessionStorage.setItem('process', res.process);
        this.process = res?.process;
      }
    });
  }
  /**
   * used to auto focus on phone no. input.
   */
  ngAfterViewInit(): void {
    document.getElementById('phoneNumber').focus();
    /**
     * reset the forms after pharma name changes
     */
    this.pharmaNameChangeSub = this.pharmaNameChange.data$.subscribe(res => {
      if (res != null) {
        // const formControls: { [key: string]: AbstractControl } = this.form.controls;
        // Object.keys(formControls).forEach(controlName => {
        //   if (controlName) {
        //     formControls[controlName].setValue('');
        //   }
        // });
        this.store.dispatch(HomeAction.getPharmaDetails({ pharmaCode: res }));
      }
    });
  }

  /**
   * to check if a given field is present in the object or not
   * @param obj json object to be checked
   * @param field field to be checked
   * @returns the given object if success
   */
  checkForEmptyJson(obj: any, field: string): any {
    return obj ? (obj[field] ? obj : null) : null;
  }

  /**
   * Unsubscribe the subscriptions.
   */
  ngOnDestroy(): void {
    this.pharmaNameChange.setProgramPage(false);
    this.store.dispatch(HybridAction.resetCaregiver({}));
    if (this.formSubmittedIdSub) {
      this.formSubmittedIdSub.unsubscribe();
    }
    if (this.citySub) {
      this.citySub.unsubscribe();
    }
    if (this.credSub) {
      this.credSub.unsubscribe();
    }
    if (this.formSubmittedIdSub) {
      this.formSubmittedIdSub.unsubscribe();
    }
    if (this.regionSub) {
      this.regionSub.unsubscribe();
    }
    if (this.programSub) {
      this.programSub.unsubscribe();
    }
    if (this.fileUploadUrlSub) {
      this.fileUploadUrlSub.unsubscribe();
    }
    if (this.fileUploadStatusSub) {
      this.fileUploadStatusSub.unsubscribe();
    }
    if (this.loginError) {
      this.loginError.unsubscribe();
    }
    if (this.hybridErrSub) {
      this.hybridErrSub.unsubscribe();
    }
    if (this.pharmaNameChangeSub){
      this.pharmaNameChangeSub.unsubscribe();
    }
    if (this.pharmaSub){
      this.pharmaSub.unsubscribe();
    }
    if (sessionStorage.getItem('process')) {
      sessionStorage.removeItem('process');
    }
  }

  goBack(formId: number, nextIndex: number, responseText: string): void {
    if (!this.isSubmissionOngoing) {
      this.isSubmissionOngoing = true;
      this.responseData.deleteCaregiverSub = true;
      this.responseData.response = responseText;
      this.responseData.submitted = true;
      this.goBackToEdit.emit({ nextIndex, chatBlockIndex: this.currentChatBlock });
      this.isSubmissionOngoing = false;
    }
  }
  deleteCaregiver(formId: number, nextIndex: number, responseText: string): void {
    if (!this.isSubmissionOngoing) {
      this.responseData.deleteCaregiverSub = true;
      this.isSubmissionOngoing = true;
      this.responseData.response = this.translate.instant(responseText);
      this.responseData.submitted = true;
      this.deleteCaregiverData.emit({
        formData: {
          caregiverEmail: this.responseData.attributes
            .find(res => res.fieldName === 'email')?.value,
          caregiverPhoneNumber: parseInt(this.responseData.attributes
            .find(res => res.fieldName === 'phone')?.value, 10),
          status: 'INACTIVE', formId
        },
        nextIndex,
        chatBlockIndex: this.currentChatBlock,
        careGiverName: this.responseData.attributes.find(res => res.fieldName === 'firstName')?.value + ' ' +
          this.responseData.attributes.find(res => res.fieldName === 'lastName')?.value,
      });
      this.isSubmissionOngoing = false;
    }
  }
  onKey(): void {
    if (this.responseData.formType === HYBRID_STATES.CARETAKER_INFO_FORM) {
      this.responseData.patientAlreadyExistsErr = undefined;
      const emailControl = this.form.get('email');
      const phoneControl = this.form.get('phone');
      if (!emailControl.errors && !phoneControl.errors) {
        this.store.dispatch(HybridAction.loadCaregiver({
          token: this.token, accessToken: this.idToken?.accessToken, applicationType: this.application,
          caregiverEmail: emailControl.value, caregiverPhoneNumber: phoneControl.value
        }));
      }
    }
  }
  deleteEpapCaregiver(formId: number, nextIndex: number, responseText: string): void {
    if (!this.isSubmissionOngoing) {
      this.responseData.deleteCaregiverSub = true;
      this.isSubmissionOngoing = true;
      this.responseData.response = responseText;
      this.responseData.submitted = true;
      this.deleteEpapCaregiverData.emit({
        formData: {
          caregiverEmail: this.responseData.attributes
            .find(res => res.fieldName === 'email')?.value,
          caregiverPhoneNumber: parseInt(this.responseData.attributes
            .find(res => res.fieldName === 'phone')?.value, 10),
          status: 'INACTIVE', formId
        },
        nextIndex,
        chatBlockIndex: this.currentChatBlock,
        careGiverName: this.responseData.attributes.find(res => res.fieldName === 'firstName')?.value + ' ' +
          this.responseData.attributes.find(res => res.fieldName === 'lastName')?.value,
      });
      this.isSubmissionOngoing = false;
    }
  }

  onHospitalChange(event): void {
    console.log('onHospitalChange', event);
    this.form.get('hospitalUser').setValue(null);
    this.store.dispatch(SignupAction.getUserForHospital({
      hospitalId: event.value.id
    }));
  }

  /**
   * validation check for address fields
   * @param field field name for form
   * @returns boolean value
   */
  checkForAddressFieldsValidation(field: string): boolean {
    if (this.process === this.unEmbeddedPharma
      || (field === 'addressSubDivision' && this.checkForServierProcess())) {
      return true;
    }
    return false;
  }

  /**
   * Check if country is thailand.
   */
  checkForThailandCountry(): boolean {
    return localStorage.getItem('country') === COUNTRIES_LIST.THAILAND.value;
  }

  checkForOazisProcess(): boolean {
    return  sessionStorage.getItem('process') === OAZIS_FLOW_PROCESS;
  }

  checkForServierProcess(): boolean {
    return sessionStorage.getItem('process') === TH_SERVIER_PROCESS;
  }

  /**
   * function to resend otp
   */
  resendOtp(): void {
    this.store.dispatch(HybridAction.requestOtp({ consentToken: this.token }));
    this.isResendOtp = true;
    this.disableResendOtp = true;
  }
}
