<div class="row no-gutters h-100 doctorRegistration" *ngIf="this.userId && this.userProfile">
  <div *ngIf="!findDoctor" class="col-md-2 d-none d-md-block">
    <div class=" bg-white h-100">
      <div class="text-center p-3">
        <img src="assets/images/logo.svg" alt="Logo">
      </div>
      <nav class="vertical-nav bg-white h-100 pl-3 mt-3 pr-1" id="sidebar">
        <ul class="nav flex-column bg-white mb-0 leftNav h-100">
          <li class="nav-item active mb-1">
            <a class="nav-link text-dark bg-light fnt-size15px d-flex align-items-center w-100 rounded">
              <img src="assets/images/profile-logo.svg" alt="profile">
              <span>{{ "PROFILE" | translate }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="cursor-pointer nav-link text-dark bg-light fnt-size15px d-flex align-items-center w-100 rounded" (click)="navigateToAEReporting()">
              <img src="assets/images/profile-logo.svg" alt="profile">
              <span>{{ "REPORT_ADVERSE_EVENT" | translate }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="cursor-pointer nav-link text-dark bg-light fnt-size15px d-flex align-items-center w-100 rounded" (click)="navigateToResources()">
              <img src="assets/images/resource-active.svg" alt="profile">
              <span>{{ "RESOURCES" | translate }}</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="col-md-10"  [ngClass]="{'col-md-12 px-4' : findDoctor}">
    <div *ngIf="!findDoctor" class="bg-white p-3 border-bottom  text-right sticky-top header-view">
      <pap-doctor-header [doctorDetails]="element" [userProfile]="userProfile"></pap-doctor-header>

    </div>
    <div class="p-3 mb-2">
      <span class="mt-2 fnt-size20px font-weight-bold">
        <span *ngIf="(this.userRole === userType.APPROVER || this.userRole === userType.PAP_SUPERVISOR); else headingText" >{{ "DOCTOR_ID" | translate }}: {{this.userId}}</span>
        <ng-template #headingText>{{ "PROFILE" | translate }}</ng-template>
      </span>
      <button class="btn btn-outline-success float-right" (click)="doctorEdit()">
        <span *ngIf="(this.userRole === userType.APPROVER || this.userRole === userType.PAP_SUPERVISOR); else buttonText" >{{ "EDIT_DOCTOR_DETAILS" | translate }}</span>
        <ng-template #buttonText>{{ "EDIT_DETAILS" | translate }}</ng-template>
      </button>
    </div>
    <div class="border rounded bg-white p-3">
      <div class="row">
        <div class="col-12" *ngIf="checkForThailandCountry()">
          <h4>{{'DOCTOR_DETAILS' | translate}}</h4>
        </div>
        <div class="col form-group" *ngIf="!checkForThailandCountry()">
          <p class="mb-1 heading">{{ "DOCTOR_FIRST_NAME" | translate }}
            <span class="text-danger">*</span>
          </p>
          <div>
            <p class="font-weight-bold">{{element?.personalDetails?.firstName}} {{element?.personalDetails?.middleName ? element?.personalDetails?.middleName : ''}} {{element?.personalDetails?.lastName}}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <ng-container *ngIf="checkForThailandCountry()">
          <div class="col-md-6 form-group">
            <div>
            <p class="mb-1 heading ">{{ "FIRST_NAME" | translate }}
              <span class="text-danger">*</span>
            </p>
            <p class="font-weight-bold">{{element?.personalDetails?.firstName}}</p>
            </div>
          </div>
          <div class="col-md-6 form-group">
            <div>
              <p class="mb-1 heading ">{{ "LAST_NAME" | translate }}
                <span class="text-danger">*</span>
              </p>
              <p class="font-weight-bold">{{element?.personalDetails?.lastName}}</p>
              </div>
          </div>
        </ng-container>
        <div class="col-md-6 form-group">
          <div>
            <p class="mb-1 heading ">{{ "MOBILE_NUMBER" | translate }}
              <span class="text-danger">*</span>
            </p>
            <p class="font-weight-bold">+{{element?.personalDetails?.countryCode}}
              {{element?.personalDetails?.phoneNumber}}</p>
          </div>
        </div>
        <div class="col-md-6 form-group" *ngIf="checkForThailandCountry()">
          <div>
            <p class="mb-1 heading ">{{ "HOME_OFFICE_NUMBER" | translate }}</p>
            <span class="font-weight-bold">
              <span *ngIf="element?.officePhoneNumber; else officeNumber" >
                +{{element?.officeCountryCode}} {{element?.officePhoneNumber}}
              </span>
              <ng-template #officeNumber>-</ng-template>
            </span>
          </div>
        </div>
        <div class="col-md-6 form-group">
          <div>
            <p class="mb-1 heading">{{ "EMAIL_ID" | translate }}
              <span class="text-danger">*</span>
            </p>
            <p class="font-weight-bold">{{element?.personalDetails?.email}}</p>
          </div>
        </div>
      </div>
      <hr>
      <div class="row" *ngIf="!checkForThailandCountry() &&  !checkForIsRegistrationInPh()">
        <div class="col-md-6 form-group">
          <div>
            <p class="mb-1 heading">{{ "GENDER" | translate }}
              <span class="text-danger" *ngIf="!checkForIsPhilippineCountry()">*</span>
            </p>
            <p class="font-weight-bold" *ngIf="element?.personalDetails?.gender">{{element?.personalDetails?.gender}}</p>
            <p class="font-weight-bold" *ngIf="!element?.personalDetails?.gender">-</p>
          </div>
        </div>
        <div class="col-md-6 form-group" *ngIf="!checkForIsRegistrationInPh()">
          <div>
            <p class="mb-1 heading">{{ "EDUCATIONAL_DEGREE" | translate }}
              <span class="text-danger" *ngIf="!checkForIsPhilippineCountry()">*</span>
            </p>
            <p class="font-weight-bold" *ngIf="element?.educationDetails?.name">{{element?.educationDetails?.name}}</p>
            <p class="font-weight-bold" *ngIf="!element?.educationDetails?.name">-</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-group" *ngIf="checkForThailandCountry() && !checkForIsRegistrationInPh()">
          <div>
            <p class="mb-1 heading">{{ "HOSPITAL_AFFILIATION" | translate }}
            </p>
            <span class="font-weight-bold" *ngFor="let hospital of element?.hospitalIds; let i = index; let isLast = last">
              <ng-container *ngIf="i < 2; else remainingNames">{{ hospital?.name }}{{ !last ? ', ' : '' }}</ng-container>
                <ng-template #remainingNames>
                  <ng-container *ngIf="i === 2">
                    + {{ element?.hospitalIds.length - 2 }} more
                  </ng-container>
                </ng-template>
            </span>
          </div>
        </div>
        <div class="col-md-6 form-group order-md-1">
          <div>
            <p class="mb-1 heading">{{ "SPECIALITY" | translate }}
              <span class="text-danger">*</span>
            </p>
            <p class="font-weight-bold">{{speciality}}</p>
          </div>
        </div>
        <div class="col-md-6 form-group" *ngIf="!checkForThailandCountry() && !checkForIsRegistrationInPh()">
          <div>
            <p class="mb-1 heading">{{ "HOSPITAL_AFFILIATION" | translate }}
            </p>
            <p class="font-weight-bold" *ngIf="element?.clinicDetails?.name">{{element?.clinicDetails?.name}}</p>
            <p class="font-weight-bold" *ngIf="!element?.clinicDetails?.name">-</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!checkForThailandCountry()">
        <div class="col-md-6 form-group order-md-0 order-1" >
          <div>
            <p class="mb-1 heading">{{ "PRC_NUMBER" | translate }}
              <span class="text-danger" *ngIf="!checkForIsRegistrationInPh()">*</span>
            </p>
            <p class="font-weight-bold">{{element?.registrationDetails?.number}}</p>
          </div>
        </div>
        <div class="col-md-6 form-group" *ngIf="!checkForIsRegistrationInPh()">
          <div>
            <p class="mb-1 heading">{{ "HOSPITAL_ADDRESS" | translate }}</p>
            <p class="font-weight-bold" *ngIf="element?.clinicDetails?.address">{{element?.clinicDetails?.address}}</p>
            <p class="font-weight-bold" *ngIf="!element?.clinicDetails?.address">-</p>
          </div>
        </div>
      </div>
      <div class="row" >
        <div class="col-md-6 form-group" *ngIf="!checkForThailandCountry() && !checkForIsRegistrationInPh()">
          <p class="mb-1 heading">{{ "DOCTOR_VIEW_PRC" | translate }} <span class="text-danger" *ngIf="!checkForIsPhilippineCountry()">*</span></p>
          <div class=" d-flex w-100 patientIdCard mt-3">
            <div class="patientIdCard__image w-100">
              <div class="position-relative primeGallary text-center">
                <dngx-full-view-galaria [images]="DoctorIdCard" [circular]="false" [fullScreen]="true"
                  [showItemNavigators]="false" [showThumbnails]="false" [showZoomIcon]="true"
                  zoomIcon="assets/images/zoom-icon-white.svg" maskClass="bg-black-50">
                </dngx-full-view-galaria>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 form-group" *ngIf="!checkForIsRegistrationInPh()">
          <p class="mb-1 heading">{{ "DIGITAL_SIGN" | translate }}</p>
          <div class=" d-flex w-100 patientIdCard mt-3">
            <div class="patientIdCard__image w-100">
              <div class="position-relative primeGallary text-center">
                <dngx-full-view-galaria [images]="DoctorSignature" [circular]="false" [fullScreen]="true"
                  [showItemNavigators]="false" [showThumbnails]="false" [showZoomIcon]="true"
                  zoomIcon="assets/images/zoom-icon-white.svg" maskClass="bg-black-50">
                </dngx-full-view-galaria>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row" *ngIf="checkForThailandCountry()">
        <div class="col-12">
          <h4>
            {{'PERSONAL_ASSISTANT_DETAILS' | translate}}
          </h4>
        </div>
        <div class="col-md-6 form-group">
          <p class="mb-1 heading">{{ "FULL_NAME" | translate }}
          </p>
          <p class="font-weight-bold">{{element?.personalAssistant?.firstName}} {{element?.personalAssistant?.lastName}}
            <span *ngIf="!element?.personalAssistant?.firstName">-</span>
          </p>
        </div>
        <div class="col-md-6 form-group">
          <div>
            <p class="mb-1 heading ">{{ "MOBILE_NUMBER" | translate }}
            </p>
            <p class="font-weight-bold" *ngIf="element?.personalAssistant?.phoneNumber">+{{element?.personalAssistant?.countryCode}}
              {{element?.personalAssistant?.phoneNumber}}
            </p>
            <p class="font-weight-bold" *ngIf="!element?.personalAssistant?.phoneNumber">
              -
            </p>
          </div>
        </div>
        <div class="col-md-6 form-group">
          <div>
            <p class="mb-1 heading">{{ "EMAIL_ID" | translate }}
            </p>
            <p class="font-weight-bold">{{element?.personalAssistant?.email}}
              <span *ngIf="!element?.personalAssistant?.email">-</span>
            </p>
          </div>
        </div>
      </div>
      <hr *ngIf="checkForThailandCountry()">
      <div class="row">
        <h4 *ngIf="checkForThailandCountry()" class="ml-3">{{'PROGRAM_DETAILS' | translate}}</h4>
        <div class="col-md-12 form-group">
          <p class="mb-1 heading">{{ "SELECT_PAP" | translate }}<span class="text-danger">*</span></p>
          <div [hidden]="!this.doctorExistWithPharma">
            <div *ngFor="let papPrograms of element?.enrolledPapDetails; let i = index" class="font-weight-bold">
              {{papPrograms.name}}
            </div>
          </div>
          <div *ngIf="(!this.doctorExistWithPharma && programData?.length > 0 && this.phamaCode !== null) && !findDoctor">
            <form [formGroup]="papPrograms" (ngSubmit)="this.submitForm()">
              <div class="px-2 py-2 select-pap">
                <div class="pl-4">
                  <div class="row">
                    <div class="col-md-4 pb-2 custom-checkbox" *ngFor="let papPrograms of programData; let i = index">
                      {{papPrograms.Program.name}}
                      <input id="selectPap{{papPrograms.Program.Id}}" value="{{papPrograms.Program.Id}}" type="checkbox"
                        (change)="onCheckChange($event)" formArrayName="papIds" name="papIds"
                        class="custom-control-input doctor-consent" />
                      <label for="selectPap{{papPrograms.Program.Id}}"
                        class="fnt-size14px custom-control-label cursor-pointer pt-1">
                        {{papPrograms.Program.Name}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group px-2">
                <p class="mb-2 font-weight-bold">Doctor's Consent <span class="text-danger">*</span></p>
                <div class="d-flex">
                  <div class="mr-2">
                    <input type="checkbox" class="doctor-consent" formControlName="consent" required />
                  </div>
                  <div class="form-group px-2">
                    <label>
                      <span>
                        {{"TERMS_AND_CONDITION" | translate}}
                      </span>
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <span class="fnt_Medium">{{"NOTE" | translate}}:</span>{{"REGISTRATION_CONFIRM" | translate}}
                </div>
              </div>
              <div class="termsList form-group px-2">
                <p class="fnt-size14px mb-3 font-weight-bold">{{"DOCTOR_REG_CONFIM" | translate}}
                </p>
                <div *ngIf="pharmaCode;else other_content" class="terms p-1 pl-3 text-break "
                  [innerHTML]="pharmaDetail.doctorConsent">
                </div>
                <ng-template #other_content>
                  <ul class="fnt-size12px pl-3">
                    <li>
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_ONE" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_TWO" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_THREE" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_FOUR" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_FIVE" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">{{"REG_TERM_SIX" | translate}}</span>
                    </li>
                    <li class="">
                      <span class="elementor-icon-list-icon">
                        <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                      <span class="elementor-icon-list-text">I have read and understood the <i><u><a target="_blank"
                              href="{{novartisPrivacy}}">{{"NOVARTIS_PRIVACY" |
                              translate}}</a></u></i>{{"NOVARTIS_PRIVACY_NOTICE" | translate}}</span>
                    </li>
                  </ul>
                </ng-template>
              </div>
              <div class=" form-group text-center">
                <button class="btn btn-success fnt-size14px px-5 py-2" [disabled]="!papPrograms.valid">
                  <span class="px-5" *ngIf="!this.isExistingUser">{{"UPDATE_PROFILE" | translate}}</span>
                </button>
              </div>
              <div class="border rounded p-3 mt-3 px-2">
                <div class="form-group mt-2">
                  <div class="termsList">
                    <p class="fnt-size14px mb-3 font-weight-bold">{{"DISCLAIMER" | translate}}
                    </p>
                    <ul class="fnt-size12px pl-3">
                      <li>
                        <span class="elementor-icon-list-icon">
                          <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                        <span class="elementor-icon-list-text">{{"DISCLAIMER_TERM_ONE" | translate}}</span>
                      </li>
                      <li class="">
                        <span class="elementor-icon-list-icon">
                          <i aria-hidden="true" class="far fa-check-circle"></i> </span>
                        <span class="elementor-icon-list-text">{{"DISCLAIMER_TERM_TWO" | translate}}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="programData?.length === 0 && this.pharmaCode !== null && !this.doctorExistWithPharma">
            <div class="row" class="empty-pap text-center pb-2">
              <img src="assets/images/no-pap.png" class="my-3" alt="empty">
              <p>{{"EMPTY_PAP" | translate}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-dialog [(visible)]="displayModal" [closeOnEscape]="false" styleClass="successful-edit" [closable]="false">
    <ng-template pTemplate="header">
      <img src="assets/images/success.svg" class="mx-auto mt-5" alt="success">
    </ng-template>
    <p class="mb-5 px-5  font-weight-bold text-center">{{"PROFILE_UPDATE" | translate}}</p>
  </p-dialog>
