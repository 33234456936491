/**
 * Constants import.
 */
import {
  BLOCK_TYPE, CHATBOT_CONSTANTS, HYBRID_STATES, LOCAL_STORAGE_COUNTRY,
  APPLICATION_TYPE, FIELD_TYPE, ERROR_CODE, APPROVAL_LIST, PATTERN, COUNTRIES_LIST,
  UN_EMBEDDED_PHARMA, OAZIS_FLOW_PROCESS, LANGUAGES
} from './../../../constant/app.constant';
import { API } from '../../../constant/api.constant';
/**
 * Angular Imports
 */
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewChecked, Input } from '@angular/core';
/**
 * Models imports.
 */
import { SignupChatbot, Country, Message, CareGiverDeleteRequest, HospitalList, Hospital } from '../../../models/sign-up-chatbot.model';
/**
 * Ngrx store imports.
 */
import { Store } from '@ngrx/store';
import * as HybridAction from '../../../store/actions/hybrid/hybrid.actions';
import * as SignupAction from '../../../store/actions/signup/signup.actions';
import * as HybridSelector from '../../../store/selectors/hybrid/hybrid.selectors';
import * as SignupSelector from '../../../store/selectors/signup/signup.selectors';
/**
 * Rxjs import.
 */
import { Subscription, of, timer } from 'rxjs';
/**
 * Router imports.
 */
import { ActivatedRoute, Router } from '@angular/router';
/**
 * Jwt import.
 */
import { JwtHelperService } from '@auth0/angular-jwt';
/*
 * Service Imports.
 */
import { TranslateService } from '@ngx-translate/core';

/*
 * component imports.
 */
import { PageNotFoundComponent } from '../../page-not-found/page-not-found.component';
@Component({
  selector: 'pap-hybrid',
  templateUrl: './hybrid.component.html',
  styleUrls: ['./hybrid.component.scss']
})
export class HybridComponent implements OnInit, OnDestroy, AfterViewChecked {

  /**
   * chat bot view on pharma flag
   */
  @Input() chatView = false;
  /**
   * pharma code input
   */
  @Input() pharmaCode: string;
  /**
   * Auto scroll.
   */
  @ViewChild('scrollHybrid') private myScrollContainer: ElementRef;
  /**
   * var for approved status for patient.
   */
  public isApproved = false;
  /**
   * var for application status.
   */
  public applicationStatus: string;
  /**
   * Storing Pap id.
   */
  public papId: number;
  /**
   * From Url Pap id.
   */
  public urlPapId: number;
  /**
   * Pap Program process.
   */
  public papProcess: string;
  /**
   * jwt access token.
   */
  public accessToken = '';
  /**
   * Chatbot chat blocks.
   */
  public chatBlocks: SignupChatbot;
  /**
   * Storing patient email.
   */
  public patientEmail: string;
  public languages = LANGUAGES;
  /**
   * Storing patient phone no.
   */
  public patientPhoneNo: string;
  /**
   * Storing patient token.
   */
  public patientToken = '';
  /**
   * Storing patient status.
   */
  public patientExists: boolean;
  /**
   * Storing patient country code.
   */
  public patientCountryCode: string;
  /**
   * Subscription for update consent.
   */
  public updateConsentSub: Subscription;
  /**
   * Subscription for Additional Info.
   */
  public additionalInfoSub: Subscription;

  public patientPrescriptionText: string;

  /**
   * Maintaining status.
   */
  public currentStatus = '';
  /**
   * Storing the block types and get it from constants file.
   */
  public blockType: object;
  /**
   * Time for docquity messages.
   */
  public docquityTime: Date[] = [];
  /**
   * Time for user messages.
   */
  public userTime: Date[] = [];
  /**
   * Docquity message type.
   */
  public docquityMessage = CHATBOT_CONSTANTS.DOCQUITY_MESSAGE;
  /**
   * User message type.
   */
  public userMessage = CHATBOT_CONSTANTS.USER_MESSAGE;
  /**
   * Subscription for hybrid form api.
   */
  public hybridFormsSub: Subscription;
  /**
   * store the option selected by user for caregiver
   */
  public selectedOption: string;
  /**
   * forms received from api.
   */
  public hybridForms: Message[];
  /**
   * Map for storing states of chatbot.
   */
  public formMap: Map<string, Message>;
  /**
   * This is used to hold countries data.
   */
  public countries: Country[];
  /**
   * Countries subscription.
   */
  public countriesSub: Subscription;
  /**
   * query parameters subscription.
   */
  public queryParametersSub: Subscription;
  /**
   * Subscription for credentials.
   */
  public credSub: Subscription;
  /**
   * Managing chatbot for patient submit data in case of conflicted email or phone number..
   */
  public loginError: Subscription;
  /**
   * subscription queryparams.
   */
  public queryParamsCheck: Subscription;
  /**
   * Flag for new chatbot messages.
   */
  public newMessages = false;
  /**
   * Storing patient name.
   */
  public patientName: string;
  /**
   * flag for managing edit functionality.
   */
  public editMode = false;
  /**
   * var for holding system time.
   */
  public systemTime: number;
  /**
   * var for storing reject reasons.
   */
  public rejectReasons: string[];
  /**
   * var for storing program name.
   */
  public programName: string;
  /**
   * var for counting edit.
   */
  public editCounter = 0;
  /**
   * subscription for session timeout.
   */
  public sessionTimeOutSub: Subscription;
  /**
   * submission ongoing.
   */
  public isSubmissionOngoing = false;
  public caregiverDeleteRequest: CareGiverDeleteRequest;
  public careGiverName: string;
  public patientAlreadyExits: string;
  public showAdditionalInfo: number;
  public maxConsentDate = new Date();
  public defaultConsentDate: Date;
  public consentDateValidation: boolean;
  public selectedConsentDate: number;
  public selectedCountry: string;
  public consentCalendarValidation: boolean;
  public hospitalList: any[] = [];
  public phoneNumberVerified: boolean;

  @Input() process = '';
  public unEmbeddedPharma = UN_EMBEDDED_PHARMA;
  private pharmaSubs: Subscription;

  /**
   * Time update for particular message.
   */
  currentTime(index: number, type: string): boolean {
    if (type === this.docquityMessage && this.docquityTime[index] === undefined) {
      this.docquityTime[index] = new Date();
    } else if (type === this.userMessage && this.userTime[index] === undefined) {
      this.userTime[index] = new Date();
    }
    return true;
  }
  /**
   * Function for scrolling.
   */
  scrollToBottom(): void {
    try {
      if (this.checkForThailandCountry()) {
        window.scrollTo(0, document.body.scrollHeight);
      }
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }
  /**
   * Filling value in chatblock.
   */
  fillValueInBlock(
    formType: string, isSubmitted: boolean, submitTextLanguageKey: string, responseLanguageKey: string): void {
    const block = this.chatBlocks.block.slice().reverse().find(ele => ele.formType === formType);
    block.submitted = isSubmitted;
    block.submitText = this.translate.instant(submitTextLanguageKey);
    block.response = this.translate.instant(responseLanguageKey);
  }
  /**
   *
   */
  public updateForm(data, block: Message): void {
    this.patientName = '';
    for (const [key, value] of Object.entries(data.formData)) {
      const attribute = block.attributes.find(ele => ele.fieldName === key);
      if (attribute) {
        if (attribute.fieldName === CHATBOT_CONSTANTS.FIRST_NAME) {
          this.patientName += String(value);
        } else if (attribute.fieldName === CHATBOT_CONSTANTS.LAST_NAME) {
          this.patientName += ' ' + String(value);
        }
        if (typeof value === 'object' && value !== null) {
          attribute.value = JSON.stringify(value);
        } else {
          attribute.value = String(value);
        }
      }
    }
  }
  /**
   * Managing chatbot for patient submit data.
   */
  submitPatientData(data): void {
    if (data.consentToken !== '') {
      this.patientToken = data.consentToken;
    }
    if (data.accessToken !== '') {
      this.accessToken = data.accessToken;
    }
    switch (data.formType) {
      case HYBRID_STATES.LOGIN: {
        this.sessionTimeOut(this.systemTime, data.jwtTokenExpire);
        this.fillValueInBlock(HYBRID_STATES.LOGIN, true, 'LETS_GET_STARTED', 'LETS_GET_STARTED');
        break;
      }
      case HYBRID_STATES.PROGRAM_INFO: {
        this.fillValueInBlock(HYBRID_STATES.PROGRAM_INFO, true, 'SUBMIT_PROCEED', 'SUBMIT_PROCEED');
        this.papId = data.formData.pap;
        const consents: string[] = [];
        const patientConsents = JSON.parse(data.formData.patientConsents);
        for (const key of Object.keys(patientConsents)) {
          if (key === 'Meta') {
            continue;
          }
          for (const val of patientConsents[key]) {
            const generalConsent = JSON.parse(val);
            for (const generalConsentKey of Object.keys(generalConsent)) {
              for (const value of generalConsent[generalConsentKey]) {
                if (value !== '') {
                  consents.push(value);
                }
              }
            }
          }
        }
        const consentBlock = this.formMap.get(HYBRID_STATES.PATIENT_CONSENT);
        consentBlock.attributes = [];
        for (const val of consents) {
          consentBlock.attributes.push({ title: val, type: 'CHECKBOX', mandatory: true, img: 'checkbox' });
        }
        break;
      }
      case HYBRID_STATES.PRESCRIPTION_INFO: {
        const block = this.formMap.get(HYBRID_STATES.PRESCRIPTION_INFO);
        this.updateForm(data, block);
        if (this.editMode) {
          this.fillValueInBlock(HYBRID_STATES.PRESCRIPTION_INFO, true, 'SAVE', 'YOUR_PRESCRIPTION_HAVE_BEEN_UPDATED');
          if (this.isApproved) {
            this.currentStatus = HYBRID_STATES.PRESCITPTION_APPROVED_SEQUENTIAL_SUCCESS;
          }
        } else {
          this.fillValueInBlock(HYBRID_STATES.PRESCRIPTION_INFO, true, 'SAVE_PROCEED', 'SAVE_PROCEED');
        }
        break;
      }
      case HYBRID_STATES.ADDITIONAL_INFO: {
        const block = this.formMap.get(HYBRID_STATES.ADDITIONAL_INFO);
        this.updateForm(data, block);
        if (this.editMode) {
          this.fillValueInBlock(HYBRID_STATES.ADDITIONAL_INFO, true,
            'SAVE', 'YOUR_ADD_INFO_HAVE_BEEN_UPDATED');
        } else {
          this.fillValueInBlock(HYBRID_STATES.ADDITIONAL_INFO, true, 'SAVE_PROCEED', 'SAVE_PROCEED');
        }
        break;
      }
      case HYBRID_STATES.PATIENT_INFO_FORM: {
        const block = this.formMap.get(HYBRID_STATES.PATIENT_INFO_FORM);
        this.updateForm(data, block);
        if (this.editMode) {
          this.fillValueInBlock(HYBRID_STATES.PATIENT_INFO_FORM, true,
            'SAVE', 'YOUR_PATIENT_DETAILS_HAVE_BEEN_UPDATED');
        } else {
          this.fillValueInBlock(HYBRID_STATES.PATIENT_INFO_FORM, true, 'SAVE_PROCEED', 'SAVE_PROCEED');
        }
        break;
      }
      case HYBRID_STATES.FILE_UPLOAD_FORM: {
        const block = this.formMap.get(HYBRID_STATES.FILE_UPLOAD_FORM);
        this.updateForm(data, block);
        if (this.editMode) {
          this.fillValueInBlock(HYBRID_STATES.FILE_UPLOAD_FORM, true, 'SAVE', 'YOUR_ID_CARD_HAVE_BEEN_UPDATED');
        } else {
          this.fillValueInBlock(HYBRID_STATES.FILE_UPLOAD_FORM, true, 'SAVE_APPLICATION', 'SAVE_APPLICATION');
        }
        break;
      }
      case HYBRID_STATES.CARETAKER_INFO_FORM: {
        const block = this.formMap.get(HYBRID_STATES.CARETAKER_INFO_FORM);
        this.updateForm(data, block);
        if (this.editMode) {
          this.fillValueInBlock(HYBRID_STATES.CARETAKER_INFO_FORM, true, 'SAVE', 'YOUR_CAREGIVER_HAVE_BEEN_UPDATED');
        } else {
          this.fillValueInBlock(HYBRID_STATES.CARETAKER_INFO_FORM, true, 'SAVE_CAREGIVER', 'SAVE_CAREGIVER');
        }
        break;
      }
      case HYBRID_STATES.ORDER_INFO_FORM: {
        const block = this.formMap.get(HYBRID_STATES.ORDER_INFO_FORM);
        this.updateForm(data, block);
        if (this.editMode) {
          this.fillValueInBlock(HYBRID_STATES.ORDER_INFO_FORM, true, 'SAVE', 'YOUR_ORDER_HAVE_BEEN_UPDATED');
          if (this.isApproved) {
            this.currentStatus = HYBRID_STATES.PRESCITPTION_APPROVED_SEQUENTIAL_SUCCESS;
          }
        } else {
          this.fillValueInBlock(HYBRID_STATES.ORDER_INFO_FORM, true, 'SAVE_PROCEED', 'SAVE_PROCEED');
        }
        break;
      }
      case HYBRID_STATES.TREATMENT_INFO_FORM: {
        const block = this.formMap.get(HYBRID_STATES.TREATMENT_INFO_FORM);
        this.updateForm(data, block);
        if (this.editMode) {
          this.fillValueInBlock(HYBRID_STATES.TREATMENT_INFO_FORM, true, 'SAVE', 'YOUR_TREATMENT_HAVE_BEEN_UPDATED');
        } else {
          this.fillValueInBlock(HYBRID_STATES.TREATMENT_INFO_FORM, true, 'SAVE_PROCEED', 'SAVE_PROCEED');
        }
        break;
      }
      case HYBRID_STATES.PATIENT_VERIFY_OTP: {
        const block = this.formMap.get(HYBRID_STATES.PATIENT_VERIFY_OTP);
        this.updateForm(data, block);
        this.fillValueInBlock(HYBRID_STATES.PATIENT_VERIFY_OTP, true, 'VERIFY_OTP', 'VERIFY_OTP');
        break;
      }
      case HYBRID_STATES.PATIENT_RESEND_OTP: {
        const block = this.formMap.get(HYBRID_STATES.PATIENT_RESEND_OTP);
        this.updateForm(data, block);
        this.fillValueInBlock(HYBRID_STATES.PATIENT_RESEND_OTP, true, 'RESEND_OTP', 'RESEND_OTP');
        break;
      }
      default: {
        break;
      }
    }

    this.manage();
    return;
  }
  /**
   * linkify the consents
   */
  convertToUrl(text: string): string {
    return text.replace(PATTERN.URL_REGEX, url => {
      return '<a href="' + url + '" target="_blank">  ' + url + '</a>';
    });
  }
  /**
   * setting blocks for conflicted email or phone number and dynamically rendering patient's email and phone number.
   */
  conflictedemailphonecreds(email: string, phone: string, countryCode: string): void {
    this.formMap.set(HYBRID_STATES.EMAIL_EXIST, {
      type: BLOCK_TYPE.PHONE_EMAIL_MISMATCH,
      content:
        `<p class="mb-3">${this.translate.instant('HELLO_MSG')}</p>
       <p class="mb-3">${!this.checkForThailandCountry() ? this.translate.instant('EMAIL_EXIST', { EMAIL_ID: email }) : this.translate.instant('EMAIL_EXIST_TH', { EMAIL_ID: email })}</p>
       <p>${this.process === this.unEmbeddedPharma ? (this.translate.instant('CHANGE_PHONE_V1', { COUNTRY_CODE: countryCode, PHONE_NO: phone })) : (!this.checkForThailandCountry() ? this.translate.instant('CHANGE_PHONE', { COUNTRY_CODE: countryCode, PHONE_NO: phone }) : this.translate.instant('CHANGE_PHONE_TH', { COUNTRY_CODE: countryCode, PHONE_NO: phone }))}</p>
       <P class= "mb-2">${!this.checkForThailandCountry() ? this.translate.instant('TIME_CONTACT') : ''}</p>
       <P>${!this.checkForThailandCountry() ? this.translate.instant('CONTINUE_PROCESS') : ''}</p>`
    });
    this.formMap.set(HYBRID_STATES.PHONE_EXIST, {
      type: BLOCK_TYPE.PHONE_EMAIL_MISMATCH,
      content:
        `<p class="mb-3">${this.translate.instant('HELLO_MSG')}</p>
       <p class="mb-3">${!this.checkForThailandCountry() ? this.translate.instant('PHONE_EXIST', { COUNTRY_CODE: countryCode, PHONE_NO: phone }) : this.translate.instant('PHONE_EXIST_TH', { COUNTRY_CODE: countryCode, PHONE_NO: phone })}</p>
       <p>${this.process === this.unEmbeddedPharma ? (this.translate.instant('CHANGE_EMAIL_V1', { EMAIL_ID: email })) : (!this.checkForThailandCountry() ? this.translate.instant('CHANGE_EMAIL', { EMAIL_ID: email }) : this.translate.instant('CHANGE_EMAIL_TH', { EMAIL_ID: email }))}</p>
       <P class= "mb-2">${!this.checkForThailandCountry() ? this.translate.instant('TIME_CONTACT') : ''}</p>
       <P>${!this.checkForThailandCountry() ? this.translate.instant('CONTINUE_PROCESS') : ''}</p>`
    });
  }
  /**
   * set patient email and phone
   */
  submitError(data: any): void {
    this.patientEmail = data.formData.email;
    this.patientPhoneNo = data.formData.phone;
    this.patientCountryCode = data.formData.countryCode;
  }
  /**
   * refreshPage method to remove token from local storage  and refesh the page to start the whole process again
   */
  refreshPage(): void {
    localStorage.removeItem('patientAuth');
    this.patientEmail = undefined;
    this.patientPhoneNo = undefined;
    if (this.chatView) {
      this.refreshComponent();
    } else {
      window.location.reload();
    }
  }
  /**
   * function to refesh page
   */
  pageReload(): void {
    if (this.chatView) {
      this.refreshComponent();
    } else {
      window.location.reload();
    }
  }
  /**
   * function used to show the edit details.
   */
  editDetails(): void {
    this.editMode = true;
    this.editMode = true;
    this.currentStatus = HYBRID_STATES.EDIT_ACTIVE;
    const block = this.formMap.get(HYBRID_STATES.EDIT_BUTTON);
    block.submitted = true;
    block.submitText = this.translate.instant('YES_EDIT');
    block.response = block.submitText;

    this.manage();
  }
  /**
   * givePatientConsent is used for submitting patient consent.
   */
  givePatientConsent(currentBlock: Message, index: number): void {
    if (!this.isSubmissionOngoing) {
      this.isSubmissionOngoing = true;
      const consentForm: any = {
        endPoint: currentBlock.apiEndPoints.submit,
        method: currentBlock.apiEndPoints.submitMethod,
        accessToken: this.accessToken,
        applicationType: 'HYBRID',
        formData: {
          pap: this.papId
        }
      };
      if (this.checkForThailandCountry()) {
        consentForm.formData.dateOfConsent = this.selectedConsentDate;
      }
      this.store.dispatch(HybridAction.submitProgramData(consentForm));
      /**
       * Update signup consent.
       */
      this.updateConsentSub = this.store.select(HybridSelector.getConsentToken).subscribe(data => {
        if (data !== null) {
          this.isSubmissionOngoing = false;
          this.patientToken = data.consentToken;
          this.fillValueInBlock(HYBRID_STATES.PATIENT_CONSENT, true, 'ACCEPT_PROCEED', 'ACCEPT_PROCEED');
          this.showAdditionalInfo = data.showAdditionalInfo;

          this.manage();
        }
      });
    }
  }

  uploadPrescriptionEvent(chatblockIndex: number): void {
    this.editMode = true;
    const block = this.chatBlocks.block[chatblockIndex];
    block.submitted = true;
    block.submitText = this.isApproved ? this.translate.instant('UPLOAD_PRESCRIPTION') : this.translate.instant('EDIT_PRESCRIPTION');
    block.response = block.submitText;
    const prescriptionBlock = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.PRESCRIPTION_INFO)));
    prescriptionBlock.submitText = this.translate.instant('SAVE');
    prescriptionBlock.submitted = false;
    this.chatBlocks.block.push(prescriptionBlock);
    this.newMessages = true;
    this.currentStatus =
      this.isApproved ? HYBRID_STATES.END : HYBRID_STATES.EDIT_ACTIVE;
    this.editCounter++;
  }

  uploadOrderForm(chatblockIndex): void {
    this.editMode = true;
    const block = this.chatBlocks.block[chatblockIndex];
    block.submitted = true;
    block.submitText = this.isApproved ? this.translate.instant('UPLOAD_ORDER_FORM') : this.translate.instant('EDIT_ORDER_DETAIL');
    block.response = block.submitText;
    const orderBlock = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.ORDER_INFO_FORM)));
    orderBlock.submitText = this.translate.instant('SAVE');
    orderBlock.submitted = false;
    this.chatBlocks.block.push(orderBlock);
    this.newMessages = true;
    this.currentStatus =
      this.isApproved ? HYBRID_STATES.END : HYBRID_STATES.EDIT_ACTIVE;
    this.editCounter++;
  }

  editPatientDetails(chatblockIndex: number): void {
    this.editMode = true;
    const block = this.chatBlocks.block[chatblockIndex];
    block.submitted = true;
    block.submitText = this.translate.instant('EDIT_PATIENT_DETAILS');
    block.response = block.submitText;
    const patientBlock = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.PATIENT_INFO_FORM)));
    patientBlock.submitText = this.translate.instant('SAVE');
    patientBlock.response = this.translate.instant('YOUR_PATIENT_DETAILS_HAVE_BEEN_UPDATED');
    patientBlock.submitted = false;
    this.newMessages = true;
    if (!this.checkForThailandCountry() && !this.checkForOazisProcess()) {
      patientBlock.title = patientBlock.title + `<div  class="fnt-size12px fnt_Medium bg-porcelain-white mt-1">
      <p class="fnt_Regular p-2">${this.translate.instant('CHANGE_CREDENTIALS')}</p>
      <div class="d-flex">
      <img src="assets/images/call_docquity.svg" alt="call" class="p-2">
      <div class="pt-2">
      <span class="mt-1">${this.translate.instant('CONTACT_DOCQUITY_VIA_PHONE')}</span>
      </div>
      </div>
      <div class="d-flex mt-2">
        <img src="assets/images/mail_docquity.svg" alt="mail" class="px-2 mb-5">
        <div>
          <span class="pt-2">${this.translate.instant('DOCQUITY_MAIL')}</span>
          <p class="mt-2 fnt_Regular">${this.translate.instant('CONTACT_TIME')}</p>
        </div>
      </div>
      </div>`;
    }
    this.chatBlocks.block.push(patientBlock);
    this.currentStatus = HYBRID_STATES.EDIT_ACTIVE;
    this.editCounter++;
  }

  editOrderDetails(chatblockIndex: number): void {
    this.editMode = true;
    const block = this.chatBlocks.block[chatblockIndex];
    block.submitted = true;
    block.submitText = this.translate.instant('EDIT_ORDER_DETAIL');
    block.response = block.submitText;
    const patientBlock = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.ORDER_INFO_FORM)));
    patientBlock.submitText = this.translate.instant('SAVE');
    patientBlock.response = this.translate.instant('YOUR_ORDER_DETAILS_HAVE_BEEN_UPDATED');
    patientBlock.submitted = false;
    this.newMessages = true;
    patientBlock.title = patientBlock.title;
    this.chatBlocks.block.push(patientBlock);
    this.currentStatus = HYBRID_STATES.EDIT_ACTIVE;
    this.editCounter++;
  }

  editTreatmentDetails(chatblockIndex: number): void {
    this.store.dispatch(SignupAction.loadHospitals({ token: this.patientToken }));
    this.editMode = true;
    const block = this.chatBlocks.block[chatblockIndex];
    block.submitted = true;
    block.submitText = this.translate.instant('EDIT_TREATMENT_DETAIL');
    block.response = block.submitText;
    const patientBlock = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.TREATMENT_INFO_FORM)));
    patientBlock.submitText = this.translate.instant('SAVE');
    patientBlock.response = this.translate.instant('YOUR_TREATMENT_DETAILS_HAVE_BEEN_UPDATED');
    patientBlock.submitted = false;
    this.newMessages = true;
    patientBlock.title = patientBlock.title;
    this.chatBlocks.block.push(patientBlock);
    this.currentStatus = HYBRID_STATES.EDIT_ACTIVE;
    this.editCounter++;
  }

  /**
   * function for patient id card edit.
   */
  editPatientIdCard(chatblockIndex: number): void {
    this.editMode = true;
    const block = this.chatBlocks.block[chatblockIndex];
    block.submitted = true;
    block.submitText = this.translate.instant('EDIT_ID_CARD');
    block.response = block.submitText;
    const idcardBlock = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.FILE_UPLOAD_FORM)));
    idcardBlock.submitText = this.translate.instant('SAVE');
    idcardBlock.submitted = false;
    this.chatBlocks.block.push(idcardBlock);
    this.newMessages = true;
    this.currentStatus = HYBRID_STATES.EDIT_ACTIVE;
    this.editCounter++;
  }

  /**
   * function for patient caregiver detail edit.
   */
  editCareGiver(chatblockIndex: number): void {
    this.store.dispatch(HybridAction.resetCaregiver({}));
    this.store.dispatch(HybridAction.resetErrors({}));
    this.editMode = true;
    const block = this.chatBlocks.block[chatblockIndex];
    block.submitted = true;
    block.submitText = this.translate.instant('UPDATE_CAREGIVER');
    block.response = block.submitText;
    const caretakerBlock: Message = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.CARETAKER_INFO_FORM)));
    caretakerBlock.patientAlreadyExistsErr = undefined;
    if (this.formMap.get(HYBRID_STATES.CARETAKER_INFO_FORM).attributes.find(val => val.fieldName === 'email').value) {
      caretakerBlock.isCareGiverExist = true;
    }
    caretakerBlock.submitText = this.translate.instant('SAVE');
    caretakerBlock.submitted = false;
    this.chatBlocks.block.push(caretakerBlock);
    this.newMessages = true;
    this.currentStatus = HYBRID_STATES.EDIT_ACTIVE;
    this.editCounter++;
  }

  editAdditionalCard(chatblockIndex: number): void {
    this.editMode = true;
    const block = this.chatBlocks.block[chatblockIndex];
    block.submitted = true;
    block.submitText = this.translate.instant('ADDITIONAL_INFO');
    block.response = block.submitText;
    const additionalBlock = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.ADDITIONAL_INFO)));
    additionalBlock.submitText = this.translate.instant('SAVE');
    additionalBlock.submitted = false;
    this.chatBlocks.block.push(additionalBlock);
    this.newMessages = true;
    this.currentStatus =
      this.isApproved ? HYBRID_STATES.END : HYBRID_STATES.EDIT_ACTIVE;
    this.editCounter++;
  }
  /**
   * function for no futher update.
   */
  noFurtherUpdate(chatblockIndex: number): void {
    this.currentStatus = HYBRID_STATES.EDIT_SUCCESS;
    const block = this.chatBlocks.block[chatblockIndex];
    block.submitted = true;
    block.submitText = this.translate.instant('NO_FURTHER_UPDATES');
    block.response = block.submitText;
    this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.EDIT_SUCCESS));
    this.newMessages = true;
    this.currentStatus = HYBRID_STATES.END;
    if (this.checkForThailandCountry() && localStorage.getItem('patientAuth')) {
      localStorage.removeItem('patientAuth');
    }
  }

  /**
   * function for handling caregiver optional detail.
   */
  careGiverOption(option: boolean): void {
    const block = this.chatBlocks.block.find(ele => ele.type === HYBRID_STATES.OPTION);
    block.submitted = true;
    if (option) {
      this.selectedOption = this.translate.instant('YES_ADD_CAREGIVER');

      this.manage();
    } else {
      this.selectedOption = this.translate.instant('NO_ADD_CAREGIVER');
      this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.EDIT_BUTTON));
      this.newMessages = true;
    }
  }

  addBlockAndSetStatus(formType: string): void {
    this.chatBlocks.block.push(this.formMap.get(formType));
    this.newMessages = true;
    this.currentStatus = formType;
  }

  /**
   * reload component when session expired.
   */
  sessionTimeOut(sysTime: number, expTime: number): void {
    this.sessionTimeOutSub = timer((expTime - sysTime) * 1000).subscribe(res => {
      window.location.reload();
    });
  }

  ngOnInit(): void {
    this.queryParametersSub = this.route.queryParams.subscribe(params => {
      if (params.country) {
        localStorage.setItem(LOCAL_STORAGE_COUNTRY.COUNTRY, params.country);
      }
    });
    if (this.checkForThailandCountry()) {
      this.selectedCountry = localStorage.getItem('country') ? localStorage.getItem('country') : null;
    }
    this.store.dispatch(HybridAction.reSetHybridPharmas());
    if (this.router.url.indexOf('/patient/') > -1) {
      if (this.route.snapshot.firstChild) {
        this.pharmaCode = this.route.snapshot.firstChild.params.pharmaCode;
      }

      if (this.route.snapshot.queryParams.pap) {
        this.papId = this.route.snapshot.queryParams.pap;
      }
      this.getPapDetail();
    } else {
      if (sessionStorage.getItem('process') && this.process !== this.unEmbeddedPharma) {
        sessionStorage.removeItem('process');
      }
      sessionStorage.setItem('process', this.process);
      this.refreshComponent();
    }
    this.process = sessionStorage.getItem('process');
  }

  getPapDetail(): void {
    this.store.dispatch(HybridAction.loadHybridPharmas({ pharmaCode: this.pharmaCode }));
    this.pharmaSubs = this.store.select(HybridSelector.getHybridPharmaDetail).subscribe(data => {
      if (data) {
        if (data?.process) {
          sessionStorage.setItem('process', data?.process);
          this.process = sessionStorage.getItem('process');
        }
        if (this.papId) {
          for (const papProgram  of data?.pap) {
            if (papProgram?.id === this.papId ) {
              this.papProcess = papProgram?.papProcess;
              this.programName = papProgram?.name;

              sessionStorage.setItem('process', this.papProcess);
              this.process = sessionStorage.getItem('process');
              if ( !localStorage.getItem('lang') && this.checkForOazisProcess()) {
                this.translate.use('th');
                localStorage.setItem('lang', 'th');
                location.reload();
              }
            }
          }
        }
        this.refreshComponent();
      } else {
        if (sessionStorage.getItem('process')) {
          sessionStorage.removeItem('process');
        }
      }
    });
  }

  /**
   * Created a Hybrid form map.
   */

  generateFormMap(formType: string): void {
    switch (formType) {
      case APPLICATION_TYPE.AUTHORIZATION: {
        let contentText;
        if (this.process === this.unEmbeddedPharma) {
          contentText = `<span class="bg-white p-2 text-abbey-grey fnt-size12px medium-font-weight">
            <span class="InfoBlock">${this.translate.instant('WELCOME_TO_THE_PATIENT_ACCESS_PROGRAM')}</span>
            </span>
            <div class="bg-white mt-3 p-2 text-abbey-grey fnt-size12px medium-font-weight">
            <span class="InfoBlock">${this.translate.instant('ASSISTANCE_MSG')}</span>
            </div>
            `;
        } else if (this.checkForOazisProcess()) {
          contentText = `<span class="bg-white p-2 text-abbey-grey fnt-size12px medium-font-weight">
          <span class="InfoBlock">${this.translate.instant('WELCOME_TO_THE_OAZIS_CARE_PROGRAM')}</span>
          </span>`;
        } else {
          contentText =  `<span class="bg-white p-2 text-abbey-grey fnt-size12px medium-font-weight">
          <span class="InfoBlock">${this.translate.instant('WELCOME_TO_THE_PATIENT_ACCESS_PROGRAM')}</span>
          </span>`;
        }
        this.formMap.set(HYBRID_STATES.WELCOME_INFO, {
          type: BLOCK_TYPE.INFO,
          content: contentText
        });
        let loginTitle = '';
        const loginAttributes = [
          {
            index: 1,
            title: this.translate.instant('MOBILE_NUMBER'),
            type: FIELD_TYPE.PHONE,
            mandatory: true,
            placeHolder: this.translate.instant('ENTER_MOBILE_NUMBER'),
            fieldName: 'phone',
            value: this.patientPhoneNo,
            arrangementOrder: 1,
          },
          {
            index: 2,
            title: this.translate.instant('EMAIL_ID'),
            type: FIELD_TYPE.EMAIL,
            mandatory: true,
            placeHolder: this.translate.instant('ENTER_EMAIL_ID'),
            fieldName: 'email',
            value: this.patientEmail,
            arrangementOrder: 2,
          },
          {
            index: 3,
            title: this.translate.instant('COUNTRY_CODE'),
            type: FIELD_TYPE.SELECT,
            mandatory: true,
            placeHolder: this.translate.instant('ENTER_YOUR_COUNTRY_CODE'),
            fieldName: 'countryCode',
            value: this.patientCountryCode,
            arrangementOrder: 3,
          },
        ];

        if ( this.checkForOazisProcess()) {
          if (!loginAttributes[1].value) {
              loginAttributes[1].value = this.patientPhoneNo + '@docquityclinic.com';
          }
          loginTitle = this.translate.instant('BY_ENTERING_THE_MOBILE_NUMBER_V2');
          this.patientPrescriptionText = this.translate.instant('FORM_INFO_MESSAGE_REVIEW');
        } else {
          loginTitle = !this.checkForThailandCountry() ? this.translate.instant('BY_ENTERING_THE_MOBILE_NUMBER')
            : this.translate.instant('BY_ENTERING_THE_MOBILE_NUMBER_V1');
          this.patientPrescriptionText = !this.checkForThailandCountry() ?
            this.translate.instant('PATIENT_PRESCRIPTION_STATUS_INFO_MESSAGE')
            : this.translate.instant('ORDER_FORM_STATUS_INFO_MESSAGE');
        }

        this.formMap.set(HYBRID_STATES.LOGIN, {
          type: BLOCK_TYPE.FORM,
          formType: HYBRID_STATES.LOGIN,
          title: `<div>${this.translate.instant('PLEASE_PROVIDE_THE_FOLLOWING_DETAILS_TO_PROCEED_FURTHER')}.</div>
          <div class="row p-1"><div class="ml-2"><img src="assets/images/bi_info-circle.svg" alt="info icon"></div>
          <div class="col-10 text-rolling-stone-grey">${loginTitle}</div></div>`,
          attributes: loginAttributes,
          onSubmit: {
            isEditable: false
          },
          apiEndPoints: {
            submit: API.PATIENT_AUTH,
            submitMethod: 'POST'
          },
          submitText: this.translate.instant('LETS_GET_STARTED'),
          response: this.translate.instant('LETS_GET_STARTED')
        });

        this.formMap.set(HYBRID_STATES.PATIENT_PRESCRIPTION_STATUS, {
          type: BLOCK_TYPE.INFO,
          content:
            `<div class="bg-white p-2 mb-2 mr-2 w-75 text-abbey-grey fnt-size12px medium-font-weight">
            <p class="InfoBlock mb-2">${this.patientPrescriptionText}</p>
            <p class="InfoBlock">${this.process === this.unEmbeddedPharma ? this.translate.instant('PRESCRIPTION_QUERY_V1') : (!this.checkForThailandCountry() ? this.translate.instant('PATIENT_CONTACT_MESSAGE') : '')}</p></div>`
        });
        break;
      }
      case 'HYBRID': {
        this.formMap.set(HYBRID_STATES.PROGRAM_INFO, this.hybridForms[0]);
        this.formMap.set(HYBRID_STATES.PATIENT_CONSENT, this.hybridForms[1]);
        this.formMap.set(HYBRID_STATES.PRESCRIPTION_INFO, this.hybridForms[2]);
        this.formMap.set(HYBRID_STATES.PATIENT_INFO_FORM, this.hybridForms[3]);
        this.formMap.set(HYBRID_STATES.FILE_UPLOAD_FORM, this.hybridForms[4]);
        this.formMap.set(HYBRID_STATES.CARETAKER_INFO_FORM, this.hybridForms[5]);
        this.formMap.set(HYBRID_STATES.ADDITIONAL_INFO, this.hybridForms[6]);
        if (this.checkForThailandCountry()) {
          this.formMap.set(HYBRID_STATES.ORDER_INFO_FORM, this.hybridForms[7]);
          this.formMap.set(HYBRID_STATES.TREATMENT_INFO_FORM, this.hybridForms[8]);
        }
        if (this.checkForOazisProcess()) {
          this.formMap.set(HYBRID_STATES.THANK_PROVIDING_DETAILS, {
            type: BLOCK_TYPE.INFO,
            content: `<span class="bg-white p-2 text-abbey-grey fnt-size12px medium-font-weight">
            <span class="InfoBlock">${this.translate.instant('THANK_YOU_PROVIDING_DETAILS')}</span>
            </span>`
          });
          this.formMap.set(HYBRID_STATES.PATIENT_VERIFY_OTP, this.hybridForms[9]);
          this.formMap.set(HYBRID_STATES.PATIENT_RESEND_OTP, this.hybridForms[10]);
          // replicate this like patient info form with less fields
          this.formMap.set(HYBRID_STATES.PATIENT_INFO_FORM, this.hybridForms[11]);
          this.formMap.get(HYBRID_STATES.PATIENT_INFO_FORM).formType = HYBRID_STATES.PATIENT_INFO_FORM;
        }
        if (this.checkForOazisProcess()) {
          this.formMap.set(HYBRID_STATES.THANK_YOU_SUCCESS, {
            type: BLOCK_TYPE.THANK_YOU,
            content:
              `<p class="mt-2">${this.translate.instant('SUCCESSFULL_SUBMISSION_V2')}</p>`
          });
        } else if (this.checkForThailandCountry()) {
          this.formMap.set(HYBRID_STATES.THANK_YOU_SUCCESS, {
            type: BLOCK_TYPE.THANK_YOU,
            content:
              `<p class="mt-1 font-weight-bold">${this.translate.instant('CONGRATULATION')}</p>
          <p class="mt-2">${this.translate.instant('SUCCESSFULL_SUBMISSION_V1')}</p>`
          });

        } else {
          this.formMap.set(HYBRID_STATES.THANK_YOU_SUCCESS, {
            type: BLOCK_TYPE.THANK_YOU,
            content:
              `<p class="mt-1 font-weight-bold">${this.translate.instant('CONGRATULATION')}</p>
          <p class="mt-2">${this.translate.instant('SUCCESSFULL_SUBMISSION')}</p>
          <p class="mt-2">${this.process === this.unEmbeddedPharma ? this.translate.instant('PRESCRIPTION_QUERY_V1') : this.translate.instant('PRESCRIPTION_QUERY')}</p>`
          });
        }
        this.formMap.set(HYBRID_STATES.THANK_YOU_CARE, {
          type: BLOCK_TYPE.THANK_YOU,
          content:
            `<p class="mt-1 font-weight-bold">${this.translate.instant('THANKS')}</p>
          <p class="mt-2">${this.translate.instant('SUCCESSFULL_CARE')}</p>
          <p class="mt-2">${this.translate.instant('CARE_NOTIFY')}</p>`
        });
        this.formMap.set(HYBRID_STATES.PRESCITPTION_APPROVED_SEQUENTIAL_SUCCESS, {
          type: BLOCK_TYPE.THANK_YOU,
          content:
            `<p class="mt-1 font-weight-bold">${this.translate.instant('Awesome')}</p>
          <p class="mt-2">${!this.checkForThailandCountry() ? this.translate.instant('PRESCRIPTION_REUPLOAD') : this.translate.instant('ORDER_FORM_SENT_APPROVAL')}</p>
          <p class="mt-2">${this.process === this.unEmbeddedPharma ? this.translate.instant('PRESCRIPTION_QUERY_V1') : (!this.checkForThailandCountry() ? this.translate.instant('PRESCRIPTION_QUERY') : '')}</p>`
        });
        this.formMap.set(HYBRID_STATES.OPTION, {
          type: BLOCK_TYPE.OPTION,
          title: this.translate.instant('WOULD_YOU_LIKE_TO_ADD_A_CAREGIVER'),
        });
        this.formMap.set(HYBRID_STATES.EDIT_DETAIL, {
          type: BLOCK_TYPE.EDIT_DETAIL,
          formType: BLOCK_TYPE.EDIT_DETAIL,
        });
        this.formMap.set(HYBRID_STATES.REJECTED, {
          type: BLOCK_TYPE.REJECTED,
          formType: BLOCK_TYPE.REJECTED,
        });
        this.formMap.set(HYBRID_STATES.EDIT_SUCCESS, {
          type: BLOCK_TYPE.EDIT_SUCCESS,
          formType: BLOCK_TYPE.EDIT_SUCCESS,
        });
        this.formMap.set(HYBRID_STATES.ALREADY_APPROVED, {
          type: BLOCK_TYPE.ALREADY_APPROVED,
          formType: BLOCK_TYPE.ALREADY_APPROVED,
        });
        this.formMap.set(HYBRID_STATES.EDIT_BUTTON, {
          type: BLOCK_TYPE.EDIT_BUTTON,
          formType: BLOCK_TYPE.EDIT_BUTTON,
        });
        this.formMap.set(HYBRID_STATES.DELETE_CAREGIVER_CONFIRMATION, {
          type: BLOCK_TYPE.DELETE_CAREGIVER_CONFIRMATION,
          formType: BLOCK_TYPE.DELETE_CAREGIVER_CONFIRMATION
        });
        this.formMap.set(HYBRID_STATES.DELETE_CAREGIVER_CONFIRMATION, {
          type: BLOCK_TYPE.DELETE_CAREGIVER_CONFIRMATION,
          formType: BLOCK_TYPE.DELETE_CAREGIVER_CONFIRMATION
        });
        this.formMap.set(HYBRID_STATES.ADD_NEW_CAREGIVER, {
          type: BLOCK_TYPE.ADD_NEW_CAREGIVER,
          formType: BLOCK_TYPE.ADD_NEW_CAREGIVER
        });
        this.formMap.get(HYBRID_STATES.PATIENT_CONSENT).response = this.translate.instant('ACCEPT_PROCEED');
        break;
      }
      default: {
        break;
      }
    }
  }
  /**
   * Managing the chatblock array.
   */
  manage(): void {
    switch (this.currentStatus) {
      case HYBRID_STATES.NA: {
        this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.WELCOME_INFO));
        this.addBlockAndSetStatus(HYBRID_STATES.LOGIN);
        break;
      }
      case HYBRID_STATES.LOGIN: {
        this.store.dispatch(HybridAction.resetHybridForms());
        this.store.dispatch(HybridAction.loadHybridForms({ consentToken: this.patientToken }));
        this.hybridFormsSub = this.store.select(HybridSelector.getHybridForms).subscribe(data => {
          if (data !== null) {
            this.chatBlocks.block = [];
            this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.WELCOME_INFO));
            this.addBlockAndSetStatus(HYBRID_STATES.LOGIN);
            this.hybridForms = JSON.parse(JSON.stringify(data.blocks));
            this.hybridForms.forEach(form => {
              if (form?.attributes) {
                let i = 0;
                form.attributes.forEach(attr => {
                  attr.index = i;
                  i++;
                });
                form.attributes.sort((a, b) => {
                  if (a.arrangementOrder || b.arrangementOrder) {
                    return a.arrangementOrder > b.arrangementOrder ? 1 : -1;
                  } else {
                    return a.index > b.index ? 1 : -1;
                  }
                });
              }
            });
            this.showAdditionalInfo = data.showAdditionalInfo;
            this.applicationStatus = data.status;
            this.patientName = data.patientName;
            this.rejectReasons = data.rejectReasons;
            this.programName = this.programName ? this.programName : data.programName;
            if (this.checkForThailandCountry()) {
              this.selectedConsentDate = data?.consentDate ? data?.consentDate * 1000 : undefined;
              this.defaultConsentDate = data?.consentDate ? new Date(data.consentDate) : undefined;
            }
            this.generateFormMap(APPLICATION_TYPE.HYBRID);
            const idToken = JSON.parse(localStorage.getItem('patientAuth'));
            const helper = new JwtHelperService();
            const decodedToken = helper.decodeToken(idToken.accessToken);
            this.accessToken = idToken.accessToken;
            this.patientToken = decodedToken.data.consentToken;
            this.patientExists = decodedToken.data.exists;
            if (this.patientExists) {
              if (this.applicationStatus === APPROVAL_LIST.IN_REVIEW) {
                if (this.formMap.get(HYBRID_STATES.FILE_UPLOAD_FORM).submitted) {
                  this.formMap.get(HYBRID_STATES.PRESCRIPTION_INFO).submitted = false;
                  this.formMap.get(HYBRID_STATES.ADDITIONAL_INFO).submitted = false;
                  this.formMap.get(HYBRID_STATES.PATIENT_INFO_FORM).submitted = false;
                  this.formMap.get(HYBRID_STATES.FILE_UPLOAD_FORM).submitted = false;
                  if (this.checkForThailandCountry()) {
                    this.formMap.get(HYBRID_STATES.ORDER_INFO_FORM).submitted = false;
                    this.formMap.get(HYBRID_STATES.TREATMENT_INFO_FORM).submitted = false;
                  }
                  this.addBlockAndSetStatus(HYBRID_STATES.EDIT_DETAIL);
                } else {
                  if (this.checkForOazisProcess()) {
                    if (this.formMap.get(HYBRID_STATES.PATIENT_INFO_FORM).submitted) {
                      this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.THANK_YOU_SUCCESS));
                      const block: Message = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.EDIT_DETAIL)));
                      block.submitted = false;
                      block.noFurtherUpdate = true;
                      this.chatBlocks.block.push(block);
                      this.newMessages = true;
                      this.currentStatus = HYBRID_STATES.EDIT_DETAIL;
                    } else {
                      this.addBlockAndSetStatus(HYBRID_STATES.PATIENT_INFO_FORM);
                    }
                  } else if (this.checkForThailandCountry()) {
                    if (this.formMap.get(HYBRID_STATES.PATIENT_INFO_FORM).submitted) {
                      this.store.dispatch(SignupAction.loadHospitals({ token: this.patientToken }));
                      this.addBlockAndSetStatus(HYBRID_STATES.TREATMENT_INFO_FORM);
                    } else {
                      this.addBlockAndSetStatus(HYBRID_STATES.PATIENT_INFO_FORM);
                    }
                    if (this.formMap.get(HYBRID_STATES.TREATMENT_INFO_FORM).submitted) {
                      this.addBlockAndSetStatus(HYBRID_STATES.FILE_UPLOAD_FORM);
                    }
                  } else {
                    this.addBlockAndSetStatus(HYBRID_STATES.PRESCRIPTION_INFO);
                    if (this.showAdditionalInfo === 1) {
                      this.addBlockAndSetStatus(HYBRID_STATES.ADDITIONAL_INFO);
                    } else if (this.formMap.get(HYBRID_STATES.PRESCRIPTION_INFO).submitted) {
                      this.addBlockAndSetStatus(HYBRID_STATES.PATIENT_INFO_FORM);
                    }
                    if (this.formMap.get(HYBRID_STATES.ADDITIONAL_INFO).submitted) {
                      this.addBlockAndSetStatus(HYBRID_STATES.PATIENT_INFO_FORM);
                    }
                    if (this.formMap.get(HYBRID_STATES.PATIENT_INFO_FORM).submitted) {
                      this.addBlockAndSetStatus(HYBRID_STATES.FILE_UPLOAD_FORM);
                    }
                  }
                }
              } else if (this.applicationStatus === APPROVAL_LIST.REJECTED) {
                this.addBlockAndSetStatus(HYBRID_STATES.REJECTED);
                const editForm = this.formMap.get(HYBRID_STATES.EDIT_DETAIL);
                editForm.title = this.translate.instant('PROFILE_CORRECTED_TITLE');
                this.addBlockAndSetStatus(HYBRID_STATES.EDIT_DETAIL);
              } else if (this.applicationStatus === APPROVAL_LIST.APPROVED) {
                if (this.checkForOazisProcess()) {
                  this.isApproved = true;
                  this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.ALREADY_APPROVED));
                  localStorage.removeItem('patientAuth');
                }
                else if (this.checkForThailandCountry()) {
                  this.isApproved = true;
                  this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.ALREADY_APPROVED));
                  const block = this.formMap.get(HYBRID_STATES.ORDER_INFO_FORM);
                  block.submitted = false;
                  block.attributes.forEach(element => {
                    element.value = '';
                  });
                  this.addBlockAndSetStatus(HYBRID_STATES.EDIT_DETAIL);
                } else {
                  this.isApproved = true;
                  this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.ALREADY_APPROVED));
                  const block = this.formMap.get(HYBRID_STATES.PRESCRIPTION_INFO);
                  block.submitted = false;
                  block.attributes.forEach(element => {
                    element.value = '';
                  });
                  this.addBlockAndSetStatus(HYBRID_STATES.EDIT_DETAIL);
                }
              } else if (this.applicationStatus === '') {
                if (this.checkForOazisProcess()) {
                  this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.THANK_PROVIDING_DETAILS));
                  this.addBlockAndSetStatus(HYBRID_STATES.PATIENT_VERIFY_OTP);
                  if (!this.formMap.get(HYBRID_STATES.PATIENT_VERIFY_OTP).submitted && !this.phoneNumberVerified) {
                    this.store.dispatch(HybridAction.requestOtp({ consentToken: this.patientToken }));
                  }
                  if (this.formMap.get(HYBRID_STATES.PATIENT_VERIFY_OTP).submitted) {
                    this.addBlockAndSetStatus(HYBRID_STATES.PRESCRIPTION_INFO);
                  }
                } else if (this.checkForThailandCountry()) {
                  this.addBlockAndSetStatus(HYBRID_STATES.ORDER_INFO_FORM);
                } else {
                  this.addBlockAndSetStatus(HYBRID_STATES.PRESCRIPTION_INFO);
                }
              }
            } else {
              this.addBlockAndSetStatus(HYBRID_STATES.PROGRAM_INFO);
            }
          }
        });
        break;
      }
      case HYBRID_STATES.PROGRAM_INFO: {
        if (this.checkForThailandCountry()) {
          this.consentCalendarValidation = true;
        }
        this.addBlockAndSetStatus(HYBRID_STATES.PATIENT_CONSENT);
        break;
      }
      case HYBRID_STATES.PATIENT_CONSENT: {
        if (this.checkForOazisProcess()) {
          this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.THANK_PROVIDING_DETAILS));
          this.addBlockAndSetStatus(HYBRID_STATES.PATIENT_VERIFY_OTP);
        } else if (this.checkForThailandCountry()) {
          this.addBlockAndSetStatus(HYBRID_STATES.ORDER_INFO_FORM);
        } else {
          this.addBlockAndSetStatus(HYBRID_STATES.PRESCRIPTION_INFO);
        }
        break;
      }
      case HYBRID_STATES.PRESCRIPTION_INFO: {
        if (this.showAdditionalInfo === 1) {
          this.addBlockAndSetStatus(HYBRID_STATES.ADDITIONAL_INFO);
        } else {
          this.addBlockAndSetStatus(HYBRID_STATES.PATIENT_INFO_FORM);
        }
        break;
      }
      case HYBRID_STATES.PATIENT_INFO_FORM: {
        if (this.checkForOazisProcess()) {
          this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.THANK_YOU_SUCCESS));
          this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.EDIT_BUTTON));
          this.newMessages = true;
        }
        else if (this.checkForThailandCountry()) {
          this.store.dispatch(SignupAction.loadHospitals({ token: this.patientToken }));
          this.addBlockAndSetStatus(HYBRID_STATES.TREATMENT_INFO_FORM);
        } else {
          this.addBlockAndSetStatus(HYBRID_STATES.FILE_UPLOAD_FORM);
        }
        this.newMessages = true;
        break;
      }
      case HYBRID_STATES.FILE_UPLOAD_FORM: {
        this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.THANK_YOU_SUCCESS));
        if (this.checkForThailandCountry()) {
          this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.EDIT_BUTTON));
          this.newMessages = true;
        } else {
          this.addBlockAndSetStatus(HYBRID_STATES.OPTION);
        }
        break;
      }
      case HYBRID_STATES.OPTION: {
        this.addBlockAndSetStatus(HYBRID_STATES.CARETAKER_INFO_FORM);
        break;
      }
      case HYBRID_STATES.CARETAKER_INFO_FORM: {
        this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.THANK_YOU_CARE));
        this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.EDIT_BUTTON));
        this.newMessages = true;
        break;
      }
      case HYBRID_STATES.EDIT_ACTIVE: {
        const editBlock = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.EDIT_DETAIL)));
        editBlock.submitted = false;
        if (this.editCounter > 0) {
          editBlock.noFurtherUpdate = true;
        }
        this.chatBlocks.block.push(editBlock);
        this.newMessages = true;
        break;
      }
      case HYBRID_STATES.PRESCITPTION_APPROVED_SEQUENTIAL_SUCCESS: {
        this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.PRESCITPTION_APPROVED_SEQUENTIAL_SUCCESS));
        this.newMessages = true;
        break;
      }
      case HYBRID_STATES.ADDITIONAL_INFO: {
        this.addBlockAndSetStatus(HYBRID_STATES.PATIENT_INFO_FORM);
        break;
      }
      case HYBRID_STATES.ORDER_INFO_FORM: {
        this.addBlockAndSetStatus(HYBRID_STATES.PATIENT_INFO_FORM);
        this.newMessages = true;
        break;
      }
      case HYBRID_STATES.TREATMENT_INFO_FORM: {
        this.addBlockAndSetStatus(HYBRID_STATES.FILE_UPLOAD_FORM);
        this.newMessages = true;
        break;
      }
      case HYBRID_STATES.PATIENT_VERIFY_OTP: {

        const chatbotResendOtpSub = this.store.select(SignupSelector.getResendOtp).subscribe(data => {

          if (data) {
            this.phoneNumberVerified = false;
            this.formMap.get(HYBRID_STATES.PATIENT_RESEND_OTP).submitted = false;
            this.addBlockAndSetStatus(HYBRID_STATES.PATIENT_RESEND_OTP);
            this.newMessages = true;
          } else {
            this.phoneNumberVerified = true;

            this.addBlockAndSetStatus(HYBRID_STATES.PRESCRIPTION_INFO);
            this.newMessages = true;
          }
          if (chatbotResendOtpSub) {
            chatbotResendOtpSub.unsubscribe();
          }
        });
        break;
      }

      case HYBRID_STATES.PATIENT_RESEND_OTP: {
        this.formMap.get(HYBRID_STATES.PATIENT_VERIFY_OTP).submitted = false;
        this.store.dispatch(SignupAction.setRequestResendOtp(null));
        this.addBlockAndSetStatus(HYBRID_STATES.PATIENT_VERIFY_OTP);
        this.newMessages = true;
        break;
      }
      default: {
        break;
      }
    }
  }

  refreshComponent(): void {
    if (this.route.snapshot.firstChild) {
      this.pharmaCode = this.route.snapshot.firstChild.params.pharmaCode;
    }
    this.docquityTime = [];
    this.userTime = [];
    this.store.dispatch(HybridAction.resetCreds({}));
    this.store.dispatch(HybridAction.resetHybridForms());
    this.store.dispatch(SignupAction.resetCountryList());
    this.store.dispatch(HybridAction.resetPatientConsent({}));
    this.systemTime = Math.floor(Date.now() / 1000);
    this.blockType = BLOCK_TYPE;
    this.formMap = new Map<string, Message>();
    this.chatBlocks = {
      block: [],
      chatBotHeading: this.checkForOazisProcess() ? this.translate.instant('OAZIS_CARE_HEADER')
        : this.translate.instant('LET_GET_HEALTHY'),
      images: {
        chatLogo: 'assets/images/chat_logo.svg',
        checkBox: 'assets/images/logo.svg'
      },
      productLogo: this.checkForOazisProcess() ? 'assets/images/oazis-logo.png' : 'assets/images/logo.svg'
    };
    this.loginError = this.store.select(HybridSelector.getHybridError).subscribe(data => {
      if (data != null && this.patientEmail !== undefined && this.patientPhoneNo !== undefined
        && this.patientCountryCode) {
        if (data.code === ERROR_CODE.E4032 && this.chatBlocks.block.length === 0) {
          this.chatBlocks.block = [];
          this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.WELCOME_INFO));
          const block = this.formMap.get(HYBRID_STATES.LOGIN);
          block.submitted = true;
          this.chatBlocks.block.push(block);
          this.addBlockAndSetStatus(HYBRID_STATES.PATIENT_PRESCRIPTION_STATUS);
        } else if (data.code === ERROR_CODE.E4024) {
          this.patientAlreadyExits = 'User already registered as a patient';
        } else {
          this.chatBlocks.block.splice(2);
          this.fillValueInBlock(HYBRID_STATES.LOGIN, true, 'LETS_GET_STARTED', 'LETS_GET_STARTED');
          this.conflictedemailphonecreds(this.patientEmail, this.patientPhoneNo, this.patientCountryCode);
          if (data.code === ERROR_CODE.E40107) {
            this.addBlockAndSetStatus(HYBRID_STATES.PHONE_EXIST);
          }
          else if (data.code === ERROR_CODE.E40108) {
            this.addBlockAndSetStatus(HYBRID_STATES.EMAIL_EXIST);
          }
          else if (data.code === ERROR_CODE.E4032) {
            this.addBlockAndSetStatus(HYBRID_STATES.PATIENT_PRESCRIPTION_STATUS);
          }
        }
      }
    });
    this.queryParamsCheck = this.route.queryParams
      .subscribe(params => {
        const country = params.country;
        if (country === undefined && !this.chatView) {
          this.router.navigate(['PageNotFoundComponent']);
        }
      }
      );
    this.store.dispatch(SignupAction.loadCountries({}));
    this.queryParametersSub = this.route.queryParams.subscribe(params => {
      if (params.country) {
        localStorage.setItem(LOCAL_STORAGE_COUNTRY.COUNTRY, params.country);
      }
    });
    if (localStorage.getItem('patientAuth')) {
      const idToken = JSON.parse(localStorage.getItem('patientAuth'));
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(idToken.accessToken);
      if (this.systemTime > decodedToken.exp) {
        this.store.dispatch(HybridAction.resetCreds({}));
        localStorage.removeItem('patientAuth');
      } else {
        this.accessToken = idToken.accessToken;
        this.patientEmail = decodedToken.data.email;
        this.patientPhoneNo = decodedToken.data.phoneNumber;
        this.patientCountryCode = decodedToken.data.countryCode;
      }
    }
    if (this.checkForThailandCountry()) {
      this.countriesSub = this.store.select(SignupSelector.getHospitalList)
        .subscribe(data => {
          if (data) {
            this.hospitalList = data ? data.map(obj => ({ id: obj?.id, name: obj.name })) : [];
          }
        });
    }
    this.countriesSub = this.store.select(SignupSelector.getCountryList).subscribe(data => {
      if (data !== null) {
        this.countries = data.list;
        this.generateFormMap(APPLICATION_TYPE.AUTHORIZATION);
        if (localStorage.getItem('patientAuth') && this.accessToken) {
          if (this.pharmaCode) {
            this.store.dispatch(HybridAction.submitLoginData({
              endPoint: API.PATIENT_AUTH,
              formData: {
                phone: this.patientPhoneNo,
                countryCode: this.patientCountryCode,
                email: this.patientEmail
              },
              pharmaCode: this.pharmaCode,
              process: this.process,
            }));
          }
          else {
            this.store.dispatch(HybridAction.submitLoginData({
              endPoint: API.PATIENT_AUTH,
              formData: {
                phone: this.patientPhoneNo,
                countryCode: this.patientCountryCode,
                email: this.patientEmail,
                process: this.process,
              },
            }));
          }
          this.credSub = this.store.select(HybridSelector.getCreds).subscribe(patientCreds => {
            if (patientCreds !== null) {
              const idToken = JSON.parse(localStorage.getItem('patientAuth'));
              const helper = new JwtHelperService();
              const decodedToken = helper.decodeToken(idToken.accessToken);
              this.accessToken = idToken.accessToken;
              this.patientToken = decodedToken.data.consentToken;
              this.patientExists = decodedToken.data.exists;
              this.chatBlocks.block.push(this.formMap.get(HYBRID_STATES.WELCOME_INFO));
              const block = this.formMap.get(HYBRID_STATES.LOGIN);
              block.submitted = true;
              this.chatBlocks.block.push(block);
              this.currentStatus = HYBRID_STATES.LOGIN;

              this.manage();
            }
          });
        } else {
          this.chatBlocks.block = [];
          this.currentStatus = HYBRID_STATES.NA;

          this.manage();
        }
      }
    });
  }

  /**
   * Scrolling to bottom when a new message is pushed in chatblocks.
   */
  ngAfterViewChecked(): void {
    if (this.newMessages) {
      this.scrollToBottom();
      this.newMessages = false;
    }
  }

  /**
   * Unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.sessionTimeOutSub) {
      this.sessionTimeOutSub.unsubscribe();
    }
    if (this.chatView) {
      this.chatBlocks.block.splice(0);
    }
    if (this.credSub) {
      this.credSub.unsubscribe();
    }
    if (this.hybridFormsSub) {
      this.hybridFormsSub.unsubscribe();
    }
    if (this.updateConsentSub) {
      this.updateConsentSub.unsubscribe();
    }
    if (this.queryParametersSub) {
      this.queryParametersSub.unsubscribe();
    }
    if (this.countriesSub) {
      this.countriesSub.unsubscribe();
    }
    if (this.loginError) {
      this.loginError.unsubscribe();
    }
    if (this.queryParamsCheck) {
      this.queryParamsCheck.unsubscribe();
    }
    if (this.additionalInfoSub) {
      this.additionalInfoSub.unsubscribe();
    }
    if (this.pharmaSubs) {
      this.pharmaSubs.unsubscribe();
    }
  }

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService) { }

  deleteCaregiverData(data): void {
    this.caregiverDeleteRequest = data.formData;
    this.careGiverName = data.careGiverName;
    const block: Message = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.DELETE_CAREGIVER_CONFIRMATION)));
    block.submitted = false;
    this.chatBlocks.block.push(block);
    this.newMessages = true;
  }
  deleteCareGiverConfirm(i: number, responeText: string): void {
    if (!this.isSubmissionOngoing) {
      const caregiverBlock: Message = this.formMap.get(HYBRID_STATES.CARETAKER_INFO_FORM);
      caregiverBlock.attributes.forEach(attr => {
        attr.value = '';
      });
      caregiverBlock.submitted = false;
      this.store.dispatch(HybridAction.deleteCareGiver({
        token: this.patientToken,
        careGiverRequest: this.caregiverDeleteRequest,
        accessToken: this.accessToken, applicationType: APPLICATION_TYPE.HYBRID
      }));
      this.chatBlocks.block[i].submitted = true;
      this.chatBlocks.block[i].response = this.translate.instant(responeText);
      this.isSubmissionOngoing = true;
      const block: Message = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.ADD_NEW_CAREGIVER)));
      block.submitted = false;
      this.chatBlocks.block.push(block);
      this.newMessages = true;
      this.isSubmissionOngoing = false;
    }
  }
  addNewCareGiver(i: number, responseText: string): void {
    if (!this.isSubmissionOngoing) {
      this.isSubmissionOngoing = true;
      this.chatBlocks.block[i].submitted = true;
      this.chatBlocks.block[i].response = this.translate.instant(responseText);
      const block: Message = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.CARETAKER_INFO_FORM)));
      block.attributes.forEach(ele => {
        ele.value = undefined;
      });
      block.submitted = false;
      this.chatBlocks.block.push(block);
      this.newMessages = true;
      this.isSubmissionOngoing = false;
    }
  }
  goBackToDetail(i: number, responseText: string): void {
    if (!this.isSubmissionOngoing) {
      this.isSubmissionOngoing = true;
      this.chatBlocks.block[i].submitted = true;
      this.chatBlocks.block[i].response = this.translate.instant(responseText);
      const block: Message = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.EDIT_DETAIL)));
      block.noFurtherUpdate = true;
      block.submitted = false;
      this.chatBlocks.block.push(block);
      this.newMessages = true;
      this.isSubmissionOngoing = false;
    }
  }
  goBackToEdit(data): void {
    if (!this.isSubmissionOngoing) {
      this.isSubmissionOngoing = true;
      const block: Message = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.EDIT_DETAIL)));
      block.submitted = false;
      block.noFurtherUpdate = true;
      this.chatBlocks.block.push(block);
      this.newMessages = true;
      this.isSubmissionOngoing = false;
    }
  }
  backToCareGiverForm(i: number, responseText: string): void {
    if (!this.isSubmissionOngoing) {
      this.isSubmissionOngoing = true;
      this.chatBlocks.block[i].submitted = true;
      this.chatBlocks.block[i].response = this.translate.instant(responseText);
      const caretakerBlock: Message = JSON.parse(JSON.stringify(this.formMap.get(HYBRID_STATES.CARETAKER_INFO_FORM)));
      caretakerBlock.isCareGiverExist = true;
      caretakerBlock.submitted = false;
      this.chatBlocks.block.push(caretakerBlock);
      this.newMessages = true;
      this.isSubmissionOngoing = false;
    }
  }

  /**
   * Select the consent date.
   */
  selectedConsentDateValue(event: Date): void {
    this.selectedConsentDate = new Date(event).getTime() / 1000;
    const consentBlock = this.formMap.get(HYBRID_STATES.PATIENT_CONSENT);
    consentBlock.submitted = false;
    this.consentCalendarValidation = false;
  }

  /**
   * Check for the thailand country.
   */
  checkForThailandCountry(): boolean {
    return localStorage.getItem('country') === COUNTRIES_LIST.THAILAND.value;
  }

  checkForOazisProcess(): boolean {
    return  sessionStorage.getItem('process') === OAZIS_FLOW_PROCESS;
  }
}
